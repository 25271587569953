import { NewsModule } from '../news';
import React from 'react';
import { ProfileModule } from '../profile';

export const HomeModule = () => {
  return (
    <div className={'container mx-auto'}>
      <div className={'flex flex-row gap-4'}>
        <div className={'w-full'}>
          <NewsModule />
        </div>
        <div className={'w-[400px] min-h-[300px]'}>
          <ProfileModule />
        </div>
      </div>
    </div>
  );
};
