import { Header } from '../component/header.component';
import { Outlet, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useAuth } from '../../auth/auth.context';
import { Logo } from '../component/logo.component';

export const HomeLayout = () => {
  const { isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div>
        <Logo />
      </div>
    );
  }

  const isWide = useMemo(
    () =>
      location.pathname.includes('schedule') ||
      location.pathname.includes('journals') ||
      location.pathname.includes('tracker'),
    [location],
  );

  const isPadding = useMemo(
    () => !location.pathname.includes('tracker'),
    [location],
  );

  return (
    <div className={'flex flex-col h-screen relative'}>
      <Header isWide={isWide} isPadding={isPadding} />

      <div
        className={`w-full mx-auto ${
          isPadding && 'p-4'
        } grow flex flex-col mt-[60px]`}
      >
        <Outlet />
      </div>
    </div>
  );
};
