import { Card } from '../../common/block/card.component';
import { Sidebar } from './sidebar/sidebar.component';
import { ScheduleContext } from '../schedule.context';
import { ScheduleItemColumn } from './item/schedule-item-column.component';
import { ScheduleBottomComponent } from './bottom/schedule-bottom.component';
import { ScheduleBottomSettingsModal } from './bottom/schedule-bottom-settings-modal.component';
import { ScheduleItemModal } from './item/modal/schedule-item-modal.component';
import { ScheduleBottomConfirmModal } from './bottom/schedule-bottom-confirm-modal.component';
import { memo } from 'react';
import { useContextSelector } from 'use-context-selector';

export const ScheduleRoot = memo(function ScheduleRoot() {
  const headers = useContextSelector(
    ScheduleContext,
    context => context.headers,
  );

  return (
    <div className={'flex flex-col gap-4 text-center text-sm h-[90vh]'}>
      <Card
        className={
          'grid grid-cols-[repeat(2,_0.2fr)_repeat(100,_1fr)] overflow-scroll no-scrollbar'
        }
      >
        <Sidebar />

        {headers.map(item => (
          <ScheduleItemColumn key={item.id} header={item} />
        ))}
      </Card>

      <ScheduleBottomComponent />

      <ScheduleBottomSettingsModal />
      <ScheduleBottomConfirmModal />
      <ScheduleItemModal />
    </div>
  );
});
