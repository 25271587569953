import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { api } from '../app/networking/axios';
import { DocumentEntityType, DocumentType } from './document.type';

export type TaskDocumentGenerateModalType = {
  type: 'generate';
  entity: DocumentEntityType;
};

export type TaskDocumentConfigurationModalType = {
  type: 'configuration';
  entity: DocumentEntityType;
};

export type TaskNoneModalType = {
  type: 'none';
};

export type TaskModalType =
  | TaskDocumentGenerateModalType
  | TaskDocumentConfigurationModalType
  | TaskNoneModalType;

export type DocumentMutationType = {
  type: DocumentType;
  params: Record<string, unknown>;
};

export type TaskContextType = {
  generate: (data: DocumentMutationType) => void;

  modal: TaskModalType;
  setModal: (value: TaskModalType) => void;
};

export const TaskContext = createContext<TaskContextType>(
  {} as TaskContextType,
);

export const TaskProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<TaskModalType>({ type: 'none' });

  const generate = useMutation({
    mutationKey: ['generate'],
    mutationFn: ({ type, params }: DocumentMutationType) =>
      api.post(`/task/${type}`, {
        params,
      }),
  });

  const value = useMemo(
    () => ({
      generate: generate.mutate,

      modal,
      setModal,
    }),
    [generate, modal, setModal],
  );

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export const useTask = () => useContext(TaskContext);
