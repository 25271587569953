import { usePlan } from '../../../plan.context';
import { PlanTableContentCategory } from './category/plan-table-content-category.component';
import { PlanTableContentCategoryCreateModal } from './category/plan-table-content-category-create-modal.component';
import { PlanTableContentCategoryDropModal } from './category/plan-table-content-category-drop-modal.component';
import { PlanTableContentCategoryUpdateModal } from './category/plan-table-content-category-update-modal.component';
import { PlanTableContentCategorySubjectTeacherModal } from './category/subject/plan-table-content-category-subject-teacher-modal.component';

export const PlanTableContent = () => {
  const { plans } = usePlan();

  return (
    <div className={'flex flex-col'}>
      {plans.map(plan => (
        <PlanTableContentCategory key={plan.id} plan={plan} />
      ))}

      <PlanTableContentCategory key={'new'} />

      <PlanTableContentCategoryCreateModal />
      <PlanTableContentCategoryUpdateModal />
      <PlanTableContentCategoryDropModal />
      <PlanTableContentCategorySubjectTeacherModal />
    </div>
  );
};
