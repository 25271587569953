import { Modal } from '../../../common/component/modal/modal';
import { useTask } from '../../task.context';
import { Button } from '../../../common/component/button/button.component';
import { useEntity } from '../../../entity/entity.context';
import { Input } from '../../../common/component/input/input.component';
import { AutocompleteService } from '../../../common/component/autocomplete-service/autocomplete-service.component';
import { UserEntity } from '../../../user/user.entity';
import { useList } from 'react-use';
import { Profile } from '../../../common/component/profile/profile.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { DocumentType } from '../../document.type';

export const TaskDocumentGenerateModal = () => {
  const { modal, setModal, generate } = useTask();
  const { refetch } = useEntity();

  if (modal.type !== 'generate') {
    return <div />;
  }

  const [year, setYear] = useState<number>(DateTime.now().year);
  const [month, setMonth] = useState<number>(DateTime.now().month);
  const [teachers, { push, removeAt }] = useList<UserEntity>();

  return (
    <Modal
      title={'Генерация ведомости'}
      showed={modal.type === 'generate'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col gap-2'}>
          <Input
            placeholder={'Год'}
            type={'number'}
            defaultValue={year}
            onChange={event => setYear(Number(event.target.value))}
          />

          <Input
            placeholder={'Месяц'}
            type={'number'}
            defaultValue={month}
            onChange={event => setMonth(Number(event.target.value))}
          />

          <AutocompleteService<UserEntity>
            title={'Преподаватель'}
            route={'users'}
            onSelected={(user: UserEntity) => push(user)}
            mapper={user => ({
              value: user,
              title: user.pretty,
              view: <Profile user={user} size={8} />,
            })}
            params={{
              type: 'TEACHER',
            }}
            selectedOnce={true}
          />

          <div className={'flex flex-col gap-4'}>
            <span className={'text-lg font-semibold'}>
              Выбранные преподаватели
              <div className={'flex flex-col gap-2'}>
                {teachers.map((teacher, index) => (
                  <div key={index} className={'flex flex-row justify-between'}>
                    <Profile prefix={`${index + 1}.`} user={teacher} size={8} />
                    <button>
                      <FontAwesomeIcon
                        icon={faXmark}
                        onClick={() => removeAt(index)}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </span>
          </div>
        </div>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>

          <Button
            color={'green'}
            onClick={() => {
              generate({
                type: modal.entity.type,
                params: {
                  year,
                  month,

                  ...(modal.entity.type === DocumentType.REPORT_CARD
                    ? {
                        partTimerIds: teachers.map(item => item.id),
                      }
                    : {
                        ignoredIds: teachers.map(item => item.id),
                      }),
                },
              });
              setTimeout(() => {
                refetch();
                setModal({ type: 'none' });
              }, 100);
            }}
          >
            Генерировать
          </Button>
        </div>
      </div>
    </Modal>
  );
};
