import { usePlanEditor } from '../../plan-editor.context';
import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';
import { useMemo } from 'react';

export const PlanEditorVerificationModal = () => {
  const { plan, modal, setModal, update } = usePlanEditor();

  const isEmpty = useMemo(
    () =>
      plan?.categories?.some(
        category =>
          !category.title ||
          category?.items?.some(
            item =>
              [item.title, item.hours, item.month, item.type].filter(
                item => item === undefined,
              ).length > 0,
          ),
      ),
    [plan],
  );

  return (
    <Modal
      title={
        plan.status?.status === 'IN_PROGRESS'
          ? 'Отправить на проверку'
          : plan.status?.status === 'REVIEW_REQUESTED'
          ? 'Снять с проверки'
          : 'Редактировать'
      }
      showed={modal.type === 'verify'}
      onClose={() => setModal({ type: 'none' })}
    >
      {isEmpty ? (
        <div className={'text-center'}>
          В календарно-тематическом плане присутствуют незаполненные данные..
        </div>
      ) : (
        <div className={'flex flex-col gap-4'}>
          <h1>
            Вы действительно хотите{' '}
            {plan.status?.status === 'IN_PROGRESS'
              ? 'отправить'
              : plan.status?.status === 'REVIEW_REQUESTED'
              ? 'снять'
              : 'редактировать'}{' '}
            календарно-тематический план{' '}
            {plan.status?.status === 'IN_PROGRESS'
              ? 'на проверку'
              : plan.status?.status === 'REVIEW_REQUESTED'
              ? 'с проверки'
              : ''}
            ?
          </h1>
          <div className={'flex flex-row gap-4'}>
            <Button
              color={'red'}
              onClick={() =>
                update(
                  plan.status?.status === 'IN_PROGRESS'
                    ? 'REVIEW_REQUESTED'
                    : 'IN_PROGRESS',
                )
              }
            >
              Да, подтвердить
            </Button>
            <Button onClick={() => setModal({ type: 'none' })}>
              Нет, отменить
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
