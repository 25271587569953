import { useCallback, useEffect, useState } from 'react';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../../common/component/input/input.component';
import { gradients } from '../../news.const';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../common/component/dropdown/dropdown';
import { useNews } from '../../news.context';
import { UseFormSetValue } from 'react-hook-form';
import { NewsEntityValues } from '../../news.entity';
import { useList } from 'react-use';
import { DropdownItem } from '../../../common/component/dropdown/dropdown-item';

export type NewsAdminCreatePollProps = {
  setValue: UseFormSetValue<NewsEntityValues>;
};

export const NewsAdminCreatePoll = ({ setValue }: NewsAdminCreatePollProps) => {
  const { setPollStatus } = useNews();

  const [title, setTitle] = useState<string>('');
  const [retake, setRetake] = useState<boolean>(true);
  const [multiple, setMultiple] = useState<boolean>(false);
  const [color, setColor] = useState<number>(0);

  const [items, { updateAt, removeAt }] = useList<string>([]);

  const iterateGradient = useCallback(() => {
    setColor(prevState =>
      prevState + 1 === gradients.length ? 0 : prevState + 1,
    );
  }, [setColor]);

  const colors = Object.values(gradients[color])
    .map(item => `#${item}`)
    .join(', ');

  useEffect(() => {
    setValue('poll.title', title);
    setValue('poll.items', items);
    setValue('poll.backgroundFrom', gradients[color].backgroundFrom);
    setValue('poll.backgroundVia', gradients[color].backgroundVia);
    setValue('poll.backgroundTo', gradients[color].backgroundTo);
    setValue('poll.retake', retake);
    setValue('poll.multiple', multiple);
  }, [title, color, items, retake, multiple]);

  return (
    <div
      className={`flex flex-col p-4 rounded-lg my-2 gap-4`}
      style={{
        background: `linear-gradient(to right bottom, ${colors})`,
      }}
    >
      <div className={'flex flex-row justify-between'}>
        <div className={'font-bold text-white'}>
          <Input
            background={false}
            placeholder={'Вопрос...'}
            type={'text'}
            className={'p-2 w-full text-white placeholder-white'}
            onKeyDown={event => {
              if (event.key.toLowerCase() === 'enter') {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            onChange={event => setTitle(event.currentTarget.value)}
          />
        </div>

        <Dropdown
          header={
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className={'h-6 w-6 text-white'}
            />
          }
          headerStyled={false}
        >
          <DropdownItem
            title={`Несколько ответов: ${multiple ? 'Да' : 'Нет'}`}
            onClick={() => setMultiple(prevState => !prevState)}
            color="blue"
          />
          <DropdownItem
            title={`Несколько голосов: ${retake ? 'Да' : 'Нет'}`}
            onClick={() => setRetake(prevState => !prevState)}
            color="blue"
          />
          <DropdownItem
            title={'Сменить тему'}
            onClick={iterateGradient}
            color="yellow"
          />
          <DropdownItem
            title={'Удалить опрос'}
            onClick={() => setPollStatus(false)}
            color="red"
          />
        </Dropdown>
      </div>
      <div className={'flex flex-col gap-2'}>
        {Array.from(
          Array(items.length === 5 ? 5 : items.length + 1).keys(),
        ).map(index => (
          <div
            key={index}
            className="w-full h-10 rounded-xl bg-gray-900/70 items-center flex flex-row justify-between p-2"
          >
            <Input
              background={false}
              placeholder={'Вопрос...'}
              type={'text'}
              className={'p-2 w-full text-white placeholder-white'}
              onKeyDown={event => {
                if (event.key.toLowerCase() === 'enter') {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
              onChange={event => updateAt(index, event.currentTarget.value)}
            />
            {items.length !== index && (
              <button
                onClick={() => removeAt(index)}
                type={'button'}
                className={'text-white'}
              >
                <FontAwesomeIcon
                  className={'h-4 w-4 text-white'}
                  icon={faTrashCan}
                />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
