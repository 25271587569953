import { Card } from '../../../common/block/card.component';
import { useSpecialtyGroupStatements } from '../../specialty-group-statements.context';
import { InputSelect } from '../../../common/component/input-select/input-select';
import { DateTime } from 'luxon';
import { Button } from '../../../common/component/button/button.component';

export const StatementHeader = () => {
  const {
    group,
    semesters,
    semesterId,
    setSemesterId,
    isEditing,
    setIsEditing,
    save,
  } = useSpecialtyGroupStatements();

  return (
    <Card className={'p-4 flex flex-row justify-between items-center'}>
      <div>{group?.title}</div>

      <div className={'flex flex-row gap-2'}>
        {semesterId &&
          (isEditing ? (
            <Button color={'green'} onClick={() => save()}>
              Сохранить
            </Button>
          ) : (
            <Button color={'yellow'} onClick={() => setIsEditing(true)}>
              Редактировать
            </Button>
          ))}
        <InputSelect
          title={'Семестр'}
          classname={'w-96'}
          selected={semesterId}
          items={semesters.map(semester => ({
            value: semester.id,
            title: [semester.start, semester.end]
              .map(value => DateTime.fromSeconds(value).toFormat('DDD'))
              .join(' - '),
          }))}
          onChange={value => setSemesterId(Number(value))}
        />
      </div>
    </Card>
  );
};
