import { useAuth } from '../../auth/auth.context';
import { Dropdown } from '../../../common/component/dropdown/dropdown';
import { Verification } from '../../../common/component/verification/verification.component';
import { DropdownItem } from '../../../common/component/dropdown/dropdown-item';

export const Profile = () => {
  const { result, logout, setModal } = useAuth();

  return (
    <Dropdown
      header={
        <div className="flex items-center place-items-center space-x-4 hover:cursor-pointer">
          {result?.user?.avatar ? (
            <img
              className="w-10 h-10 aspect-square rounded-full"
              src={result?.user.avatar}
            />
          ) : (
            <div
              className={
                'h-10 w-10 aspect-square bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 rounded-full flex justify-center items-center'
              }
            >
              <div className={'font-medium text-md text-white'}>
                {result?.user?.letters ?? 'XX'}
              </div>
            </div>
          )}
          <div className="font-medium dark:text-white flex flex-col items-start">
            <div
              className={'flex flex-row gap-2 whitespace-nowrap items-center '}
            >
              <div>{result?.user.pretty ?? 'Who?'}</div>
              {result?.user?.isAdmin && <Verification />}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 whitespace-nowrap">
              {result?.user?.title}
            </div>
          </div>
        </div>
      }
      headerStyled={false}
    >
      {(result?.user.isAdmin || result?.original?.user.isAdmin) && (
        <DropdownItem
          title="Симуляция"
          color="default"
          onClick={() => setModal({ type: 'simulation' })}
        />
      )}
      <DropdownItem
        title="Разработчики"
        color="default"
        onClick={() => setModal({ type: 'developers' })}
      />
      <DropdownItem title="Выйти" color="red" onClick={logout} />
    </Dropdown>
  );
};
