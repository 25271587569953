import { useFileUploader } from '../file-uploader.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../../../block/card.component';

export const FileCollection = () => {
  const { files } = useFileUploader();

  return (
    <div className={'flex flex-row gap-4'}>
      {files.map((file, index) => {
        console.log('file type:', file.type);

        if (file.type.includes('sheet')) {
          return (
            <Card
              key={index}
              className={'p-4 bg-[#F0F2F5] w-32 h-32 dark:bg-[#333333]'}
            >
              <FontAwesomeIcon
                className={'h-16 w-16 aspect-square'}
                icon={faFileExcel}
              />
            </Card>
          );
        }

        return (
          <img
            key={index}
            className={'h-32 w-32 aspect-square rounded-xl object-cover'}
            src={URL.createObjectURL(file)}
          />
        );
      })}
    </div>
  );
};
