import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  createOne,
  dropWithData,
  findOne,
  getOne,
} from '../entity/entity.service';
import { useNotifier } from '../app/notification/notification-context';
import { SpecialityGroupEntity } from '../specialty-group/speciality-group.entity';
import {
  GroupRelationType,
  SpecialtyGroupRelationEntity,
} from './specialty-group-relation.entity';
import { useMap } from 'react-use';
import { UserEntity } from '../user/user.entity';

export type SpecialtyGroupRelationContextType = {
  relations: SpecialtyGroupRelationEntity;
  group: SpecialityGroupEntity | undefined;

  setRelation: (userId: number, type: GroupRelationType) => void;
  dropRelation: (userId: number, type: GroupRelationType) => void;
};

export const SpecialtyGroupRelationContext =
  createContext<SpecialtyGroupRelationContextType>(
    {} as SpecialtyGroupRelationContextType,
  );

export type SpecialtyGroupRelationProps = {
  groupId?: string;
};

export const SpecialtyGroupRelationProvider = ({
  children,
  groupId,
}: PropsWithChildren & SpecialtyGroupRelationProps) => {
  const { notify } = useNotifier();

  const [relations, { set, setAll }] = useMap<SpecialtyGroupRelationEntity>({
    boss: undefined,
    curators: [],
    students: [],
    captains: [],
  });

  const [group, setGroup] = useState<SpecialityGroupEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    findOne<SpecialityGroupEntity>('groups', Number(groupId))
      .then(response => setGroup(response.data))
      .then(() =>
        getOne<SpecialtyGroupRelationEntity>(`/groups/${groupId}/relations`),
      )
      .then(response => setAll(response.data))
      .catch(() => notify('error', 'Ошибка загрузки данных'));
  }, [groupId, setAll]);

  const setRelation = useCallback(
    (userId: number, type: GroupRelationType) => {
      findOne<UserEntity>('users', userId)
        .then(async response => {
          await createOne<void>(`/groups/${groupId}/relations/`, {
            userId,
            type,
          });

          return response.data;
        })
        .then(user => {
          if (type === GroupRelationType.CAPTAIN) {
            set('captains', [...relations.captains, user]);
          } else if (type === GroupRelationType.CURATOR) {
            set('curators', [...relations.curators, user]);
          } else if (type === GroupRelationType.STUDENT) {
            set('students', [...relations.students, user]);
          }

          notify('success', 'Связь успешно установлена!');
        })
        .catch(() => notify('error', 'Ошибка установки связи!'));
    },
    [groupId, relations, set],
  );

  const dropRelation = useCallback(
    (userId: number, type: GroupRelationType) => {
      dropWithData<void>(`/groups/${groupId}/relations/`, {
        userId,
        type,
      })
        .then(() => {
          if (type === GroupRelationType.CAPTAIN) {
            set(
              'captains',
              relations.captains.filter(item => item.id !== userId),
            );
          } else if (type === GroupRelationType.CURATOR) {
            set(
              'curators',
              relations.curators.filter(item => item.id !== userId),
            );
          } else if (type === GroupRelationType.STUDENT) {
            set(
              'students',
              relations.students.filter(item => item.id !== userId),
            );
          }

          notify('success', 'Связь успешно удалена!');
        })
        .catch(() => notify('error', 'Ошибка удалении связи!'));
    },
    [groupId, relations, set],
  );

  const value = useMemo(
    () => ({
      group,
      relations,

      setRelation,
      dropRelation,
    }),
    [group, relations, setRelation, dropRelation],
  );

  return (
    <SpecialtyGroupRelationContext.Provider value={value}>
      {children}
    </SpecialtyGroupRelationContext.Provider>
  );
};

export const useSpecialtyGroupRelation = () =>
  useContext<SpecialtyGroupRelationContextType>(SpecialtyGroupRelationContext);
