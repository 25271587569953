export enum TimeType {
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
  SHORTED = 'SHORTED',
}

export const typeTitle = {
  [TimeType.WEEKDAY]: 'Будни',
  [TimeType.WEEKEND]: 'Выходные',
  [TimeType.SHORTED]: 'Сокращенное',
};
