import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import './theme.css';

export type ThemeVariantType = 'dark' | 'light';

export type ThemeContextType = {
  toggle: () => void;
};

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType,
);

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [currentTheme, setTheme] = useState<ThemeVariantType>('light');

  useEffect(() => {
    // if (currentTheme === "dark") {
    //   task.body.classList.add("dark");
    // } else {
    //   task.body.classList.remove("dark");
    // }
  }, [currentTheme]);

  const toggle = useCallback(() => {
    setTheme(currentValue => (currentValue === 'dark' ? 'light' : 'dark'));
  }, [setTheme]);

  const value = useMemo(
    () => ({
      toggle,
    }),
    [toggle],
  );

  return (
    <ThemeContext.Provider value={value}>
      <div className={'h-screen dark:text-white'}>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext<ThemeContextType>(ThemeContext);
