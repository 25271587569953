import { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';
import { ErrorCard } from '../../block/error-card.component';

export type TextAreaProps = {
  background?: boolean;
  error?: string;
  rows?: number;
};

export const TextArea = forwardRef(
  (
    props: InputHTMLAttributes<HTMLTextAreaElement> & TextAreaProps,
    ref: LegacyRef<HTMLTextAreaElement>,
  ) => {
    return (
      <div className={'flex flex-col gap-2'}>
        <textarea
          ref={ref}
          rows={props.rows ?? 10}
          {...props}
          className={`px-4 py-2 w-full rounded-lg text-md bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6] dark:placeholder-[#e1e3e6] ${props.className}`}
        />

        {props.error && <ErrorCard error={props.error} />}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';
