import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { createPlan, getPlans } from './plan.service';
import { MetaTypeEntity } from '../common/networking/entity/meta-type.entity';
import { JournalPlanMetaEntity } from './entity/journal-plan-meta.entity';
import { JournalPlanItemEntity } from './entity/journal-plan-item.entity';
import { useNotifier } from '../app/notification/notification-context';
import { useNavigate } from 'react-router-dom';

export type JournalContextType = {
  setQuery: (value: object) => void;
  page: number;
  setPage: (value: number) => void;

  create: (value: JournalPlanItemEntity) => void;

  isLoading: boolean;
  results: MetaTypeEntity<JournalPlanMetaEntity, JournalPlanItemEntity>;
};

export const PlanContext = createContext<JournalContextType>(
  {} as JournalContextType,
);

export const PlanProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();
  const navigate = useNavigate();
  const [query, setQuery] = useState<object>({});
  const [page, setPage] = useState<number>(0);

  const results = useQuery({
    queryKey: [query, page],
    queryFn: () =>
      getPlans({
        ...query,

        offset: page * 10,
        limit: 10,
      }),
  });

  const create = useCallback((value: JournalPlanItemEntity) => {
    createPlan(
      value.group.specialtyId,
      value.plan.subject.id,
      2023,
      value.group.course,
      value.group.id,
    )
      .then(plan => {
        notify('success', 'Календарно-тематический план успешно создан!');
        navigate(`/journals/plans/${plan.id}/editor/`);
      })
      .catch(() =>
        notify('error', 'Ошибка создания календарно-тематического плана!'),
      );
  }, []);

  const value = useMemo(
    () => ({
      setQuery,
      page,
      setPage,

      create,

      isLoading: false,
      results:
        results.data ??
        ({} as MetaTypeEntity<JournalPlanMetaEntity, JournalPlanItemEntity>),
    }),
    [setQuery, page, setPage, create, results],
  );

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

export const usePlan = () => useContext<JournalContextType>(PlanContext);
