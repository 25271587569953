import { usePlan } from '../../plan.context';
import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';
import { InputSelect } from '../../../common/component/input-select/input-select';
import { useState } from 'react';

export const PlanHeaderCloneModal = () => {
  const { modal, setModal, clone, revisions } = usePlan();

  const [from, setFrom] = useState<number>();
  const [to, setTo] = useState<number>();

  return (
    <Modal
      showed={modal.type === 'clone'}
      title={'Клонирование учебных планов'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col'}>
          <h1>Внимание!</h1>
          <div>
            Выполняя операцию клонирования, все данные во входящем учебном плане
            будут удалены!
          </div>
        </div>

        <InputSelect
          title={'Из'}
          selected={from}
          items={revisions.map(item => ({
            title: item.toString(),
            value: item,
          }))}
          onChange={value => setFrom(Number(value))}
        />

        <InputSelect
          title={'В'}
          selected={to}
          items={revisions.map(item => ({
            title: item.toString(),
            value: item,
          }))}
          onChange={value => setTo(Number(value))}
        />

        <div className={'flex flex-row gap-4'}>
          <Button
            color={'green'}
            disabled={from === undefined || to === undefined}
            onClick={() => clone(from as number, to as number)}
          >
            Да, клонировать
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
