import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../app/networking/axios';
import {
  JournalCalendarPlanItemEntity,
  JournalPlanItemType,
} from '../journal-plan/entity/journal-calendar-plan.item.entity';
import { useNotifier } from '../app/notification/notification-context';
import { useNavigate } from 'react-router-dom';
import { JournalPlanStatus } from '../journal-plan/entity/journal-plan-status.entity';
import toast from 'react-hot-toast';

export type PlanEditorModalNoneType = { type: 'none' };
export type PlanEditorModalDeleteType = { type: 'delete' };
export type PlanEditorModalExportType = { type: 'export' };
export type PlanEditorModalImportType = { type: 'import' };
export type PlanEditorModalVerifyType = { type: 'verify' };
export type PlanEditorModalAcceptType = { type: 'accept' };
export type PlanEditorModalDeclineType = { type: 'decline' };
export type PlanEditorModalDeleteCategoryType = { type: 'delete-category' };
export type PlanEditorModalDeleteItemType = { type: 'delete-item' };

export type PlanEditorModalType =
  | PlanEditorModalNoneType
  | PlanEditorModalDeleteType
  | PlanEditorModalDeleteCategoryType
  | PlanEditorModalDeleteItemType
  | PlanEditorModalExportType
  | PlanEditorModalImportType
  | PlanEditorModalVerifyType
  | PlanEditorModalAcceptType
  | PlanEditorModalDeclineType;

export type PlanEditorContextProps = {
  planId: number;
};

export type PlanEditorContextType = {
  plan: JournalCalendarPlanItemEntity;
  readonly: boolean;

  modal: PlanEditorModalType;
  setModal: (value: PlanEditorModalType) => void;

  isLoading: boolean;

  doImport: (file: File) => void;
  doExport: () => string;

  accept: () => void;
  decline: (note: string) => void;

  addCategory: () => void;
  updateCategory: (id: number, title: string) => void;
  removeCategory: (id: number) => void;

  addItem: (categoryId: number) => void;

  updateItem: (
    categoryId: number,
    id: number,
    data: {
      title?: string;
      hours?: number;
      month?: number;
      type?: JournalPlanItemType;
      visual?: string;
      homework?: string;
      note?: string;
    },
  ) => void;
  removeItem: (categoryId: number, id: number) => void;

  update: (status: JournalPlanStatus) => void;
  drop: () => void;
};

export const PlanEditorContext = createContext<PlanEditorContextType>(
  {} as PlanEditorContextType,
);

export const PlanEditorProvider = ({
  planId,
  children,
}: PropsWithChildren<PlanEditorContextProps>) => {
  const navigate = useNavigate();
  const { notify } = useNotifier();

  const [modal, setModal] = useState<PlanEditorModalType>({ type: 'none' });

  const plan = useQuery({
    queryKey: ['plan', planId],
    queryFn: () =>
      api
        .get<JournalCalendarPlanItemEntity>(`/journal/plan/${planId}`)
        .then(data => data.data),
  });

  const doImport = useCallback(
    async (file: File) => {
      const data = new FormData();

      data.append('file', file);

      await toast.promise(
        api
          .post(`/journal/plan/${plan.data?.id}/import/`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => plan.refetch())
          .then(() => setModal({ type: 'none' })),
        {
          loading: 'Загружаем файл',
          success: 'Файл успешно импортирован',
          error: 'Ошибка импортирования файла',
        },
      );
    },
    [plan],
  );

  const doExport = useCallback(() => 'https://google.com', [plan]);

  const accept = useCallback(
    () =>
      toast.promise(
        api
          .put(`/journal/plan/${plan.data?.id}/accept`)
          .then(() => plan.refetch())
          .then(() => setModal({ type: 'none' })),
        {
          loading: 'Одобряем календарно-тематический план',
          success: 'Календарно-тематический план успешно одобрен',
          error: 'Ошибка одобрения календарно-тематического плана',
        },
      ),
    [plan],
  );

  const decline = useCallback(
    (note: string) =>
      toast.promise(
        api
          .put(`/journal/plan/${plan.data?.id}/decline`, { note })
          .then(() => plan.refetch())
          .then(() => setModal({ type: 'none' })),
        {
          loading: 'Отказываем календарно-тематический план',
          success: 'Календарно-тематический план успешно отказан',
          error: 'Ошибка отказа календарно-тематического плана',
        },
      ),
    [plan],
  );

  const addCategory = useCallback(
    () =>
      api
        .post(`/journal/plan/${plan.data?.id}/category`)
        .then(() => plan.refetch())
        .then(() => notify('success', 'Категория успешно добавлена'))
        .catch(() => notify('error', 'Ошибка добавления категории')),
    [plan],
  );

  const updateCategory = useCallback(
    (id: number, title: string) =>
      api
        .put(`/journal/plan/${plan.data?.id}/category/${id}`, {
          title,
        })
        .then(() => plan.refetch())
        .then(() => notify('success', 'Категория успешно добавлена'))
        .catch(() => notify('error', 'Ошибка добавления категории')),
    [plan],
  );

  const removeCategory = useCallback(
    (id: number) =>
      api
        .delete(`/journal/plan/${plan.data?.id}/category/${id}`)
        .then(() => plan.refetch())
        .then(() => notify('success', 'Категория успешно удалена'))
        .catch(() => notify('error', 'Ошибка удаления категории')),
    [plan],
  );

  const addItem = useCallback(
    (categoryId: number) =>
      api
        .post(`/journal/plan/${plan.data?.id}/category/${categoryId}/item`)
        .then(() => plan.refetch())
        .then(() => notify('success', 'Тема успешно добавлена'))
        .catch(() => notify('error', 'Ошибка добавления темы')),
    [plan],
  );

  const updateItem = useCallback(
    (
      categoryId: number,
      id: number,
      data: {
        title?: string;
        hours?: number;
        month?: number;
        type?: JournalPlanItemType;
        visual?: string;
        homework?: string;
        note?: string;
      },
    ) =>
      api
        .put(
          `/journal/plan/${plan.data?.id}/category/${categoryId}/item/${id}`,
          data,
        )
        .then(() => plan.refetch())
        .then(() => notify('success', 'Тема успешно добавлена'))
        .catch(() => notify('error', 'Ошибка добавления темы')),
    [plan],
  );

  const removeItem = useCallback(
    (categoryId: number, id: number) =>
      api
        .delete(
          `/journal/plan/${plan.data?.id}/category/${categoryId}/item/${id}`,
        )
        .then(() => plan.refetch())
        .then(() => notify('success', 'Тема успешно добавлена'))
        .catch(() => notify('error', 'Ошибка удаления темы')),
    [plan],
  );

  const update = useCallback(
    (status: JournalPlanStatus) =>
      api
        .put(`/journal/plan/${plan.data?.id}/`, {
          status,
        })
        .then(() => plan.refetch())
        .then(() => setModal({ type: 'none' }))
        .then(() =>
          notify('success', 'Календарно-тематический план успешно обновлен'),
        )
        .catch(() =>
          notify('error', 'Ошибка обновлен календарно-тематического плана'),
        ),
    [plan],
  );

  const drop = useCallback(
    () =>
      api
        .delete(`/journal/plan/${plan.data?.id}/`)
        .then(() => navigate('/journals/plans/'))
        .then(() =>
          notify('success', 'Календарно-тематический план успешно удален'),
        )
        .catch(() =>
          notify('error', 'Ошибка удаления календарно-тематического плана'),
        ),
    [plan],
  );

  const value = useMemo(
    () => ({
      plan: plan.data ?? ({} as JournalCalendarPlanItemEntity),
      readonly: plan?.data?.status?.status !== 'IN_PROGRESS' ?? false,
      isLoading: plan.isLoading,

      modal,
      setModal,

      doImport,
      doExport,

      accept,
      decline,

      addCategory,
      updateCategory,
      removeCategory,
      addItem,
      updateItem,
      removeItem,

      update,
      drop,
    }),
    [
      plan,

      modal,
      setModal,

      doImport,
      doExport,

      accept,
      decline,

      addCategory,
      updateCategory,
      removeCategory,
      addItem,
      updateItem,
      removeItem,

      update,
      drop,
    ],
  );

  return (
    <PlanEditorContext.Provider value={value}>
      {children}
    </PlanEditorContext.Provider>
  );
};

export const usePlanEditor = () =>
  useContext<PlanEditorContextType>(PlanEditorContext);
