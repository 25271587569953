import { Card } from '../../../common/block/card.component';
import { StatementBodyStudents } from './students/statement-body-students.component';
import { StatementBodySubjects } from './subjects/statement-body-subjects.component';

export const StatementBody = () => {
  return (
    <Card className={'flex flex-row'}>
      <StatementBodyStudents />
      <StatementBodySubjects />
    </Card>
  );
};
