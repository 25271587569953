import {
  EntityBasicColumnType,
  EntityColumnInputType,
  EntityColumnVariationType,
} from './entity.type';

export const retrieveValue = (
  input: EntityColumnInputType,
  predefined?: number | string,
): EntityColumnVariationType & EntityBasicColumnType =>
  typeof input === 'function' ? input(predefined ?? 0) : input;
