import { HTMLAttributes, PropsWithChildren } from 'react';

export type CardProps = {
  paddingX?: number;
  background?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Card = (props: PropsWithChildren<CardProps>) => {
  return (
    <div
      {...props}
      className={`w-full rounded-xl ${
        (props.background ?? true) && 'bg-white dark:bg-[#222222]'
      } ${props.className} `}
    >
      {props.children}
    </div>
  );
};
