import { EntityModule } from '../entity';
import { BuildingEntity } from './building.entity';

export const BuildingModule = () => (
  <EntityModule<BuildingEntity>
    title={'Корпуса'}
    route={'buildings'}
    columns={[
      {
        key: 'title',
        title: 'Название',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => item.title,
      },
      {
        key: 'minFloors',
        title: 'Минимальный этаж',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => item.minFloors,
      },
      {
        key: 'maxFloors',
        title: 'Максимальный этаж',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => item.maxFloors,
      },
      {
        key: 'description',
        title: 'Описание',
        input: {
          type: 'textarea',
          options: {
            required: 'Данное поле обязательно',
          },
        },
      },
    ]}
    links={[
      {
        title: 'Аудитории',
        color: 'blue',
        link: item => `${item.id}/classrooms`,
      },
      {
        title: 'Звонки',
        color: 'yellow',
        link: item => `${item.id}/time`,
      },
    ]}
  />
);
