import { PlanItemEntity } from '../../../plan-item.entity';
import { PlanTableContentCategoryHeaderInput } from './plan-table-content-category-header-input.component';
import { PlanTableContentCategoryHeaderExam } from './plan-table-content-category-header-exam.component';

export type PlanTableContentCategoryHeaderProps = {
  plan?: PlanItemEntity;
};

export const PlanTableContentCategoryHeader = ({
  plan,
}: PlanTableContentCategoryHeaderProps) => {
  return (
    <div
      className={
        'flex flex-row border-b-2 border-[#F0F2F5] dark:border-[#333333] bg-gray-100/70 dark:text-white dark:bg-neutral-700'
      }
    >
      <PlanTableContentCategoryHeaderInput plan={plan} />
      {plan && <PlanTableContentCategoryHeaderExam plan={plan} />}
    </div>
  );
};
