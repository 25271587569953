import { Card } from '../../common/block/card.component';
import { useJournalItems } from '../journal-items.context';
import { CardLoader } from '../../common/component/loading/card-loader.component';
import { JournalMeta } from './meta/journal-meta.component';
import { JournalHeader } from './header/journal-header.component';
import { JournalTable } from './table/journal-table.component';

export const JournalContent = () => {
  const { isLoading, availabilities, groupId, subjectId } = useJournalItems();

  if (isLoading) {
    return <CardLoader />;
  }

  if (availabilities?.subjects?.length === 0) {
    return (
      <div className={'flex flex-col justify-center items-center'}>
        <Card className={'p-4'}>Для вас нет доступных журналов.</Card>
      </div>
    );
  }

  return (
    <div className={'flex flex-row gap-4'}>
      <div className={'w-9/12'}>
        {groupId && subjectId ? (
          <Card
            className={
              'grid grid-rows-[repeat(2,_0.2fr)_repeat(100,_1fr)] overflow-scroll no-scrollbar h-[90vh]'
            }
          >
            <JournalHeader />
            <JournalTable />
          </Card>
        ) : (
          <Card className={'p-4 flex flex-col items-center justify-center'}>
            Необходимо выбрать академическую группу и дисциплину
          </Card>
        )}
      </div>
      <div className={'w-3/12'}>
        <JournalMeta />
      </div>
    </div>
  );
};
