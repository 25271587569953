import { PlanItemEntity } from '../../../plan-item.entity';
import { PlanTableContentCategorySubjectInput } from './plan-table-content-category-subject-input.component';
import { PlanTableContentCategorySubjectExam } from './plan-table-content-category-subject-exam.component';

export type PlanTableContentCategorySubjectProps = {
  root: PlanItemEntity;
  plan?: PlanItemEntity;
};

export const PlanTableContentCategorySubject = ({
  root,
  plan,
}: PlanTableContentCategorySubjectProps) => {
  return (
    <div
      className={
        'flex flex-row border-b-2 border-[#F0F2F5] dark:border-[#333333]'
      }
    >
      <PlanTableContentCategorySubjectInput root={root} plan={plan} />
      {plan && <PlanTableContentCategorySubjectExam root={root} plan={plan} />}
    </div>
  );
};
