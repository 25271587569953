import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSearchNumericState } from '../common/hook/use-search-state.hook';
import {
  JournalAvailabilitiesEntity,
  JournalMetaEntity,
} from './journal-items.entity';
import { useQueries } from '@tanstack/react-query';
import { fetchAvailabilities, fetchMeta } from './journal-items.service';

export type JournalItemsContextType = {
  groupId: number | undefined;
  setGroupId: (id: number) => void;

  subjectId: number | undefined;
  setSubjectId: (id: number) => void;

  totals: boolean;
  setTotals: (value: boolean) => void;

  availabilities?: JournalAvailabilitiesEntity;
  meta?: JournalMetaEntity;

  isLoading: boolean;
};

export const JournalItemsContext = createContext<JournalItemsContextType>(
  {} as JournalItemsContextType,
);

export const JournalItemsProvider = ({ children }: PropsWithChildren) => {
  const [groupId, setGroupId] = useSearchNumericState('groupId', undefined);
  const [subjectId, setSubjectId] = useSearchNumericState(
    'subjectId',
    undefined,
  );

  const [totals, setTotals] = useState(false);

  const [availabilities, meta] = useQueries({
    queries: [
      {
        queryKey: ['availabilities'],
        queryFn: () => fetchAvailabilities(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['meta', groupId, subjectId],
        queryFn: () => fetchMeta(Number(groupId), Number(subjectId)),
        enabled: groupId !== undefined && subjectId !== undefined,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const value = useMemo(
    () => ({
      groupId,
      setGroupId,
      subjectId,
      setSubjectId,
      totals,
      setTotals,

      availabilities: availabilities.data,
      meta: meta.data,

      isLoading:
        availabilities.isLoading || (meta.isFetching && meta.isLoading),
    }),
    [
      groupId,
      setGroupId,
      subjectId,
      setSubjectId,
      totals,
      setTotals,
      availabilities,
      meta,
    ],
  );

  return (
    <JournalItemsContext.Provider value={value}>
      {children}
    </JournalItemsContext.Provider>
  );
};

export const useJournalItems = () =>
  useContext<JournalItemsContextType>(JournalItemsContext);
