import { Tab } from '@headlessui/react';
import { Header } from './header.component';
import React from 'react';
import { useBuildingTime } from '../building-time.context';
import { TimeSchedule } from './time-schedule.component';
import { TimeScheduleShorted } from './time-schedule-shorted.component';

export const BuildingTimeContent = () => {
  const { index, setIndex } = useBuildingTime();

  return (
    <div className={'flex flex-col gap-4 container mx-auto h-[90vh]'}>
      <Tab.Group onChange={value => setIndex(value)} selectedIndex={index}>
        <Header />

        <Tab.Panel key={0}>
          <TimeSchedule />
        </Tab.Panel>
        <Tab.Panel key={1}>
          <TimeSchedule />
        </Tab.Panel>
        <Tab.Panel key={2}>
          <TimeSchedule />
        </Tab.Panel>
        <Tab.Panel key={3}>
          <TimeScheduleShorted />
        </Tab.Panel>
      </Tab.Group>
    </div>
  );
};
