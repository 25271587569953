import { Popover, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren } from 'react';
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from './dropdown-menu-item.component';

export type DropdownMenuProps = {
  title: string;
  items?: DropdownMenuItemType[];
};

export const DropdownMenu = ({
  title,
  items,
  children,
}: DropdownMenuProps & PropsWithChildren) => {
  return (
    <div className="top-16">
      <Popover className="relative">
        <>
          <Popover.Button>
            <span>{title}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-6 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="rounded-lg shadow-lg bg-white dark:bg-[#141414] w-screen max-w-xl">
                <div className="relative w-full p-4 flex flex-col gap-2">
                  {items && (
                    <div>
                      {items.map(item => (
                        <DropdownMenuItem key={item.link} {...item} />
                      ))}
                    </div>
                  )}
                  {children && children}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
    </div>
  );
};
