import { SpecialityGroupEntity } from '../../../specialty-group/speciality-group.entity';
import { UserEntity } from '../../../user/user.entity';
import { Fragment } from 'react';
import { ScheduleContext } from '../../schedule.context';
import { useContextSelector } from 'use-context-selector';

export type ScheduleItemColumnProps = {
  header: SpecialityGroupEntity | UserEntity;
};

export const ScheduleItemColumn = ({ header }: ScheduleItemColumnProps) => {
  const edit = useContextSelector(ScheduleContext, context => context.edit);

  const isGroupsView = useContextSelector(
    ScheduleContext,
    context => context.isGroupsView,
  );

  const itemsFor = useContextSelector(
    ScheduleContext,
    context => context.itemsFor,
  );

  const itemsCountFor = useContextSelector(
    ScheduleContext,
    context => context.itemsCountFor,
  );

  const lessonSize = useContextSelector(
    ScheduleContext,
    context => context.lessonSize,
  );

  const lessonsCount = useContextSelector(
    ScheduleContext,
    context => context.lessonsCount,
  );

  const isDuplicated = useContextSelector(
    ScheduleContext,
    context => context.isDuplicated,
  );

  return (
    <div className={'flex flex-col'}>
      <div
        className={`flex flex-col p-1 text-center border-r border-b border-[#F0F2F5] dark:border-[#333333] sticky top-0 dark:bg-[#333333] bg-[#E5E6E7] z-10 truncate whitespace-nowrap`}
        style={{
          width: `${lessonSize}rem`,
        }}
      >
        <div>{isGroupsView ? header.title : (header as UserEntity).pretty}</div>
        <div>
          Вычитка: {itemsCountFor(header.id)} / {itemsCountFor(header.id) * 2}
        </div>
      </div>

      {Array.from(Array(6).keys()).map(day => (
        <Fragment key={day}>
          {Array.from(Array(lessonsCount).keys()).map(position => {
            const items = itemsFor(day, position, header.id);

            return (
              <button
                key={`${day}-${position}`}
                className={`flex flex-col gap-2 w-full cursor-pointer border-r border-b border-[#F0F2F5] dark:border-[#333333]`}
                onClick={() => edit(header.id, day, position)}
                style={{
                  width: `${lessonSize}rem`,
                  height: `${lessonSize}rem`,
                }}
              >
                {Array.from(Array(2).keys()).map(index => {
                  const item = items.find(
                    toFind =>
                      (!toFind.subgroup && index === 0) ||
                      toFind.subgroup === index,
                  );

                  if (!item) {
                    return <div key={index} className={'h-1/2'} />;
                  }

                  const isTeacherDuplicated = !isGroupsView
                    ? false
                    : isDuplicated(day, position, 'teacher', item.teacher.id);

                  const isClassroomDuplicated =
                    !isGroupsView || !item.classroom || item.isOnline
                      ? false
                      : isDuplicated(
                          day,
                          position,
                          'classroom',
                          item.classroom.id,
                        );

                  return (
                    <div
                      key={item.id}
                      className={
                        'text-left flex flex-col text-white w-full h-1/2'
                      }
                    >
                      <div
                        className={`border-l-4 whitespace-nowrap truncate ${
                          item.subgroup != undefined
                            ? 'border-[#115e59] bg-[#14b8a6]'
                            : 'border-[#183FD5] bg-[#3B82F7]'
                        } px-1`}
                      >
                        {item.plan.subject.short ?? item.plan.subject.title}
                      </div>
                      <div
                        className={`border-l-4 px-1 whitespace-nowrap truncate ${
                          isTeacherDuplicated
                            ? 'border-[#B72622] bg-[#EB5545] text-white'
                            : 'border-[#E0E2E6] text-black dark:text-white'
                        }`}
                      >
                        {isGroupsView ? item.teacher.pretty : item.group.title}
                      </div>
                      <div
                        className={`border-l-4 px-1 whitespace-nowrap truncate ${
                          isClassroomDuplicated
                            ? 'border-[#B72622] bg-[#EB5545] text-white'
                            : item.isOnline
                            ? 'border-[#A6561F] bg-[#F8D84A] text-black dark:text-white'
                            : item.isStreet
                            ? 'border-[#FFBF00] text-black dark:text-white'
                            : 'border-[#E0E2E6] text-black dark:text-white'
                        }`}
                      >
                        {item.isOnline
                          ? 'Дистанционное'
                          : item.isStreet
                          ? 'На улице'
                          : item.classroom?.title ?? 'null'}
                      </div>
                      <div></div>
                    </div>
                  );
                })}
              </button>
            );
          })}
        </Fragment>
      ))}
    </div>
  );
};
