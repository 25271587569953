import { convertToRoman } from '../../../../common/utils/number.utils';
import { usePlan } from '../../../plan.context';

export const PlanTableHeaderDistribution = () => {
  const { specialty, base, semesters } = usePlan();

  const years = base === 'FULL' ? specialty.fullYears : specialty.notFullYears;

  return (
    <div className={'flex flex-col text-center w-2/4'}>
      <div
        className={
          'flex items-center justify-center p-2 border-b-2 border-[#F0F2F5] dark:border-[#333333]'
        }
      >
        Объем образовательной программы (академических часов)
      </div>
      <div className={'flex flex-row text-center w-full'}>
        {Array.from(Array(years).keys()).map(year => {
          const filteredSemesters = semesters.filter(
            semester => semester.year === year,
          );

          return (
            <div key={year} className={'w-full'}>
              <div className={`flex flex-col`}>
                <div
                  className={`flex items-center justify-center py-2 border-b-2 border-[#F0F2F5] dark:border-[#333333] ${
                    year + 1 !== years && 'border-r-2'
                  }`}
                >
                  {year + 1} курс
                </div>
                <div className={'flex flex-row'}>
                  {filteredSemesters.length > 0 ? (
                    filteredSemesters.map((semester, index) => (
                      <div
                        key={semester.countThrough}
                        className={`flex items-center justify-center p-2 border-[#F0F2F5] dark:border-[#333333] ${
                          year + 1 === years &&
                          index + 1 === filteredSemesters.length
                            ? ''
                            : 'border-r-2'
                        } ${
                          filteredSemesters.length === 1
                            ? 'w-full'
                            : `w-1/${filteredSemesters.length}`
                        }`}
                      >
                        {convertToRoman(semester.countThrough + 1)}
                      </div>
                    ))
                  ) : (
                    <div
                      className={`flex w-full py-2 justify-center items-center border-[#F0F2F5] dark:border-[#333333] ${
                        year + 1 !== years && 'border-r-2'
                      }`}
                    >
                      Семестры отсутствуют
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
