import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { NotificationView } from './notification-component';
import toast from 'react-hot-toast';

export type NotifyType = 'success' | 'warning' | 'error';

export type NotificationContextType = {
  notify: (type: NotifyType, message: string) => void;
};

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType,
);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const notify = useCallback((type: NotifyType, message: string) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    } else if (type === 'warning') {
      toast.custom(message);
    }
  }, []);

  const memo = useMemo(
    () => ({
      notify,
    }),
    [notify],
  );

  return (
    <NotificationContext.Provider value={memo}>
      <div>
        <NotificationView />
        {children}
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotifier = () => useContext(NotificationContext);
