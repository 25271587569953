import { SubjectClassificationModal } from './components/classification/subject-classification-modal.component';
import { SubjectProvider } from './subject.context';
import { SubjectTable } from './components/subject-table.component';

export const SubjectModule = () => (
  <SubjectProvider>
    <SubjectTable />

    <SubjectClassificationModal />
  </SubjectProvider>
);
