import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Popover } from '@headlessui/react';

export type DropdownMenuItemType = {
  title: string;
  description: string;
  link: string;
  icon: IconProp;
};

export const DropdownMenuItem = ({
  title,
  description,
  link,
  icon,
}: DropdownMenuItemType) => (
  <Popover.Button
    as={NavLink}
    to={link}
    className="flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 dark:hover:bg-[#222222] focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
  >
    <div
      className={`rounded-lg flex h-14 w-14 shrink-0 items-center justify-center text-white bg-[#222222] dark:bg-white hover:bg-white dark:hover:bg-[#222222]`}
    >
      <FontAwesomeIcon
        className={
          'text-2xl text-white dark:text-[#222222] hover:text-[#222222] dark:hover:text-white'
        }
        icon={icon}
      />
    </div>
    <div className="ml-4">
      <p className="text-sm font-medium text-gray-900 dark:text-white">
        {title}
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-4">
        {description}
      </p>
    </div>
  </Popover.Button>
);
