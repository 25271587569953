import { ScheduleContext } from '../../schedule.context';
import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';
import { useContextSelector } from 'use-context-selector';

export const ScheduleBottomConfirmModal = () => {
  const modal = useContextSelector(ScheduleContext, context => context.modal);

  const setModal = useContextSelector(
    ScheduleContext,
    context => context.setModal,
  );

  const confirm = useContextSelector(
    ScheduleContext,
    context => context.confirm,
  );

  const title = modal.type === 'confirm' ? modal.title : '';
  const description = modal.type === 'confirm' ? modal.description : '';

  return (
    <Modal
      showed={modal.type === 'confirm'}
      title={title}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-4'}>
        <h1>{description}</h1>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={confirm}>
            Да
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>Нет</Button>
        </div>
      </div>
    </Modal>
  );
};
