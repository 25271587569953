import { Fragment } from 'react';
import { PlanItemEntity } from '../../../plan-item.entity';
import { AutocompleteService } from '../../../../../../common/component/autocomplete-service/autocomplete-service.component';
import { SubjectEntity } from '../../../../../../subject/subject.entity';
import { usePlan } from '../../../../../plan.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';

export type PlanTableContentCategorySubjectInputProps = {
  root: PlanItemEntity;
  plan?: PlanItemEntity;
};

export const PlanTableContentCategorySubjectInput = ({
  root,
  plan,
}: PlanTableContentCategorySubjectInputProps) => {
  const { createItem, dropItem, hoursCountByPlan, disabledSubjects, setModal } =
    usePlan();

  return (
    <div className={`flex flex-row ${plan ? 'w-2/4' : 'w-full'}`}>
      <div
        className={`flex items-center justify-center ${
          plan
            ? 'w-11/12 border-r-2 border-[#F0F2F5] dark:border-[#333333]'
            : 'w-full'
        }`}
      >
        <AutocompleteService<SubjectEntity>
          title={'Дисциплина'}
          background={false}
          route={'/subject'}
          selectedOnce={true}
          selected={plan?.subjectId}
          withoutSetter={!plan}
          disabled={disabledSubjects}
          onSelected={value => createItem(root.id, Number(value))}
          mapper={value => ({
            value: value.id,
            title: value.title,
          })}
        />
        {plan && (
          <div className={'flex flex-row gap-1'}>
            <button
              onClick={() =>
                setModal({
                  type: 'subjectTeachers',
                  rootId: root.id,
                  planId: plan?.id,
                })
              }
            >
              <FontAwesomeIcon
                icon={faEdit}
                className={'mr-2 text-gray-600 dark:text-white/70'}
              />
            </button>
            <button onClick={() => dropItem(root.id, plan.id)}>
              <FontAwesomeIcon
                icon={faTrashCan}
                className={'mr-2 text-gray-600 dark:text-white/70'}
              />
            </button>
          </div>
        )}
      </div>
      {plan && (
        <Fragment>
          <div
            className={`flex items-center justify-center w-1/12 border-r-2 border-[#F0F2F5] dark:border-[#333333]`}
          >
            {hoursCountByPlan(root.id, plan.id)}
          </div>
        </Fragment>
      )}
    </div>
  );
};
