import { UserEntity } from '../user/user.entity';
import { api } from '../app/networking/axios';

export const findAllClassifications = (subjectId: number) =>
  api.get<UserEntity[]>(`/subject/${subjectId}/classification`);

export const addClassification = (subjectId: number, userId: number) =>
  api.post(`/subject/${subjectId}/classification/${userId}`);

export const removeClassification = (subjectId: number, userId: number) =>
  api.delete(`/subject/${subjectId}/classification/${userId}`);
