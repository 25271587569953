import { UserEntity } from '../user/user.entity';

export type SpecialtyGroupRelationEntity = {
  boss?: UserEntity;

  curators: UserEntity[];

  captains: UserEntity[];

  students: UserEntity[];
};

export enum GroupRelationType {
  CURATOR = 'CURATOR',
  CAPTAIN = 'CAPTAIN',
  STUDENT = 'STUDENT',
}
