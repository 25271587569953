import React from 'react';
import ReactDOM from 'react-dom/client';
import * as luxon from 'luxon';
import * as Sentry from '@sentry/react';
import { Application } from './app/Application';

luxon.Settings.defaultLocale = 'ru';
luxon.Settings.defaultZone = 'UTC+7';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Application />,
);
