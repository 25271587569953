import { RoleEntity } from '../role.entity';
import { EntityModule } from '../../entity';
import { useRoles } from '../role.context';

export const RoleTable = () => {
  const { setRoleId } = useRoles();

  return (
    <EntityModule<RoleEntity>
      title={'Наборы'}
      route={'roles'}
      columns={[
        {
          key: 'title',
          title: 'Название',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item => item.title,
        },
        {
          key: 'priority',
          title: 'Приоритет',
          input: {
            type: 'number',
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item => item.priority,
        },
      ]}
      links={[
        {
          title: 'Полномочия',
          color: 'blue',
          onClick: role => setRoleId(role.id),
        },
      ]}
    />
  );
};
