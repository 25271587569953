import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { PermissionCategoryType } from './role.entity';
import { useQueries } from '@tanstack/react-query';
import { getOne } from '../entity/entity.service';
import { PermissionType } from './permission.constant';
import { useNotifier } from '../app/notification/notification-context';
import { api } from '../app/networking/axios';

export type RoleContextType = {
  roleId?: number;
  setRoleId: (value: number | undefined) => void;

  categories: PermissionCategoryType[];
  permissions: PermissionType[];

  isToggled: (permission: PermissionType) => boolean;
  togglePermissions: (permission: PermissionType, value: boolean) => void;
};

export const RoleContext = createContext<RoleContextType>(
  {} as RoleContextType,
);

export const RoleProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();
  const [roleId, setRoleId] = useState<number | undefined>(undefined);

  const [categories, permissions] = useQueries({
    queries: [
      {
        queryKey: ['categories'],
        queryFn: () =>
          getOne<PermissionCategoryType[]>('/roles/categories').then(
            response => response.data,
          ),
      },
      {
        queryKey: ['permissions', roleId],
        queryFn: () =>
          getOne<PermissionType[]>(`/roles/${roleId}/permissions`).then(
            response => response.data,
          ),
        enabled: roleId !== undefined,
      },
    ],
  });

  const isToggled = useCallback(
    (permission: PermissionType) =>
      (permissions.data ?? []).includes(permission),
    [permissions],
  );

  const togglePermissions = useCallback(
    (permission: PermissionType, value: boolean) => {
      (value
        ? api.post(`/roles/${roleId}/permissions`, {
            permission,
          })
        : api.delete(`/roles/${roleId}/permissions`, {
            data: {
              permission,
            },
          })
      )
        .then(() => permissions.refetch())
        .then(() => notify('success', 'Полномочия успешно изменены!'))
        .catch(() => notify('error', 'Ошибка изменений полномочий'));
    },
    [roleId, permissions],
  );

  const value = useMemo(
    () => ({
      roleId,
      setRoleId,

      categories: categories.data ?? [],
      permissions: permissions.data ?? [],

      isToggled,
      togglePermissions,
    }),
    [roleId, setRoleId, categories, permissions, isToggled, togglePermissions],
  );

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export const useRoles = () => useContext<RoleContextType>(RoleContext);
