import { Transition, Menu } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

export type MenuColorVariant = 'default' | 'blue' | 'red' | 'yellow' | 'green';

export type MenuProps = {
  header: ReactNode;
  headerStyled?: boolean;
  children: ReactNode;
};

export const Dropdown = ({ header, headerStyled, children }: MenuProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={
            headerStyled ?? true
              ? 'inline-flex justify-center rounded-md bg-[#222222] dark:bg-white dark:text-black px-4 py-2 text-sm text-white hover:bg-opacity-90 dark:hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              : ''
          }
        >
          {header}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 z-[500] origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-[#141414] shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-1.5">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
