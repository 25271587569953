import { usePlanEditor } from '../../plan-editor.context';
import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';

export const PlanEditorAcceptModalComponent = () => {
  const { modal, setModal, accept } = usePlanEditor();

  return (
    <Modal
      title={'Одобрить календарно-тематический план'}
      showed={modal.type === 'accept'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-row gap-4 mt-6'}>
          <Button color={'green'} onClick={accept}>
            Да, одобрить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
