import { useFileUploader } from '../file-uploader.context';
import { FileContainerStatusSuccess } from './file-container-status-success.component';
import { FileContainerStatusError } from './file-container-status-error.component';
import { FileCollection } from './file-collection.component';

export type FileContainerProps = {
  showSelected?: boolean;
};

export const FileContainer = ({ showSelected }: FileContainerProps) => {
  const { allowMultiple, button, input, files, isSuccess, isError } =
    useFileUploader();

  return (
    <div className={'flex flex-col gap-4'}>
      <button
        ref={button}
        className={`${
          isSuccess ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
        } w-full bg-[#F0F2F5] dark:bg-[#333333] rounded-xl p-4 flex flex-col gap-6 justify-center items-center`}
        type={'button'}
        disabled={isSuccess}
      >
        <input
          type="file"
          id="file"
          ref={input}
          style={{ display: 'none' }}
          multiple={allowMultiple}
        />

        {isError && <FileContainerStatusError />}

        {isSuccess ? (
          <FileContainerStatusSuccess />
        ) : (
          <div className={'text-black dark:text-[#e1e3e6]'}>
            Перетащите или выберите файлы...
          </div>
        )}
      </button>

      {(showSelected ?? true) && files.length > 0 && <FileCollection />}
    </div>
  );
};
