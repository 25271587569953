import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthModule } from '../../auth';
import { AuthRequiredComponent } from '../auth/auth-required.component';
import { HomeModule } from '../../home';
import { HomeLayout } from './layout/home.layout';
import { BuildingModule } from '../../building';
import { UserModule } from '../../user';
import { ClassroomModule } from '../../building-classroom';
import { SubjectModule } from '../../subject';
import { SpecialtyModule } from '../../specialty';
import { SpecialtyGroupModule } from '../../specialty-group';
import { SpecialtyPlanModule } from '../../specialty-plan';
import { ScheduleModule } from '../../schedule';
import { InstitutionModule } from '../../institution';
import { SpecialtyGroupRelationModule } from '../../specialty-group-relation';
import { RoleModule } from '../../role';
import { BuildingTimeModule } from '../../building-time';
import { PolicyModule } from '../../policy';
import { DevelopersModal } from '../../auth/components/terms/developers-modal.component';
import { SupportModule } from '../../support';
import { SpecialtyGroupStatementsModule } from '../../specialty-group-statements';
import { TaskModule } from '../../task';
import { JournalPlanModule } from '../../journal-plan';
import { JournalPlanEditorModule } from '../../journal-plan-editor';
import { JournalItemsModule } from '../../journal-items';
import { SimulationModal } from '../../auth/components/simulation-modal.component';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/policy'} element={<PolicyModule />} />
        <Route path={'/support'} element={<SupportModule />} />

        {/* public routes */}
        <Route element={<AuthRequiredComponent inverse={true} />}>
          <Route
            index
            path={'/'}
            element={<AuthModule isAllowedLoginBlock={false} />}
          />

          <Route
            index
            path={'/auth'}
            element={<AuthModule isAllowedLoginBlock={true} />}
          />
        </Route>

        {/* private routes */}
        <Route element={<AuthRequiredComponent />}>
          <Route element={<HomeLayout />}>
            <Route path={'/home'} element={<HomeModule />} />
            <Route path={'/buildings'} element={<BuildingModule />} />
            <Route path={'/subjects'} element={<SubjectModule />} />
            <Route path={'/specialties'} element={<SpecialtyModule />} />
            <Route path={'/institutions'} element={<InstitutionModule />} />
            <Route
              path={'/specialties/:specialtyId/groups'}
              element={<SpecialtyGroupModule />}
            />
            <Route
              path={'/specialties/:specialtyId/groups/:groupId/relations'}
              element={<SpecialtyGroupRelationModule />}
            />
            <Route
              path={'/specialties/:specialtyId/groups/:groupId/statements'}
              element={<SpecialtyGroupStatementsModule />}
            />
            <Route path={'/journals/plans/'} element={<JournalPlanModule />} />
            <Route
              path={'/journals/plans/:planId/editor/'}
              element={<JournalPlanEditorModule />}
            />
            <Route
              path={'/journals/items/subject/'}
              element={<JournalItemsModule />}
            />
            <Route
              path={'/specialties/:specialtyId/plans'}
              element={<SpecialtyPlanModule />}
            />
            <Route
              path={'/buildings/:buildingId/classrooms'}
              element={<ClassroomModule />}
            />
            <Route
              path={'/buildings/:buildingId/time'}
              element={<BuildingTimeModule />}
            />
            <Route path={'/roles'} element={<RoleModule />} />
            <Route path={'/users'} element={<UserModule />} />
            <Route path={'/schedule'} element={<ScheduleModule />} />
            <Route path={'/tasks'} element={<TaskModule />} />
          </Route>
        </Route>
      </Routes>

      <DevelopersModal />
      <SimulationModal />
    </BrowserRouter>
  );
};
