import { useParams } from 'react-router-dom';
import { GroupTable } from './components/group-table.component';
import { SpecialtyGroupProvider } from './specialty-group.context';

export type SpecialtyGroupParams = {
  specialtyId: string;
};

export const SpecialtyGroupModule = () => {
  const { specialtyId } = useParams<SpecialtyGroupParams>();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <GroupTable />
    </SpecialtyGroupProvider>
  );
};
