import {
  EntityActionType,
  EntityDefaultActions,
  EntityLinkType,
  EntityType,
} from '../../entity.type';
import { Dropdown } from '../../../common/component/dropdown/dropdown';
import { useEntity } from '../../entity.context';
import { Button } from '../../../common/component/button/button.component';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from '../../../common/component/dropdown/dropdown-item';

export type EntityTableActionsProps<ItemType> = {
  item: EntityType<ItemType>;
  predefined?: number | string | undefined;
  actions?: EntityActionType<ItemType>[];
  links?: EntityLinkType<ItemType>[];
  disabledActions?: EntityDefaultActions[];
};

export const EntityTableActions = <ItemType,>({
  item,
  predefined,
  actions,
  links,
  disabledActions,
}: EntityTableActionsProps<ItemType>) => {
  const { setModal, isActionsDisabled } = useEntity<ItemType>();

  return (
    <td className="py-3.5 pr-4 flex flex-row justify-end gap-2">
      {links &&
        links.map((link, index) =>
          link.link ? (
            <NavLink key={index} to={link.link(item)}>
              <Button color={link.color} small>
                {link.title}
              </Button>
            </NavLink>
          ) : (
            <Button
              key={index}
              color={link.color}
              small
              onClick={() => link.onClick?.(item)}
            >
              {link.title}
            </Button>
          ),
        )}

      {!isActionsDisabled && (
        <Dropdown header={'Действия'}>
          {actions?.map(action => (
            <DropdownItem
              key={action.title}
              {...action}
              onClick={() => action.onClick(item)}
            />
          ))}
          {!disabledActions?.includes('update') && (
            <DropdownItem
              title="Изменить"
              color="blue"
              onClick={() =>
                setModal({
                  type: 'update',
                  id: item.id,
                  groupedValue: predefined,
                })
              }
            />
          )}
          {!disabledActions?.includes('delete') && (
            <DropdownItem
              title="Удалить"
              color="red"
              onClick={() =>
                setModal({
                  type: 'drop',
                  id: item.id,
                })
              }
            />
          )}
        </Dropdown>
      )}
    </td>
  );
};
