export enum PermissionType {
  ROLE_CATEGORIES_LIST = 'ROLE_CATEGORIES_LIST',

  ROLE_LIST = 'ROLE_LIST',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DESTROY = 'ROLE_DESTROY',

  ROLE_PERMISSION_LIST = 'ROLE_PERMISSION_LIST',
  ROLE_PERMISSION_ADD = 'ROLE_PERMISSION_ADD',
  ROLE_PERMISSION_REMOVE = 'ROLE_PERMISSION_REMOVE',

  ROLE_USER_LIST = 'ROLE_USER_LIST',
  ROLE_USER_ADD = 'ROLE_USER_ADD',
  ROLE_USER_REMOVE = 'ROLE_USER_REMOVE',

  USER_LIST = 'USER_LIST',
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DESTROY = 'USER_DESTROY',

  SUBJECT_LIST = 'SUBJECT_LIST',
  SUBJECT_CREATE = 'SUBJECT_CREATE',
  SUBJECT_UPDATE = 'SUBJECT_UPDATE',
  SUBJECT_DESTROY = 'SUBJECT_DESTROY',

  SUBJECT_CLASSIFICATION_LIST = 'SUBJECT_CLASSIFICATION_LIST',
  SUBJECT_CLASSIFICATION_ADD = 'SUBJECT_CLASSIFICATION_ADD',
  SUBJECT_CLASSIFICATION_REMOVE = 'SUBJECT_CLASSIFICATION_REMOVE',

  SPECIALTY_GROUP_LIST = 'SPECIALTY_GROUP_LIST',
  SPECIALTY_GROUP_CREATE = 'SPECIALTY_GROUP_CREATE',
  SPECIALTY_GROUP_UPDATE = 'SPECIALTY_GROUP_UPDATE',
  SPECIALTY_GROUP_DESTROY = 'SPECIALTY_GROUP_DESTROY',

  SPECIALTY_GROUP_RELATION_LIST = 'SPECIALTY_GROUP_RELATION_LIST',
  SPECIALTY_GROUP_RELATION_LIST_STUDENT = 'SPECIALTY_GROUP_RELATION_LIST_STUDENT',
  SPECIALTY_GROUP_RELATION_ADD = 'SPECIALTY_GROUP_RELATION_ADD',
  SPECIALTY_GROUP_RELATION_REMOVE = 'SPECIALTY_GROUP_RELATION_REMOVE',

  SPECIALTY_LIST = 'SPECIALTY_LIST',
  SPECIALTY_CREATE = 'SPECIALTY_CREATE',
  SPECIALTY_UPDATE = 'SPECIALTY_UPDATE',
  SPECIALTY_DESTROY = 'SPECIALTY_DESTROY',

  POLL_LIST = 'POLL_LIST',
  POLL_CREATE = 'POLL_CREATE',
  POLL_UPDATE = 'POLL_UPDATE',
  POLL_VOTE = 'POLL_VOTE',
  POLL_DESTROY = 'POLL_DESTROY',

  NEWS_LIST = 'NEWS_LIST',
  NEWS_CREATE = 'NEWS_CREATE',
  NEWS_UPDATE = 'NEWS_UPDATE',
  NEWS_DESTROY = 'NEWS_DESTROY',

  NEWS_REACTION_LIST = 'NEWS_REACTION_LIST',
  NEWS_REACTION_SET = 'NEWS_REACTION_SET',

  BUILDING_LIST = 'BUILDING_LIST',
  BUILDING_CREATE = 'BUILDING_CREATE',
  BUILDING_UPDATE = 'BUILDING_UPDATE',
  BUILDING_DESTROY = 'BUILDING_DESTROY',

  BUILDING_TIME_LIST = 'BUILDING_TIME_LIST',
  BUILDING_TIME_SET = 'BUILDING_TIME_SET',

  BUILDING_TIME_SHORTED_LIST = 'BUILDING_TIME_SHORTED_LIST',
  BUILDING_TIME_SHORTED_ADD = 'BUILDING_TIME_SHORTED_ADD',
  BUILDING_TIME_SHORTED_REMOVE = 'BUILDING_TIME_SHORTED_REMOVE',

  BUILDING_CLASSROOM_LIST = 'BUILDING_CLASSROOM_LIST',
  BUILDING_CLASSROOM_CREATE = 'BUILDING_CLASSROOM_CREATE',
  BUILDING_CLASSROOM_UPDATE = 'BUILDING_CLASSROOM_UPDATE',
  BUILDING_CLASSROOM_DESTROY = 'BUILDING_CLASSROOM_DESTROY',

  SCHEDULE_LIST = 'SCHEDULE_LIST',

  SCHEDULE_MANAGER_LIST = 'SCHEDULE_MANAGER_LIST',
  SCHEDULE_MANAGER_PLAN_LIST = 'SCHEDULE_MANAGER_PLAN_LIST',
  SCHEDULE_MANAGER_MODIFY = 'SCHEDULE_MANAGER_MODIFY',
  SCHEDULE_MANAGER_PUBLISH = 'SCHEDULE_MANAGER_PUBLISH',
  SCHEDULE_MANAGER_CLONE = 'SCHEDULE_MANAGER_CLONE',

  PLAN_TEACHER_LIST = 'PLAN_TEACHER_LIST',
  PLAN_TEACHER_CREATE = 'PLAN_TEACHER_CREATE',
  PLAN_TEACHER_DESTROY = 'PLAN_TEACHER_DESTROY',

  PLAN_SEMESTER_LIST = 'PLAN_SEMESTER_LIST',
  PLAN_SEMESTER_CREATE = 'PLAN_SEMESTER_CREATE',
  PLAN_SEMESTER_UPDATE = 'PLAN_SEMESTER_UPDATE',
  PLAN_SEMESTER_DESTROY = 'PLAN_SEMESTER_DESTROY',

  PLAN_LIST = 'PLAN_LIST',
  PLAN_META_LIST = 'PLAN_META_LIST',
  PLAN_CREATE = 'PLAN_CREATE',
  PLAN_DESTROY = 'PLAN_DESTROY',
  PLAN_CLONE = 'PLAN_CLONE',

  PLAN_HOUR_LIST = 'PLAN_HOUR_LIST',
  PLAN_HOUR_UPDATE = 'PLAN_HOUR_UPDATE',

  JOURNAL_LIST_ALLOWED = 'JOURNAL_LIST_ALLOWED',
  JOURNAL_LIST = 'JOURNAL_LIST',
  JOURNAL_MARK_SET = 'JOURNAL_MARK_SET',
  JOURNAL_HOMEWORK_SET = 'JOURNAL_HOMEWORK_SET',

  ACTIVITY_NOTIFY = 'ACTIVITY_NOTIFY',

  SUPPORT_CHAT_REDIRECT = 'SUPPORT_CHAT_REDIRECT',
  SUPPORT_CHAT_CLOSE = 'SUPPORT_CHAT_CLOSE',

  SUPPORT_AGENT_LIST = 'SUPPORT_AGENT_LIST',
  SUPPORT_AGENT_CREATE = 'SUPPORT_AGENT_CREATE',
  SUPPORT_AGENT_DESTROY = 'SUPPORT_AGENT_DESTROY',

  SUPPORT_MACROS_LIST = 'SUPPORT_MACROS_LIST',
  SUPPORT_MACROS_CREATE = 'SUPPORT_MACROS_CREATE',
  SUPPORT_MACROS_UPDATE = 'SUPPORT_MACROS_UPDATE',
  SUPPORT_MACROS_DESTROY = 'SUPPORT_MACROS_DESTROY',

  SUPPORT_TIME_LIST = 'SUPPORT_TIME_LIST',
  SUPPORT_TIME_SET = 'SUPPORT_TIME_SET',

  ACADEMIC_STATEMENTS_LIST = 'ACADEMIC_STATEMENTS_LIST',
  ACADEMIC_STATEMENTS_SET = 'ACADEMIC_STATEMENTS_SET',

  JOURNAL_MODULE_PREVIEW = 'JOURNAL_MODULE_PREVIEW',

  JOURNAL_ADMIN_READ = 'JOURNAL_ADMIN_READ',

  JOURNAL_ITEMS_QUERY_EXPLICIT = 'JOURNAL_ITEMS_QUERY_EXPLICIT',
  JOURNAL_ITEMS_MANAGE_EXPLICIT = 'JOURNAL_ITEMS_MANAGE_EXPLICIT',
  JOURNAL_PLAN_QUERY_EXPLICIT = 'JOURNAL_PLAN_QUERY_EXPLICIT',
  JOURNAL_PLAN_MANAGE_EXPLICIT = 'JOURNAL_PLAN_MANAGE_EXPLICIT',
}
