import { PropsWithChildren } from 'react';

export type PlanEditorMetaItemProps = {
  title: string;
};

export const PlanEditorMetaItem = ({
  title,
  children,
}: PropsWithChildren<PlanEditorMetaItemProps>) => {
  return (
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'font-semibold'}>{title}</span>
      <span className={'font-jb text-right'}>{children}</span>
    </div>
  );
};
