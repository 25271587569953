import { ScheduleProvider } from './schedule.context';
import { ScheduleRoot } from './components/schedule-root.component';

export const ScheduleModule = () => {
  return (
    <ScheduleProvider>
      <ScheduleRoot />
    </ScheduleProvider>
  );
};
