import { Button } from '../../../common/component/button/button.component';
import { Modal } from '../../../common/component/modal/modal';

import { useNews } from '../../news.context';

export const NewsAdminDeleteModal = () => {
  const { modal, setModal, drop } = useNews();

  return (
    <Modal
      title={'Удаление публикации'}
      showed={modal.type === 'delete'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <h1>Вы действительно хотите удалить ????</h1>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={drop}>
            Да, удалить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
