import { Dropdown } from '../../../../common/component/dropdown/dropdown';
import { ScheduleContext } from '../../../schedule.context';
import { Button } from '../../../../common/component/button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { useContextSelector } from 'use-context-selector';
import { DropdownItem } from '../../../../common/component/dropdown/dropdown-item';

export const ScheduleItemModalRightTab = () => {
  const isMetaLoading = useContextSelector(
    ScheduleContext,
    context => context.isMetaLoading,
  );

  const changeSubgroupSize = useContextSelector(
    ScheduleContext,
    context => context.changeSubgroupSize,
  );

  const metaPositions = useContextSelector(
    ScheduleContext,
    context => context.metaPositions,
  );

  const swap = useContextSelector(ScheduleContext, context => context.swap);

  if (isMetaLoading) {
    return null;
  }

  return (
    <div className={'flex flex-row gap-2'}>
      {metaPositions() === 2 && (
        <Button small={true} square={true} onClick={swap}>
          <FontAwesomeIcon icon={faRightLeft} />
        </Button>
      )}
      <Dropdown header="Подгруппы">
        <DropdownItem
          title="Совмещенная"
          color="default"
          onClick={() => changeSubgroupSize(1)}
        />
        <DropdownItem
          title="2 подгруппы"
          color="default"
          onClick={() => changeSubgroupSize(2)}
        />
      </Dropdown>
    </div>
  );
};
