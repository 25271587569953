import { Profile } from './profile.component';
import { Logo } from './logo.component';
import { Navigation } from './navigation.component';

export type HeaderProps = {
  isWide: boolean;
  isPadding: boolean;
};

export const Header = ({ isWide, isPadding }: HeaderProps) => (
  <div
    className={`fixed top-0 left-0 right-0 z-50 w-full bg-white dark:bg-[#222222] ${
      isPadding && 'shadow-md'
    }`}
  >
    <div
      className={`${
        !isWide ? 'container mx-auto' : 'px-4'
      } py-2 items-center flex gap-4`}
    >
      <Logo />
      <Navigation />
      <Profile />
    </div>
  </div>
);
