export const PlanTableHeaderTitle = () => (
  <div className={'flex w-2/4'}>
    <div
      className={
        'flex items-center justify-center p-2 border-r-2 border-[#F0F2F5] dark:border-[#333333] w-11/12'
      }
    >
      Дисциплина
    </div>
    <div
      className={
        'flex items-center justify-center p-2 border-r-2 border-[#F0F2F5] dark:border-[#333333] w-1/12'
      }
    >
      Всего
    </div>
  </div>
);
