import { JournalItemsProvider } from './journal-items.context';
import { JournalContent } from './components/journal-content.component';

export const JournalItemsModule = () => {
  return (
    <JournalItemsProvider>
      <div className={'flex flex-col gap-4'}>
        <JournalContent />
      </div>
    </JournalItemsProvider>
  );
};
