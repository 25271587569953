import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './auth.context';

export type AuthRequiredProps = {
  /**
   * Данный параметр указывает на то, нужно ли нам выполнить обратную проверку. Если значение true, то пользователь
   * не должен быть авторизован и его перенаправит на внутреннюю страницу, в противном случае, его перенаправит на
   * страницу авторизации.
   */
  inverse?: boolean | undefined;
};

export const AuthRequiredComponent = ({ inverse }: AuthRequiredProps) => {
  const { result } = useAuth();
  const location = useLocation();

  return (inverse ? result === undefined : result !== undefined) ? (
    <Outlet />
  ) : (
    <Navigate to={inverse ? '/home' : '/'} state={{ from: location }} replace />
  );
};
