import { Autocomplete } from '../../../../common/component/autocomplete/autocomplete.component';
import { ScheduleContext } from '../../../schedule.context';
import { useMemo, useState } from 'react';
import { Badge } from '../../../../common/component/badge/badge';
import { useContextSelector } from 'use-context-selector';

export type ScheduleItemSubjectProps = {
  position: number;
};

export const ScheduleItemSubject = ({ position }: ScheduleItemSubjectProps) => {
  const plansFor = useContextSelector(
    ScheduleContext,
    context => context.plansFor,
  );

  const metaFor = useContextSelector(
    ScheduleContext,
    context => context.metaFor,
  );

  const modify = useContextSelector(ScheduleContext, context => context.modify);

  const isSemester = useContextSelector(
    ScheduleContext,
    context => context.isSemester,
  );

  const [search, setSearch] = useState<string>('');

  const filtered = useMemo(
    () =>
      plansFor()
        .filter(plan =>
          plan.subject.title.toLowerCase().includes(search.toLowerCase()),
        )
        .sort((first, second) =>
          first.subject.title.localeCompare(second.subject.title),
        ),
    [search, plansFor],
  );

  return (
    <Autocomplete
      title={'Дисциплина'}
      items={filtered.map(plan => {
        const teacher = plan.teachers[position] ?? plan.teachers[0];

        return {
          value: plan.id,
          title: plan.subject.title,
          view: (
            <div
              className={
                'w-full flex flex-row justify-between place-items-center'
              }
            >
              <div>{plan.subject.short}</div>
              <div className={'flex flex-row gap-2'}>
                {teacher && teacher.classroom && (
                  <Badge color={'purple'}>{teacher.classroom.title}</Badge>
                )}
                {teacher && (
                  <Badge color={'green'}>{teacher.teacher.pretty}</Badge>
                )}
                {!isSemester &&
                  plan.stats.total !== null &&
                  plan.stats.total !== undefined && (
                    <Badge
                      color={
                        plan.stats.current >= plan.stats.total
                          ? 'red'
                          : 'yellow'
                      }
                    >
                      Часы: {plan.stats.current}/{plan.stats.total}
                    </Badge>
                  )}
              </div>
            </div>
          ),
        };
      })}
      emptyTitle={
        'Дисциплины не найдены, будет использовано указанное вами название.'
      }
      selected={metaFor(position).planId}
      onSelected={value =>
        modify(position, {
          property: 'planId',
          value: value as number,
        })
      }
      onSearch={setSearch}
    />
  );
};
