import { useJournalItems } from '../../journal-items.context';
import { firstLetterUppercase } from '../../../common/utils/string.utils';
import { DateTime } from 'luxon';
import { JournalHeaderItem } from './journal-header-item.component';

export const JournalHeader = () => {
  const { meta, totals } = useJournalItems();

  return (
    <div
      className={'sticky top-0 flex flex-row z-30 bg-white dark:bg-[#222222]'}
    >
      <div
        className={
          'sticky left-0 z-40 w-96 h-38 bg-white dark:bg-[#222222] shrink-0 flex flex-col font-bold border-r border-b dark:border-[#333333] justify-center items-center'
        }
      >
        ФИО
      </div>

      {meta &&
        meta.semesters
          .sort((first, second) => first.start - second.start)
          .map((semester, index) => (
            <div key={semester.id} className={'flex flex-col'}>
              <div
                className={
                  'h-10 flex flex-col font-bold border-r border-b dark:border-[#333333] justify-center items-center'
                }
              >
                {`Семестр #${index + 1}`}
              </div>

              <div className={'flex flex-row'}>
                {semester.months
                  .sort((first, second) => first.month - second.month)
                  .map(month => (
                    <div key={month.month} className={'flex flex-col'}>
                      <div
                        className={
                          'h-10 flex flex-col font-bold border-r border-b dark:border-[#333333] justify-center items-center'
                        }
                      >
                        {`${firstLetterUppercase(
                          DateTime.now()
                            .set({ month: month.month })
                            .toFormat('LLLL'),
                        )}`}
                      </div>

                      <div className={'flex flex-row'}>
                        {!totals &&
                          month.schedule.map(item => (
                            <JournalHeaderItem
                              key={item.id}
                              item={item}
                              type={'ITEM'}
                            />
                          ))}

                        <JournalHeaderItem type={'TOTAL'} />
                        <JournalHeaderItem type={'FIXED'} />
                      </div>
                    </div>
                  ))}

                <JournalHeaderItem type={'TOTAL'} isSemester={true} />
                <JournalHeaderItem type={'FIXED'} isSemester={true} />
              </div>
            </div>
          ))}

      <JournalHeaderItem type={'TOTAL'} isSemester={true} />
      <JournalHeaderItem type={'FIXED'} isSemester={true} />
    </div>
  );
};
