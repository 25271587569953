import { Autocomplete } from '../../../../common/component/autocomplete/autocomplete.component';
import { ScheduleContext } from '../../../schedule.context';
import { useMemo, useState } from 'react';
import { Badge } from '../../../../common/component/badge/badge';
import { useContextSelector } from 'use-context-selector';

export type ScheduleItemModalClassroomProps = {
  position: number;
};

export const ScheduleItemClassroom = ({
  position,
}: ScheduleItemModalClassroomProps) => {
  const metaFor = useContextSelector(
    ScheduleContext,
    context => context.metaFor,
  );

  const classroomsFor = useContextSelector(
    ScheduleContext,
    context => context.classroomsFor,
  );

  const modify = useContextSelector(ScheduleContext, context => context.modify);

  const isDuplicatedByModal = useContextSelector(
    ScheduleContext,
    context => context.isDuplicatedByModal,
  );

  const modal = useContextSelector(ScheduleContext, context => context.modal);

  const headerFor = useContextSelector(
    ScheduleContext,
    context => context.headerFor,
  );

  const [search, setSearch] = useState<string>('');

  const filtered = useMemo(() => {
    if (modal.type !== 'lesson') {
      return [];
    }

    const buildingId = headerFor(modal.groupId)?.buildingId;

    return classroomsFor()
      .filter(
        classroom =>
          classroom.title.toLowerCase().includes(search.toLowerCase()) &&
          (!buildingId || classroom.buildingId === buildingId),
      )
      .sort((first, second) => first.title.localeCompare(second.title));
  }, [search, classroomsFor, modal, headerFor]);

  return (
    <Autocomplete
      title={'Аудитория'}
      items={filtered.map(classroom => ({
        value: classroom.id,
        title: classroom.title,
        view: (
          <div
            className={
              'w-full flex flex-row justify-between place-items-center'
            }
          >
            <div>{classroom.title}</div>
            <div className={'flex flex-row gap-2'}>
              {isDuplicatedByModal('classroom', classroom.id) && (
                <Badge color={'red'}>ЗАДЕЙСТВОВАН</Badge>
              )}
            </div>
          </div>
        ),
      }))}
      selected={metaFor(position).classroomId}
      onSelected={value =>
        modify(position, {
          property: 'classroomId',
          value: value as number,
        })
      }
      onSearch={setSearch}
    />
  );
};
