import { ReactNode, useState } from 'react';
import { ModalContent, ModalContentProps } from './modal-content';

export type ModalProps = ModalContentProps & {
  closePrompt?: ReactNode | null | undefined;
};

export const Modal = ({ closePrompt, ...otherProps }: ModalProps) => {
  const [_, setCloseDialogIsOpen] = useState(false);

  return (
    <>
      <ModalContent
        {...otherProps}
        onClose={
          closePrompt ? () => setCloseDialogIsOpen(true) : otherProps.onClose
        }
      />
    </>
  );
};
