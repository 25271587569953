import { Modal } from '../../../common/component/modal/modal';
import { useSubject } from '../../subject.context';
import { AutocompleteService } from '../../../common/component/autocomplete-service/autocomplete-service.component';
import { UserEntity } from '../../../user/user.entity';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Profile } from '../../../common/component/profile/profile.component';

export const SubjectClassificationModal = () => {
  const { subject, setSubject, teachers, addTeacher, removeTeacher } =
    useSubject();

  return (
    <Modal
      title={'Классификация'}
      showed={subject !== undefined}
      onClose={() => setSubject(undefined)}
    >
      <div className={'flex flex-col gap-4'}>
        <AutocompleteService<UserEntity>
          title={'Преподаватель'}
          route={'users'}
          withoutSetter={true}
          disabled={teachers.map(item => item.id)}
          onSelected={selected => addTeacher(Number(selected))}
          mapper={user => ({
            value: user.id,
            view: <Profile user={user} size={6} />,
            title: user.pretty,
          })}
        />

        <div className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
          Список преподавателей
        </div>

        <div
          className={
            'flex flex-col gap-2 divide-y divide-y divide-[#F0F2F5] dark:divide-[#333333]'
          }
        >
          {teachers.length > 0 &&
            teachers.map((teacher, index) => (
              <div key={index} className={'flex flex-row pt-1 justify-between'}>
                <Profile
                  prefix={<span>{index + 1}. </span>}
                  user={teacher}
                  size={8}
                />
                <div>
                  <button onClick={() => removeTeacher(teacher.id)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
            ))}

          {teachers.length === 0 && <div>Преподаватели отсутствуют</div>}
        </div>
      </div>
    </Modal>
  );
};
