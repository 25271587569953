import {
  Autocomplete,
  AutocompleteItemType,
  AutocompleteItemValueType,
} from '../autocomplete/autocomplete.component';
import { useEffect, useState } from 'react';
import { findById, findByQuery } from './autocomplete-service.service';
import { useList } from 'react-use';
import { EntityType } from '../../../entity/entity.type';

export type AutocompleteServiceProps<ItemType> = {
  title: string;
  route: string;
  withoutSetter?: boolean;
  selectedOnce?: boolean;
  disabled?: AutocompleteItemValueType[];
  background?: boolean;
  emptyTitle?: string;
  params?: Record<string, string>;

  onSelected: (value: AutocompleteItemValueType) => void;
  selected?: AutocompleteItemValueType;
  mapper: (item: ItemType) => AutocompleteItemType;
};

export const AutocompleteService = <ItemType,>({
  title,
  route,
  background,

  emptyTitle,
  withoutSetter,
  disabled,
  onSelected,
  selected,
  selectedOnce,
  mapper,
  params,
}: AutocompleteServiceProps<ItemType>) => {
  const [query, setQuery] = useState<string>('');
  const [items, { push, clear }] = useList<ItemType>([]);

  useEffect(() => {
    findByQuery<EntityType<ItemType>>(route, query, params)
      .then(response => {
        clear();
        push(...response.data);

        return response.data;
      })
      .then(items => {
        if (selected && !items.some(item => item.id === selected)) {
          findById<ItemType>(route, selected).then(response =>
            push(response.data),
          );
        }
      });
  }, [query, selected]);

  return (
    <Autocomplete
      title={title}
      items={items.map(mapper)}
      selected={selected}
      selectedOnce={selectedOnce}
      emptyTitle={emptyTitle}
      disabled={disabled}
      onSelected={onSelected}
      onSearch={setQuery}
      background={background}
      withoutSetter={withoutSetter}
    />
  );
};
