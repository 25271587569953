import { PlanItemEntity } from '../../plan-item.entity';
import { PlanTableContentCategoryHeader } from './header/plan-table-content-category-header.component';
import { PlanTableContentCategorySubject } from './subject/plan-table-content-category-subject.component';

export type PlanTableContentCategoryProps = {
  plan?: PlanItemEntity;
};

export const PlanTableContentCategory = ({
  plan,
}: PlanTableContentCategoryProps) => {
  return (
    <div className={`flex flex-col ${!plan && 'mb-4'}`}>
      <PlanTableContentCategoryHeader plan={plan} />

      {plan && (
        <div className={'flex flex-col'}>
          {plan.children.map(child => (
            <PlanTableContentCategorySubject
              key={child.id}
              root={plan}
              plan={child}
            />
          ))}

          <PlanTableContentCategorySubject key={'child.new'} root={plan} />
        </div>
      )}
    </div>
  );
};
