import { ReactNode } from 'react';
import { UserEntity } from '../../../user/user.entity';

import { Verification } from '../verification/verification.component';

export type ProfileProps = {
  prefix?: ReactNode;
  user?: UserEntity;
  suffix?: ReactNode;
  title?: boolean;
  titled?: boolean;
  simple?: boolean;

  size?: number;
};

export const Profile = ({
  prefix,
  user,
  suffix,
  size,
  title,
  titled,
  simple,
}: ProfileProps) => {
  if (!user) {
    return <div>Нет</div>;
  }

  const filteredSize = size ?? 10;

  return (
    <div className={'flex flex-row gap-2 items-center'}>
      {prefix && prefix}
      {user.avatar ? (
        <img
          className={`w-${filteredSize} h-${filteredSize} aspect-square rounded-full`}
          src={user.avatar}
        />
      ) : (
        <div
          className={`h-${filteredSize} w-${filteredSize} aspect-square bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 rounded-full flex justify-center items-center`}
        >
          <div
            className={` ${
              filteredSize <= 8 ? 'text-sm' : 'text-md'
            } text-white`}
          >
            {user.letters ??
              `${user.secondName.substring(0, 1)}${user.thirdName.substring(
                0,
                1,
              )}`}
          </div>
        </div>
      )}
      {(title ?? true) && (
        <div className={'flex flex-col font-medium'}>
          <div className={'flex flex-row gap-2 items-center whitespace-nowrap'}>
            {(simple ?? false ? user.simple : user.pretty) ||
              [user.secondName, user.firstName, user.thirdName].join(' ')}{' '}
            {user.isAdmin && <Verification />}
          </div>
          {(titled ?? false) && (
            <span className={'text-gray-500 dark:text-gray-400'}>
              {user.title}
            </span>
          )}
        </div>
      )}
      {suffix && suffix}
    </div>
  );
};
