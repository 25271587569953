import { useEffect } from 'react';

export const PolicyModule = () => {
  useEffect(() => {
    window.location.replace(
      'https://raw.githubusercontent.com/it-schedule/policy/master/policy.md',
    );
  });

  return <div>Policy</div>;
};
