import { PlanEditorContentEmpty } from './plan-editor-content-empty.component';
import { PlanEditorContentCategory } from './plan-editor-content-category.component';
import { Card } from '../../../common/block/card.component';
import { usePlanEditor } from '../../plan-editor.context';

export const PlanEditorContent = () => {
  const { plan, readonly } = usePlanEditor();

  return (
    <div className={'flex flex-col gap-4'}>
      {plan.categories.map((value, index) => (
        <Card key={index} className={'flex flex-col gap-4 w-9/12'}>
          <PlanEditorContentCategory category={value} />
        </Card>
      ))}

      {!readonly && <PlanEditorContentEmpty />}
    </div>
  );
};
