import { ScheduleEntity } from '../../../schedule/schedule.entity';
import { Card } from '../../../common/block/card.component';
import { ScheduleItem } from './schedule-item.component';

export type ScheduleSectionProps = {
  title?: string;
  journal: boolean;

  items: ScheduleEntity[];
};

export const ScheduleSection = ({
  title,
  journal,
  items,
}: ScheduleSectionProps) => {
  if (items.length === 0) {
    return <div />;
  }

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        {title && (
          <div className={'flex flex-row items-end justify-end'}>
            <span className={'text-right text-lg font-semibold'}>{title}</span>
          </div>
        )}

        {items.map(item => (
          <Card key={item.id} className={'p-4 flex flex-col'}>
            <ScheduleItem schedule={item} journalVisible={journal} />
          </Card>
        ))}
      </div>
    </>
  );
};
