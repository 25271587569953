import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

type ButtonColorVariant =
  | 'none'
  | 'default'
  | 'blue'
  | 'red'
  | 'yellow'
  | 'green';

const colorValues = {
  none: '',
  default: 'bg-[#222222] text-white dark:bg-white dark:text-[#222222]',
  blue: 'bg-[#3375f6] text-white',
  red: 'bg-[#FD3246] text-white',
  yellow: 'bg-[#E9A440] text-white',
  green: 'bg-[#68B158] text-white',
};

export type ButtonProps = {
  color?: ButtonColorVariant;
  square?: boolean;
  small?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      {...props}
      className={`rounded-lg hover:opacity-90 whitespace-nowrap disabled:opacity-70 ${
        props.square
          ? `aspect-square ${props.small ? 'px-2 py-1' : 'px-3 py-2'}`
          : `${props.small ?? false ? 'text-sm px-4 py-2' : 'w-full px-6 py-2'}`
      } ${colorValues[props.color ?? 'default']} ${props.className}`}
    >
      {props.children}
    </button>
  );
};
