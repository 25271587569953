import Lottie from 'lottie-react';
import duckSuccess from '../assets/success.json';

export const FileContainerStatusSuccess = () => (
  <div className={'flex flex-col gap-2 text-center'}>
    <Lottie animationData={duckSuccess} className={'h-40'} />

    <div className={'text-[#68B158] font-medium flex flex-col'}>
      <div>Отлично, нам это нравится!</div>
      <div>Загружаем ваши файлы...</div>
    </div>
  </div>
);
