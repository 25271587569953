import { ScheduleContext } from '../../schedule.context';
import { useContextSelector } from 'use-context-selector';

export const SidebarDay = () => {
  const week = useContextSelector(ScheduleContext, context => context.week);

  const weekTitle = useContextSelector(
    ScheduleContext,
    context => context.weekTitle,
  );

  const isSemester = useContextSelector(
    ScheduleContext,
    context => context.isSemester,
  );

  const lessonsCount = useContextSelector(
    ScheduleContext,
    context => context.lessonsCount,
  );

  const lessonSize = useContextSelector(
    ScheduleContext,
    context => context.lessonSize,
  );

  return (
    <div
      className={
        'flex flex-col z-20 sticky left-0 dark:bg-[#333333] bg-[#E5E6E7]'
      }
    >
      <div
        className={
          'px-1 py-3.5 text-center border-r border-b border-[#F0F2F5] dark:border-[#333333] sticky top-0 w-20 z-10 dark:bg-[#333333] bg-[#E5E6E7]'
        }
      >
        {weekTitle}
      </div>

      {Array.from(Array(lessonsCount).keys())
        .map(day => week.plus({ day }))
        .map((day, index) => (
          <div
            key={index}
            className={
              'shrink-0 font-semibold text-2xl flex justify-center items-center rotate-180 text-center border-t border-l border-[#F0F2F5] dark:border-[#333333] whitespace-nowrap'
            }
            style={{
              writingMode: 'vertical-rl',
              height: `${lessonSize * lessonsCount}rem`,
            }}
          >
            {day.toFormat(isSemester ? 'EEEE' : 'DDDD').toUpperCase()}
          </div>
        ))}
    </div>
  );
};
