import { Modal } from '../../../common/component/modal/modal';
import { useTask } from '../../task.context';
import { FileUploader } from '../../../common/component/file/file-uploader';
import { FileCollectionType } from '../../../common/component/file/file-collection.type';

export const TaskDocumentConfigurationModal = () => {
  const { modal, setModal } = useTask();

  return (
    <Modal
      title={'Базовая конфигурация'}
      showed={modal.type === 'configuration'}
      onClose={() => setModal({ type: 'none' })}
    >
      <FileUploader
        maxFiles={1}
        maxSize={1}
        formats={[]}
        collection={FileCollectionType.COVER}
        onChange={console.log}
      />
    </Modal>
  );
};
