import { usePlanEditor } from '../../plan-editor.context';
import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';
import { TextArea } from '../../../common/component/textarea/textarea.component';
import { useState } from 'react';

export const PlanEditorDeclineModalComponent = () => {
  const { modal, setModal, decline } = usePlanEditor();
  const [note, setNote] = useState<string>('');

  return (
    <Modal
      title={'Отклонить календарно-тематический план'}
      showed={modal.type === 'decline'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <TextArea
          type={'text'}
          placeholder={'Причина отказа..'}
          onChange={event => setNote(event.target.value)}
        />

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={() => decline(note)}>
            Да, отклонить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
