import { DateTime } from 'luxon';

import { DocumentEntityType, TaskStatus } from '../document.type';
import { Badge2 } from '../../common/component/badge2/badge';

export type EntityTableTaskStatusProps = {
  entity: DocumentEntityType;
};

export const EntityTableTaskStatus = ({
  entity,
}: EntityTableTaskStatusProps) => {
  if (!entity || !entity.lastJob) {
    return <Badge2 color={'red'}>Задача не выполнялась</Badge2>;
  }

  if (entity.lastJob.status === TaskStatus.RUNNING) {
    return <Badge2 color={'yellow'}>В процессе</Badge2>;
  }

  if (entity.lastJob.status === TaskStatus.FAILED) {
    return <Badge2 color={'red'}>Ошибка при выполнении задачи</Badge2>;
  }

  return (
    <div className={'flex flex-row items-center'}>
      <Badge2 color={'green'}>Задача успешно выполнена</Badge2>
      <span className={'text-neutral-300 text-sm'}>
        {DateTime.fromSeconds(entity.lastJob.createdAt ?? 0).toFormat(
          'DDD, tt',
        )}
      </span>
    </div>
  );
};
