export const gradients = [
  {
    backgroundFrom: 'FC4778',
    backgroundVia: '994DB8',
    backgroundTo: '3952F5',
  },
  {
    backgroundFrom: 'F8997D',
    backgroundVia: 'D36675',
    backgroundTo: 'AD336D',
  },
  {
    backgroundFrom: 'F44C7D',
    backgroundVia: 'FCC44B',
    backgroundTo: 'FCEF64',
  },
  {
    backgroundFrom: 'F90C71',
    backgroundVia: '900644',
    backgroundTo: '30001A',
  },
];
