import { NavLink } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import { useAuth } from '../../auth/auth.context';

export const Logo = () => {
  const { result } = useAuth();

  return (
    <NavLink to={'/home'}>
      <div className={'flex items-center gap-2 whitespace-nowrap'}>
        <img
          src={result?.institution.cover ?? logo}
          className={'h-8 w-8 rounded-lg'}
        />
        <span className={'font-semibold text-lg'}>
          Платформа » {result?.institution.short}
        </span>
      </div>
    </NavLink>
  );
};
