import { EntityModule } from '../entity';
import { InstitutionEntity } from './institution.entity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceMeh } from '@fortawesome/free-solid-svg-icons';
import { FileCollectionType } from '../common/component/file/file-collection.type';
import { Profile } from '../common/component/profile/profile.component';

export const InstitutionModule = () => (
  <EntityModule<InstitutionEntity>
    title={'Образовательные учреждения'}
    route={'institution'}
    columns={[
      {
        key: 'cover',
        title: '',
        small: true,
        render: item =>
          item.cover ? (
            <img className={'w-12 h-12 rounded-xl'} src={item.cover} />
          ) : (
            <FontAwesomeIcon
              className={'w-12 h-12 text-gray-300'}
              icon={faFaceMeh}
            />
          ),
      },
      {
        key: 'title',
        title: 'Название',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 3,
              message: 'Минимальная длина 3 символа',
            },
          },
        },
        render: item => item.title,
      },
      {
        key: 'boss',
        title: 'Руководитель',
        render: item => <Profile user={item.boss} />,
      },
      {
        key: 'short',
        title: 'Сокращенное название',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 3,
              message: 'Минимальная длина 3 символа',
            },
          },
        },
        render: item => item.short,
      },
      {
        key: 'coverId',
        title: 'Аватар',
        input: {
          type: 'attachment',
          count: 1,
          formats: ['.jpeg', '.jpg', '.png'],
          collection: FileCollectionType.COVER,
          isSystem: true,
        },
      },
      // {
      //   key: 'boss.pretty',
      //   title: 'Руководитель',
      //   render: (item) => item.boss.pretty,
      // },
      {
        key: 'boss.secondName',
        title: 'Фамилия',
        input: {
          type: 'text',
          editableOn: ['create'],
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 3,
              message: 'Минимальная длина 3 символа',
            },
          },
        },
      },
      {
        key: 'boss.firstName',
        title: 'Имя',
        input: {
          type: 'text',
          editableOn: ['create'],
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 3,
              message: 'Минимальная длина 3 символа',
            },
          },
        },
      },
      {
        key: 'boss.thirdName',
        title: 'Отчество',
        input: {
          type: 'text',
          editableOn: ['create'],
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 3,
              message: 'Минимальная длина 3 символа',
            },
          },
        },
      },
      {
        key: 'boss.mail',
        title: 'Почта',
        input: {
          type: 'text',
          editableOn: ['create'],
          options: {
            required: 'Данное поле обязательно',
          },
        },
      },
      {
        key: 'boss.sex',
        title: 'Пол',
        input: {
          type: 'select',
          editableOn: ['create'],
          items: [
            {
              value: '0',
              title: 'Мужской',
            },
            {
              value: '1',
              title: 'Женский',
            },
          ],
          options: {
            required: 'Данное поле обязательно',
          },
        },
      },
      {
        key: 'boss.birthDate',
        title: 'Дата рождения',
        input: {
          type: 'calendar',
          editableOn: ['create'],
          options: {
            required: 'Данное поле обязательно',
          },
        },
      },
    ]}
  />
);
