import {
  EntityColumnType,
  EntityModuleGroupByProps,
  EntityType,
} from '../../entity.type';
import { retrieveValue } from '../../entity.utils';
import { useEntity } from '../../entity.context';

export type EntityTableHeaderProps<ItemType> = {
  columns: EntityColumnType<EntityType<ItemType>>[];
  groupBy?: EntityModuleGroupByProps<ItemType>;
};

export const EntityTableHeader = <ItemType,>({
  columns,
  groupBy,
}: EntityTableHeaderProps<ItemType>) => {
  const { isActionsDisabled } = useEntity();

  return (
    <thead>
      <tr>
        {groupBy && (
          <th
            className={
              'py-3.5 pl-4 pr-12 w-44 text-left font-bold rtl:text-right text-black dark:text-white'
            }
            colSpan={1}
          />
        )}

        {columns
          .filter(column => {
            const input = column.input
              ? retrieveValue(column.input)
              : undefined;

            return (
              column.render !== undefined ||
              column.renderCountThrough !== undefined ||
              (input?.type === 'select' && input?.render)
            );
          })
          .map(item => (
            <th
              key={item.key}
              scope="col"
              className={`py-3.5 pl-4 ${
                !!item.small && 'pr-12'
              } font-semibold text-left rtl:text-right text-black dark:text-white`}
            >
              {item.short ?? item.title}
            </th>
          ))}

        {!isActionsDisabled && (
          <th
            scope="col"
            className="py-3.5 pl-4 pr-12 font-semibold text-left rtl:text-right text-black dark:text-white"
          />
        )}
      </tr>
    </thead>
  );
};
