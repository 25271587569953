import { Popover, Transition } from '@headlessui/react';
import { useState } from 'react';
import { usePopper } from 'react-popper';

const marksColorValues: Record<string, string> = {
  '-1': 'bg-red-500 dark:bg-red-600',
  '0': 'bg-purple-500 dark:bg-purple-600',
  '2': 'bg-red-500 dark:bg-red-600',
  '3': 'bg-orange-500 dark:bg-orange-600',
  '4': 'bg-yellow-500 dark:bg-yellow-600',
  '5': 'bg-green-500 dark:bg-green-600',
};

const marksLocalizations: Record<string, string> = {
  '-1': 'Н/А',
  '0': 'Н/Б',
};

export type JournalTableMarkProps = {
  mark: number;
  isLesson?: boolean;
  onSelected: (value: number | undefined) => void;
  type: 'ITEM' | 'TOTAL' | 'FIXED';
  lastItems: boolean;
};

export const JournalTableMark = ({
  mark,
  isLesson,
  onSelected,
  type,
  lastItems,
}: JournalTableMarkProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: lastItems ? 'top' : 'bottom',
  });

  return (
    <Popover className={'relative overflow-visible'}>
      <>
        <Popover.Button ref={setReferenceElement}>
          <div
            className={`flex items-center justify-center h-10 w-10 border-r border-b dark:border-[#333333] hover:cursor-pointer ${
              marksColorValues[mark]
            } ${
              mark === -2 &&
              type !== 'ITEM' &&
              'bg-neutral-100 dark:bg-neutral-900'
            }`}
          >
            <span className={'text-white'}>
              {(mark !== -2 && marksLocalizations[mark]) ?? mark}
            </span>
          </div>
        </Popover.Button>

        <Popover.Overlay className="fixed inset-0 bg-black opacity-50 z-50"></Popover.Overlay>

        <Transition
          className="absolute left-1/2 transform -translate-x-1/2 p-4 bg-white dark:bg-[#222222] z-[100] rounded-lg overflow-visible"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <Popover.Panel className={'overflow-visible'}>
            {({ close }) => (
              <div className={'flex flex-col gap-4 overflow-visible'}>
                <div
                  className={'flex flex-row gap-4 whitespace-nowrap text-white'}
                >
                  <button
                    onClick={() => {
                      onSelected(5);
                      close();
                    }}
                    className={'rounded-lg p-1 bg-green-500 aspect-square h-10'}
                  >
                    5
                  </button>
                  <button
                    onClick={() => {
                      onSelected(4);
                      close();
                    }}
                    className={
                      'rounded-lg p-1 bg-yellow-500 aspect-square h-10'
                    }
                  >
                    4
                  </button>
                  <button
                    onClick={() => {
                      onSelected(3);
                      close();
                    }}
                    className={
                      'rounded-lg p-1 bg-orange-500 aspect-square h-10'
                    }
                  >
                    3
                  </button>
                  <button
                    onClick={() => {
                      onSelected(2);
                      close();
                    }}
                    className={'rounded-lg p-1 bg-red-500 aspect-square h-10'}
                  >
                    2
                  </button>
                  <button
                    onClick={() => {
                      onSelected(isLesson ? 0 : -1);
                      close();
                    }}
                    className={`rounded-lg p-1 ${
                      isLesson ? 'bg-purple-500' : 'bg-red-500'
                    } aspect-square h-10`}
                  >
                    {isLesson ? 'Н/Б' : 'Н/А'}
                  </button>
                </div>

                {isLesson && mark !== undefined && mark !== -2 && (
                  <button
                    className={'text-neutral-700 dark:text-neutral-400'}
                    onClick={() => {
                      onSelected(undefined);
                      close();
                    }}
                  >
                    Удалить оценку
                  </button>
                )}
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};
