import { Tab } from '@headlessui/react';

export type TabItemProps = {
  name: number;
  title: string;
};

export const TabItem = ({ name, title }: TabItemProps) => (
  <Tab
    key={name}
    className={({ selected }) =>
      `rounded-lg leading-5 py-1.5 min-w-36 w-full whitespace-nowrap
          ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none
          ${
            selected
              ? 'bg-white shadow text-black'
              : 'text-black dark:text-white hover:bg-white/[0.12]'
          }`
    }
  >
    {title}
  </Tab>
);
