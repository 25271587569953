import { EntityTable } from './component/entity-table.component';
import { TaskProvider } from './task.context';

export const TaskModule = () => {
  return (
    <TaskProvider>
      <EntityTable />
    </TaskProvider>
  );
};
