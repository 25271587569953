export enum DocumentType {
  REPORT_CARD = 'REPORT_CARD',
  FORM_NUMBER_TWO = 'FORM_NUMBER_TWO',
}

export enum TaskStatus {
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export type DocumentTaskType = {
  id: number;

  institutionId: number;

  type: DocumentType;

  configuration: string;

  jobId: number;

  url?: string;

  status: TaskStatus;

  createdAt: number;
};

export type DocumentEntityType = {
  id: number;
  title: string;
  type: DocumentType;
  lastJob?: DocumentTaskType | undefined;
  url?: string | undefined;
};
