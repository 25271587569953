import { QueryClientProvider } from '@tanstack/react-query';

import { ThemeProvider } from './theme/theme.context';
import { client } from './client/query.client';
import { AuthProvider } from './auth/auth.context';
import { Router } from './router/router.component';
import { NotificationProvider } from './notification/notification-context';

export const Application = () => (
  <ThemeProvider>
    <NotificationProvider>
      <QueryClientProvider client={client}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </QueryClientProvider>
    </NotificationProvider>
  </ThemeProvider>
);
