import { useSpecialtyGroupStatements } from '../../../specialty-group-statements.context';
import { StatementBodySubjectItemMark } from './statement-body-subject-item-mark.component';
import { PlanItemEntity } from '../../../../specialty-plan/components/table/plan-item.entity';

export type StatementBodySubjectsItemProps = {
  item: PlanItemEntity;
};

export const StatementBodySubjectsItem = ({
  item,
}: StatementBodySubjectsItemProps) => {
  const { relations, isEditing, update, statementAt } =
    useSpecialtyGroupStatements();

  return (
    <div className={'flex flex-col w-full'}>
      <div
        className={
          'w-16 h-48 shrink-0 py-2 font-semibold flex justify-center items-center rotate-180 text-center border-l border-[#F0F2F5] dark:border-[#333333]'
        }
        style={{
          writingMode: 'vertical-rl',
        }}
      >
        {item.subject.short}
      </div>

      {relations?.students?.map(student => (
        <div
          key={student.id}
          className={
            'w-16 h-10 border-t border-r border-[#F0F2F5] dark:border-[#333333] text-center'
          }
        >
          {isEditing ? (
            <input
              key={student.id}
              defaultValue={statementAt(item.subject.id, student.id)}
              className={
                'flex w-16 h-10 border-b border-r border-[#F0F2F5] dark:border-[#333333] text-center justify-center items-center'
              }
              placeholder={'0'}
              onChange={event => {
                const re = /^[0,2-5\b]+$/;

                if (re.test(event.target.value)) {
                  event.target.value = event.target.value.substring(
                    event.target.value.length - 1,
                  );

                  update(
                    item.subject.id,
                    student.id,
                    Number(event.target.value),
                  );
                } else {
                  event.target.value = '';
                }
              }}
            />
          ) : (
            <div
              className={
                'flex w-16 h-10 border-b border-r border-[#F0F2F5] dark:border-[#333333] text-center justify-center items-center'
              }
            >
              <StatementBodySubjectItemMark
                mark={statementAt(item.subject.id, student.id)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
