import { UserProvider } from './user.context';
import { UserTable } from './components/user-table.component';
import { UserRolesModal } from './components/user-roles-modal.component';

export const UserModule = () => (
  <UserProvider>
    <UserTable />
    <UserRolesModal />
  </UserProvider>
);
