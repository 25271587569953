import { Autocomplete } from '../../../../common/component/autocomplete/autocomplete.component';
import { ScheduleContext } from '../../../schedule.context';
import { useMemo, useState } from 'react';
import { Profile } from '../../../../common/component/profile/profile.component';
import { Badge } from '../../../../common/component/badge/badge';
import { useContextSelector } from 'use-context-selector';

export type ScheduleItemModalTeacherProps = {
  position: number;
};

export const ScheduleItemTeacher = ({
  position,
}: ScheduleItemModalTeacherProps) => {
  const plansFor = useContextSelector(
    ScheduleContext,
    context => context.plansFor,
  );

  const teacherFor = useContextSelector(
    ScheduleContext,
    context => context.teacherFor,
  );

  const metaFor = useContextSelector(
    ScheduleContext,
    context => context.metaFor,
  );

  const modify = useContextSelector(ScheduleContext, context => context.modify);

  const isDuplicatedByModal = useContextSelector(
    ScheduleContext,
    context => context.isDuplicatedByModal,
  );

  const [search, setSearch] = useState<string>('');

  const filtered = useMemo(
    () =>
      teacherFor(position).filter(teacher =>
        teacher.full.toLowerCase().includes(search.toLowerCase()),
      ),
    [position, search, plansFor],
  );

  return (
    <Autocomplete
      title={'Преподаватель'}
      items={filtered.map(teacher => ({
        value: teacher.id,
        title: teacher.pretty,
        view: (
          <div
            className={
              'w-full flex flex-row justify-between place-items-center'
            }
          >
            <Profile user={teacher} size={6} />

            <div className={'flex flex-row gap-2'}>
              {isDuplicatedByModal('teacher', teacher.id) && (
                <Badge color={'red'}>ЗАДЕЙСТВОВАН</Badge>
              )}
            </div>
          </div>
        ),
      }))}
      selected={metaFor(position).teacherId}
      onSelected={value =>
        modify(position, {
          property: 'teacherId',
          value: value as number,
        })
      }
      onSearch={setSearch}
    />
  );
};
