import {
  FileUploaderContextProps,
  FileUploaderProvider,
} from './file-uploader.context';
import { FileContainer } from './components/file-container.component';

export const FileUploader = (props: FileUploaderContextProps) => (
  <FileUploaderProvider {...props}>
    <FileContainer showSelected={props.showSelected} />
  </FileUploaderProvider>
);
