import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Modal } from '../../../common/component/modal/modal';
import { Button } from '../../../common/component/button/button.component';

import { EntityColumnType } from '../../entity.type';
import { useEntity } from '../../entity.context';
import { EntityField } from './field/entity-field.component';
import { retrieveValue } from '../../entity.utils';

export type EntityModifyModalProps<EntityType> = {
  columns: EntityColumnType<EntityType>[];
};

export const EntityModifyModal = <EntityType,>({
  columns,
}: EntityModifyModalProps<EntityType>) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { itemWithId, modify, modal, setModal } = useEntity<EntityType>();

  useEffect(() => {
    if (modal.type === 'none') {
      reset();
    }

    if (modal.type === 'update') {
      const item = itemWithId(modal.id);

      if (!item) {
        return;
      }

      columns
        .filter(column => column.input !== undefined)
        .forEach(column => {
          setValue(column.key, item[column.key]);
        });
    }
  }, [itemWithId, setValue, reset, modal]);

  const onSubmit = handleSubmit(values => {
    modify(values);
  });

  return (
    <Modal
      title={modal.type === 'create' ? 'Создание' : 'Редактирование'}
      showed={modal.type === 'create' || modal.type === 'update'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <form onSubmit={onSubmit} className={'flex flex-col gap-4 mt-4'}>
        {columns
          .filter(
            item =>
              (item.input !== undefined &&
                retrieveValue(item.input).editableOn?.includes(
                  modal.type.toString() as unknown as 'create' | 'update',
                )) ??
              true,
          )
          .map(item => (
            <EntityField
              key={item.key}
              column={item}
              register={register}
              control={control}
              error={errors[item.key]?.message?.toString()}
              predefined={
                modal.type === 'create' || modal.type === 'update'
                  ? modal.groupedValue
                  : undefined
              }
            />
          ))}

        <Button type={'submit'} color={'green'}>
          {modal.type === 'create' ? 'Создать' : 'Редактировать'}
        </Button>
      </form>
    </Modal>
  );
};
