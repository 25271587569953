import { PropsWithChildren } from 'react';

export type BadgeColorVariant2 = 'red' | 'green' | 'yellow' | 'purple';

const colorValues = {
  red: 'bg-red-300 text-red-800',
  green: 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300',
  yellow:
    'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300',
  purple:
    'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300',
};

const circleColorValues = {
  red: 'bg-red-600',
  green: 'bg-green-500',
  yellow: 'bg-yellow-500',
  purple: 'bg-purple-500',
};

export type BadgeProps2 = {
  color: BadgeColorVariant2;
  classname?: string;
};

export const Badge2 = ({
  children,
  color,
  classname,
}: PropsWithChildren<BadgeProps2>) => (
  <span
    className={`flex flex-row items-center text-xs font-medium px-2.5 py-0.5 rounded-lg ${colorValues[color]} ${classname}`}
  >
    <div
      className={`block w-2 h-2 mr-1 mt-0.5 ${circleColorValues[color]} rounded-full`}
    ></div>
    <div>{children}</div>
  </span>
);
