import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { faCheck, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type AutocompleteItemValueType = string | number | null | any;

export type AutocompleteItemType = {
  title?: string;
  view?: ReactNode;
  value: AutocompleteItemValueType;
};

export type AutocompleteProps = {
  title: string;
  background?: boolean;

  emptyTitle?: string;
  disabled?: AutocompleteItemValueType[];
  withoutSetter?: boolean;
  selectedOnce?: boolean;
  items: AutocompleteItemType[];
  selected: AutocompleteItemValueType | undefined;
  onSelected: (value: AutocompleteItemValueType) => void;
  onSearch: (value: string) => void;
};

export const Autocomplete = ({
  title,
  background,
  items,
  selected,
  selectedOnce,
  emptyTitle,
  disabled,
  onSelected,
  onSearch,
  withoutSetter,
}: AutocompleteProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useState<string>('');

  const itemById = useCallback(
    (value: AutocompleteItemValueType) =>
      items.find(item => item.value === value),
    [items],
  );

  const itemDisabled = useCallback(
    (value: AutocompleteItemValueType) => disabled?.includes(value) ?? false,
    [disabled],
  );

  useEffect(() => onSearch(query), [onSearch, query]);

  return (
    <div className="w-full h-full">
      <Combobox
        value={selected}
        onChange={onSelected}
        disabled={(selectedOnce ?? false) && selected !== undefined}
        nullable={false}
      >
        <div className="relative h-full">
          <div
            className={`relative w-full h-full ${
              !(selectedOnce ?? false) &&
              selected === undefined &&
              'cursor-pointer'
            } ${
              (background ?? true) &&
              'rounded-lg bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6]'
            }`}
          >
            <Combobox.Input
              as={Fragment}
              displayValue={(value: AutocompleteItemValueType) =>
                withoutSetter
                  ? (undefined as unknown as string)
                  : value
                  ? itemById(value)?.title ?? title
                  : title
              }
              onChange={event => setQuery(event.target.value)}
            >
              {({ open }) =>
                open ? (
                  <input
                    className={`text-left px-4 ${
                      !(background ?? true)
                        ? 'disabled:bg-transparent py-1'
                        : 'py-2'
                    } ${
                      background ?? true
                        ? 'w-full rounded-lg text-md bg-[#F0F2F5] dark:bg-[#333333]'
                        : 'w-full h-full bg-transparent'
                    }`}
                  />
                ) : (
                  <div
                    className={`text-left px-4 pr-10 ${
                      !selected && 'text-[#9199A5] dark:text-[#e1e3e6]'
                    } ${
                      !(background ?? true)
                        ? 'disabled:bg-transparent py-1'
                        : 'py-2'
                    } ${
                      background ?? true
                        ? 'w-full rounded-lg text-md bg-[#F0F2F5] dark:bg-[#333333]'
                        : 'w-full h-full bg-transparent'
                    }`}
                    onClick={() => buttonRef?.current?.click()}
                  >
                    {withoutSetter
                      ? title
                      : selected
                      ? itemById(selected)?.view ??
                        itemById(selected)?.title ??
                        title
                      : title}
                  </div>
                )
              }
            </Combobox.Input>
            <Combobox.Button
              ref={buttonRef}
              className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                (background || selectedOnce) && 'hidden'
              }`}
              hidden={!(background ?? true)}
            >
              <FontAwesomeIcon
                className="h-5 w-5 text-gray-400"
                icon={faUpDown}
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-[500] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-[#141414] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.length === 0 && query !== '' ? (
                <div className="relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-50 dark:hover:bg-[#222222]">
                  {emptyTitle ?? 'Ничего не найдено'}
                </div>
              ) : (
                items
                  .sort(
                    (first, second) =>
                      Number(itemDisabled(first.value)) -
                      Number(itemDisabled(second.value)),
                  )
                  .map(item => (
                    <Combobox.Option
                      key={item.value?.toString()}
                      className={`relative select-none ${
                        !itemDisabled(item.value) && 'cursor-pointer'
                      } py-2 pl-10 pr-4 hover:bg-gray-50 dark:hover:bg-[#222222]`}
                      value={item.value}
                      disabled={itemDisabled(item.value)}
                    >
                      {({ selected, disabled }) => (
                        <div
                          className={
                            'w-full flex flex-row justify-between place-items-center'
                          }
                        >
                          <div
                            className={`w-full block truncate text-black dark:text-white ${
                              selected && !withoutSetter
                                ? 'font-medium'
                                : 'font-normal'
                            }`}
                          >
                            {item.view ??
                              item.title ??
                              'Ошибка воспроизведения интерфейса'}
                          </div>
                          {disabled && (
                            <div
                              className={'text-red-500 whitespace-nowrap ml-4'}
                            >
                              ВЫБРАН
                            </div>
                          )}
                          {selected && !withoutSetter ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black dark:text-white">
                              <FontAwesomeIcon
                                className="h-5 w-5"
                                icon={faCheck}
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Combobox.Option>
                  ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
