import { useProfile } from '../profile.context';
import { ProfileUser } from './profile/profile-user.component';
import { Schedule } from './schedule/schedule.component';
import { CardLoader } from '../../common/component/loading/card-loader.component';
import { useAuth } from '../../app/auth/auth.context';
import { PermissionType } from '../../app/auth/auth-role-permission.type';
import { Availabilities } from './availabilities/availabilities.component';

export const ProfileContent = () => {
  const { result } = useAuth();
  const { isLoading } = useProfile();

  if (isLoading) {
    return <CardLoader />;
  }

  return (
    <div className={'flex flex-col gap-4 w-[400px]'}>
      <ProfileUser />

      <Schedule />
      {result?.permissions?.includes(PermissionType.JOURNAL_MODULE_PREVIEW) && (
        <Availabilities />
      )}
    </div>
  );
};
