import { useCallback } from 'react';
import { useList } from 'react-use';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare as faSquareUnchecked } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck as faSquareChecked } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../../common/component/button/button.component';

import { PollEntity } from '../../news.entity';
import { useNews } from '../../news.context';

export type NewsPollProps = {
  poll: PollEntity;
};

export const NewsPoll = ({ poll }: NewsPollProps) => {
  const { vote } = useNews();
  const [list, { push, filter, clear }] = useList<number>();

  const userVoted = poll.items.some(item => item.voted);
  const isEnded = poll.time && DateTime.now() > DateTime.fromSeconds(poll.time);

  const toggleItem = useCallback(
    (itemId: number) => {
      if (list.includes(itemId)) {
        filter(item => item !== itemId);
      } else {
        push(itemId);
      }

      if (!poll.multiple && list.length !== 0) {
        vote(poll.id, list);
      }
    },
    [list, push, filter, vote],
  );

  return (
    <div
      className={`flex flex-col p-4 rounded-lg my-2 gap-4`}
      style={{
        background: `linear-gradient(to right bottom, #${poll.backgroundFrom}, #${poll.backgroundVia}, #${poll.backgroundTo})`,
      }}
    >
      <div className={'font-bold text-white'}>{poll.title}</div>
      <div className={'flex flex-col gap-2'}>
        {poll.items.map(item => (
          <button
            disabled={userVoted && !poll.retake}
            onClick={() => {
              if (userVoted && poll.retake) {
                vote(poll.id, []);
              }

              if (!userVoted) {
                if (poll.multiple) {
                  toggleItem(item.id);
                } else {
                  vote(poll.id, [item.id]);
                  clear();
                }
              }
            }}
            key={item.id}
            className="relative w-full h-10 rounded-xl bg-gray-700/50 items-center flex flex-row justify-between pr-2"
          >
            <div
              className={`${
                userVoted && item.percentage !== 0
                  ? `${item.voted ? 'bg-gray-900/50' : 'bg-gray-800/20'}`
                  : ''
              } absolute h-10 dark:text-white rounded-xl p-2`}
              style={{ width: `${item.percentage}%` }}
            >
              <div className={'flex flex-row gap-2'}>
                {!userVoted && poll.multiple && (
                  <div className={'font-bold text-white'}>
                    {list.includes(item.id) ? (
                      <FontAwesomeIcon icon={faSquareChecked} />
                    ) : (
                      <FontAwesomeIcon icon={faSquareUnchecked} />
                    )}
                  </div>
                )}
                <div
                  className={`${
                    item.voted && 'font-bold'
                  } text-white whitespace-nowrap`}
                >
                  {item.title}
                </div>
              </div>
            </div>
            {userVoted && (
              <div
                className={
                  'absolute h-10 w-full flex flex-row place-items-center justify-end pr-2'
                }
              >
                <div className={`${item.voted && 'font-bold'} text-white`}>
                  {item.percentage}%
                </div>
              </div>
            )}
          </button>
        ))}
      </div>
      {isEnded && (
        <div className={'font-semibold text-center text-white'}>
          Опрос завершен
        </div>
      )}

      <div className={'flex flex-row gap-2 justify-center'}>
        {!userVoted && poll.time && (
          <div className={'font-semibold text-center text-white'}>
            Опрос закроется{' '}
            {DateTime.fromSeconds(poll.time).toFormat('DD HH:mm')}
          </div>
        )}

        {userVoted && (
          <div className={'font-semibold text-center text-white'}>
            Проголосовал {poll.total} человек
          </div>
        )}
      </div>

      {!userVoted && poll.multiple && (
        <div>
          <Button
            disabled={list.length === 0}
            onClick={() => {
              vote(poll.id, list);
              clear();
            }}
          >
            Проголосовать
          </Button>
        </div>
      )}
    </div>
  );
};
