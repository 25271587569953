import { useAuth } from '../../../app/auth/auth.context';
import { Card } from '../../../common/block/card.component';
import { Profile } from '../../../common/component/profile/profile.component';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../common/component/button/button.component';

export const ProfileUserDeveloper = () => {
  const { result } = useAuth();
  const navigate = useNavigate();

  return (
    <Card className={'p-4 flex flex-col gap-4'}>
      <Profile
        user={result?.user && { ...result?.user, title: 'Разработчик' }}
        titled={true}
      />

      <div className={'flex flex-row gap-4'}>
        <div className={'w-1/2'}>
          <Button onClick={() => navigate(`/tracker/3`)}>Трекер задач</Button>
        </div>
        <div className={'w-1/2'}>
          <Button color={'red'} onClick={() => navigate(`/institutions`)}>
            Панель управления
          </Button>
        </div>
      </div>
    </Card>
  );
};
