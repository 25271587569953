import { PropsWithChildren } from 'react';

export type FiltersQueryItemType = {
  title: string;
  required?: boolean;
  drop?: () => void;
};

export const FiltersQueryItem = ({
  title,
  required,
  drop,

  children,
}: PropsWithChildren<FiltersQueryItemType>) => {
  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'flex flex-row justify-between'}>
        <span>{title}</span>
        {drop && (
          <button onClick={drop} className={'text-neutral-400'}>
            Убрать
          </button>
        )}
      </div>
      {children}
      {required && (
        <span className={'text-sm text-neutral-500'}>
          Обязательный параметр
        </span>
      )}
    </div>
  );
};
