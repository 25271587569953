export type RomanValuesType = {
  [key: string]: number;
};

const romanValues: RomanValuesType = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1,
};

export const convertToRoman = (value: number): string => {
  let converted = '';

  Object.keys(romanValues).forEach(key => {
    const q = Math.floor(value / romanValues[key]);
    value -= q * romanValues[key];
    converted += key.repeat(q);
  });

  return converted;
};
