import { Fragment, ReactNode, useCallback } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { faCheck, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorCard } from '../../block/error-card.component';

export type InputSelectValueType = number | string | boolean;

export type InputSelectItemType = {
  title: string;
  value: InputSelectValueType;
  view?: ReactNode;
};

export type InputSelectProps = {
  title: string;
  background?: boolean;
  items: InputSelectItemType[];
  onChange: (value: InputSelectValueType) => void;
  selected?: InputSelectValueType;
  error?: string;
  above?: boolean;
  centered?: boolean;
  classname?: string;
};

export const InputSelect = ({
  title,
  background,
  items,
  selected,
  onChange,
  error,
  above,
  centered,
  classname,
}: InputSelectProps) => {
  const itemById = useCallback(
    (value: InputSelectValueType | undefined) =>
      items.find(item => item.value == value),
    [items],
  );

  return (
    <div className="w-full">
      <Listbox value={selected?.toString()} onChange={onChange}>
        <div className="relative">
          <Listbox.Button
            className={
              background ?? true
                ? `text-left px-4 py-2 rounded-lg text-md bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6] dark:placeholder-[#e1e3e6] ${
                    classname ? classname : 'w-full'
                  }`
                : 'rounded-lg px-4 py-2 w-full hover:bg-[#F0F2F5] hover:dark:bg-[#333333] hover:cursor-pointer focus:cursor-text focus:bg-[#F0F2F5] focus:dark:bg-[#333333]'
            }
          >
            <span
              className={`block truncate ${
                centered ?? false ? 'text-center' : 'text-left'
              }`}
            >
              {selected !== undefined ? (
                <div
                  className={`${
                    !itemById(selected) && 'text-[#9199A5] dark:text-[#e1e3e6]'
                  }`}
                >
                  {itemById(selected)?.title ?? title}
                </div>
              ) : (
                <div className={'text-[#9199A5] dark:text-[#e1e3e6]'}>
                  {title}
                </div>
              )}
            </span>
            {(background ?? true) && (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <FontAwesomeIcon
                  className="h-5 w-5 text-gray-400"
                  icon={faUpDown}
                />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute z-[500] max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-[#141414] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                above ? 'mb-1 bottom-full' : 'mt-1 top-full'
              }`}
            >
              {items.map(item => (
                <Listbox.Option
                  key={
                    typeof item.value === 'boolean'
                      ? Number(item.value)
                      : item.value
                  }
                  className={`relative cursor-pointer select-none py-2 ${
                    background ?? true ? 'pl-10 pr-4' : 'px-1 '
                  } hover:bg-gray-50 dark:hover:bg-[#222222]`}
                  value={item.value.toString()}
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={`block px-4 ${
                          centered ?? false ? 'text-center' : 'text-left'
                        } truncate text-black dark:text-white ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {item.view ?? item.title}
                      </div>
                      {selected && (background ?? true) ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black dark:text-white">
                          <FontAwesomeIcon className="h-5 w-5" icon={faCheck} />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error && <ErrorCard error={error} />}
    </div>
  );
};
