import { Card } from '../../../common/block/card.component';
import { convertToRoman } from '../../../common/utils/number.utils';
import {
  Badge2,
  BadgeColorVariant2,
} from '../../../common/component/badge2/badge';
import React from 'react';
import { PlanEditorMetaItem } from './plan-editor-meta-item.component';
import { usePlanEditor } from '../../plan-editor.context';
import { Button } from '../../../common/component/button/button.component';
import { useAuth } from '../../../app/auth/auth.context';
import { PermissionType } from '../../../app/auth/auth-role-permission.type';
import { Profile } from '../../../common/component/profile/profile.component';

const statusTranslations = {
  NOT_STARTED: 'Не начиналось',
  IN_PROGRESS: 'В процессе разработки',
  REVIEW_REQUESTED: 'Требуется проверка',
  DECLINED: 'Требуются правки',
  REVIEWED: 'Проверено',
};

const statusColors = {
  NOT_STARTED: 'red',
  IN_PROGRESS: 'yellow',
  REVIEW_REQUESTED: 'purple',
  DECLINED: 'red',
  REVIEWED: 'green',
};

export const PlanEditorMeta = () => {
  const { result } = useAuth();
  const { plan, readonly, setModal } = usePlanEditor();

  return (
    <div className={'flex flex-col gap-4'}>
      <Card className={'p-4 flex flex-col gap-4'}>
        <span className={'text-lg font-semibold'}>Мета-информация</span>

        <div className={'flex flex-col gap-2 mb-6'}>
          <PlanEditorMetaItem title={'Дисциплина'}>
            <span>{plan.subject.title}</span>
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Академическая группа'}>
            <span>{plan.group.title}</span>
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Курс'}>
            {convertToRoman(plan.year)}
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Нагрузка'}>
            {plan.subject.academic.hours
              .sort(
                (first, second) =>
                  (first.semester?.start || 0) - (second.semester?.start || 0),
              )
              .map(hour => {
                const semesterIndex = plan.specialty?.semesters
                  ?.sort(
                    (first, second) =>
                      (first?.start || 0) - (second?.start || 0),
                  )
                  ?.findIndex(semester => semester.id === hour.semesterId);

                return `${hour.hours} ч. ${
                  semesterIndex !== -1 && semesterIndex !== undefined
                    ? `(${convertToRoman(semesterIndex + 1)} сем.)`
                    : ''
                }`;
              })
              .join(' / ')}
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Статус'}>
            <Badge2
              color={statusColors[plan.status?.status] as BadgeColorVariant2}
            >
              {statusTranslations[plan.status?.status]}
            </Badge2>
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Разработчик'}>
            <Profile size={8} user={plan.teacher} />
          </PlanEditorMetaItem>
        </div>

        <div className={'flex flex-col gap-2'}>
          {result?.permissions?.includes(
            PermissionType.JOURNAL_PLAN_MANAGE_EXPLICIT,
          ) &&
            plan.status?.status === 'REVIEW_REQUESTED' && (
              <>
                <div className={'flex flex-row gap-2'}>
                  <div className={'w-1/2'}>
                    <Button
                      color={'green'}
                      onClick={() => setModal({ type: 'accept' })}
                    >
                      Одобрить
                    </Button>
                  </div>

                  <div className={'w-1/2'}>
                    <Button
                      color={'red'}
                      onClick={() => setModal({ type: 'decline' })}
                    >
                      Отказать
                    </Button>
                  </div>
                </div>
              </>
            )}

          {plan.status?.status !== 'REVIEWED' &&
            plan.teacher.id === result?.user?.id && (
              <Button
                color={plan.status?.status === 'IN_PROGRESS' ? 'green' : 'blue'}
                onClick={() => setModal({ type: 'verify' })}
              >
                {plan.status?.status === 'IN_PROGRESS'
                  ? 'Отправить на проверку'
                  : plan.status?.status === 'REVIEW_REQUESTED'
                  ? 'Снять с проверки'
                  : 'Редактировать'}
              </Button>
            )}
          {!readonly && plan.teacher.id === result?.user?.id && (
            <Button
              color={'yellow'}
              onClick={() => setModal({ type: 'import' })}
            >
              Импорт
            </Button>
          )}
          {!readonly && plan.teacher.id === result?.user?.id && (
            <Button
              color={'red'}
              onClick={event => {
                if (event.shiftKey) {
                  // TODO: instant delete
                } else {
                  setModal({ type: 'delete' });
                }
              }}
            >
              Удалить
            </Button>
          )}
        </div>
      </Card>

      {plan.status?.status === 'DECLINED' && plan.status?.note && (
        <Card className={'p-4 flex flex-col gap-4'}>
          <div className={'flex flex-row justify-between'}>
            <span className={'text-lg font-semibold'}>Причина отказа:</span>
          </div>

          <pre>{plan.status?.note}</pre>
        </Card>
      )}
    </div>
  );
};
