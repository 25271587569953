import { FileCollectionType } from './file-collection.type';
import { FileDTO } from './file.entity';
import { api } from '../../../app/networking/axios';
import axios from 'axios';

export const createFiles = (
  collection: FileCollectionType,
  isSystem: boolean,
  count: number,
) =>
  api.post<FileDTO[]>('/file/', {
    collection,
    count,
    isSystem,
  });

export const uploadFile = (url: string, file: File) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      Authorization: undefined,
    },
  });
