import { Button } from '../../common/component/button/button.component';

import { useNews } from '../news.context';
import { NewsAdminCreateModal } from './modify/news-admin-create-modal.component';
import { NewsAdminDeleteModal } from './modify/news-admin-delete-modal';

export const NewsAdmin = () => {
  const { setModal } = useNews();

  return (
    <div className={'flex flex-row justify-between'}>
      <div />

      <div className={'flex flex-row gap-2'}>
        <Button color={'green'} onClick={() => setModal({ type: 'create' })}>
          Опубликовать
        </Button>

        <NewsAdminCreateModal />
        <NewsAdminDeleteModal />
      </div>
    </div>
  );
};
