import {
  EntityActionType,
  EntityColumnType,
  EntityDefaultActions,
  EntityLinkType,
  EntityType,
} from '../../entity.type';
import { useEntity } from '../../entity.context';
import { EntityTableActions } from './entity-table-actions.component';
import { retrieveValue } from '../../entity.utils';

export type EntityTableContentProps<ItemType> = {
  columns: EntityColumnType<EntityType<ItemType>>[];
  actions?: EntityActionType<EntityType<ItemType>>[];
  links?: EntityLinkType<EntityType<ItemType>>[];
  disabledActions?: EntityDefaultActions[];
};

export const EntityTableContent = <ItemType,>({
  columns,
  actions,
  links,
  disabledActions,
}: EntityTableContentProps<ItemType>) => {
  const { items } = useEntity<ItemType>();

  return (
    <tbody className="bg-white divide-y divide-[#F0F2F5] dark:divide-[#333333] dark:bg-[#222222]">
      {items.map(item => (
        <tr key={item.id}>
          {columns
            .filter(column => {
              const input = column.input
                ? retrieveValue(column.input)
                : undefined;

              return (
                column.render !== undefined ||
                (input?.type === 'select' && input?.render)
              );
            })
            .map(column => {
              const input = column.input
                ? retrieveValue(column.input)
                : undefined;

              return (
                <td
                  key={column.key}
                  className={`py-3.5 pl-4 ${
                    !!item.small && 'pr-12'
                  } text-left rtl:text-right text-black dark:text-white`}
                >
                  {column.render ? (
                    column.render(item)
                  ) : input?.type === 'select' && input.render ? (
                    input.items.find(
                      selectItem => selectItem.value === item[column.key],
                    )?.title ?? 'Не установлено'
                  ) : (
                    <div />
                  )}
                </td>
              );
            })}

          <EntityTableActions
            actions={actions}
            links={links}
            item={item}
            disabledActions={disabledActions}
          />
        </tr>
      ))}
    </tbody>
  );
};
