import { FieldValues } from 'react-hook-form';
import { api } from '../app/networking/axios';

export const findAll = <ItemType>(
  route: string,
  offset: number,
  limit: number,
  search?: string,
  values?: FieldValues,
) =>
  api.get<ItemType[]>(route, {
    params: {
      offset,
      limit,
      ...(search && search.length >= 3 ? { search } : {}),
      ...values,
    },
  });

export const findOne = <ItemType>(route: string, id: number) =>
  getOne<ItemType>(`${route}/${id}`);

export const getOne = <ItemType>(route: string, params?: FieldValues) =>
  api.get<ItemType>(route, {
    params,
  });

export const createOne = <ItemType>(route: string, data: FieldValues) =>
  api.post<ItemType>(route, data);

export const updateOne = <ItemType>(
  route: string,
  id: number,
  data: FieldValues,
) => api.put<ItemType>(`${route}/${id}`, data);

export const putOne = <ItemType>(route: string, data: FieldValues) =>
  api.put<ItemType>(route, data);

export const dropOne = <ItemType>(route: string, id: number) =>
  api.delete<ItemType>(`${route}/${id}`);

export const dropWithData = <ItemType>(route: string, data: FieldValues) =>
  api.delete<ItemType>(route, {
    data,
  });
