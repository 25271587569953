import { RoleProvider } from './role.context';
import { RoleTable } from './components/role-table.component';
import { RolePermissionsModal } from './components/role-permissions-modal.component';

export const RoleModule = () => (
  <RoleProvider>
    <RoleTable />
    <RolePermissionsModal />
  </RoleProvider>
);
