import { Card } from '../../common/block/card.component';
import { PlanBaseValuesType, usePlan } from '../plan.context';
import { InputSelect } from '../../common/component/input-select/input-select';
import { PlanHeaderTabs } from './plan-header-tabs.component';
import { Button } from '../../common/component/button/button.component';

export const PlanHeader = () => {
  const {
    specialty,
    base,
    setBase,
    revisions,
    revision,
    setRevision,
    setModal,
  } = usePlan();

  return (
    <Card className={'p-4 flex flex-row justify-between items-center'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'text-lg font-bold'}>{specialty.title}</div>
        <div className={'text-md font-medium'}>Учебный план</div>
      </div>
      <div className={'flex flex-row gap-2'}>
        <PlanHeaderTabs />
        <div className={'w-56'}>
          <InputSelect
            title={'База'}
            selected={base}
            items={[
              {
                title: '11 классов',
                value: 'FULL',
              },
              {
                title: '9 классов',
                value: 'NOT_FULL',
              },
            ]}
            onChange={value => setBase(value as PlanBaseValuesType)}
          />
        </div>
        <div className={'w-56'}>
          <InputSelect
            title={'Ревизия'}
            selected={revision}
            items={revisions.map(item => ({
              title: item.toString(),
              value: item,
            }))}
            onChange={value => setRevision(Number(value))}
          />
        </div>
        <div className={'w-44'}>
          <Button color={'yellow'} onClick={() => setModal({ type: 'clone' })}>
            Клонирование
          </Button>
        </div>
      </div>
    </Card>
  );
};
