import { Modal } from '../../../common/component/modal/modal';
import { NewsFileEntity } from '../../news.entity';

export type NewsImagePreviewProps = {
  files: NewsFileEntity[];
  preview: number | undefined;
  iterateImage: () => void;

  onClose: () => void;
};

export const NewsImagesPreview = ({
  files,
  preview,
  iterateImage,
  onClose,
}: NewsImagePreviewProps) => (
  <Modal showed={preview !== undefined} onClose={onClose}>
    <div className={'flex flex-col text-center gap-4'}>
      <img
        className={'hover:cursor-pointer rounded-xl'}
        src={files[preview ?? 0].url}
        onClick={iterateImage}
      />

      <div>
        Изображение {(preview ?? 0) + 1} из {files.length}
      </div>
    </div>
  </Modal>
);
