import { useParams } from 'react-router-dom';
import { SpecialtyGroupProvider } from '../specialty-group/specialty-group.context';
import { SpecialtyGroupParams } from '../specialty-group';
import { SpecialtyGroupStatementsProvider } from './specialty-group-statements.context';
import { StatementRoot } from './components/statement-root.component';

export type SpecialtyGroupStatementsParams = {
  groupId: string;
};

export const SpecialtyGroupStatementsModule = () => {
  const { specialtyId, groupId } = useParams<
    SpecialtyGroupParams & SpecialtyGroupStatementsParams
  >();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <SpecialtyGroupStatementsProvider groupId={groupId}>
        <StatementRoot />
      </SpecialtyGroupStatementsProvider>
    </SpecialtyGroupProvider>
  );
};
