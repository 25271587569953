import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '../../../common/component/button/button.component';
import { TextArea } from '../../../common/component/textarea/textarea.component';
import { FileCollectionType } from '../../../common/component/file/file-collection.type';
import { Modal } from '../../../common/component/modal/modal';
import { FileUploader } from '../../../common/component/file/file-uploader';
import { Input } from '../../../common/component/input/input.component';

import { useNews } from '../../news.context';
import { NewsEntityValues } from '../../news.entity';
import { NewsAdminCreatePoll } from './news-admin-create-poll.component';
import { useAuth } from '../../../app/auth/auth.context';
import { Toggle } from '../../../common/component/toggle/toggle.component';

export const NewsAdminCreateModal = () => {
  const { result } = useAuth();
  const { modal, setModal, modify, poll, setPollStatus } = useNews();
  const { register, handleSubmit, setValue, reset, control } =
    useForm<NewsEntityValues>();

  const onSubmit = handleSubmit(modify);

  useEffect(() => {
    if (modal.type !== 'none') {
      reset();
    }

    if (modal.type === 'update') {
      setValue('title', modal.item.title);
      setValue('short', modal.item.short);
      setValue('content', modal.item.content);
    }
  }, [modal, reset]);

  return (
    <Modal
      title={`${
        modal.type === 'create' ? 'Создание' : 'Редактирование'
      } публикации`}
      right={
        modal.type === 'create' && (
          <div className={'flex flex-row gap-2'}>
            {!poll && (
              <Button onClick={() => setPollStatus(true)}>
                Добавить опрос
              </Button>
            )}
          </div>
        )
      }
      showed={modal.type === 'create' || modal.type === 'update'}
      onClose={() => setModal({ type: 'none' })}
    >
      <form onSubmit={onSubmit} className={'flex flex-col gap-4'}>
        <Input placeholder={'Название'} {...register('title')} />
        <Input placeholder={'Сокращенное название'} {...register('short')} />

        <TextArea
          placeholder={'Содержимое'}
          className={'resize-none'}
          {...register('content')}
        />

        {(modal.type === 'create' || modal.type === 'update') && (
          <TextArea
            rows={3}
            placeholder={'Краткое содержимое для уведомлений'}
            className={'resize-none'}
            {...register('notification', {
              minLength: {
                value: 3,
                message: 'Минимальная длина 3 символа',
              },
              maxLength: {
                value: 50,
                message: 'Максимальная длина 50 символов',
              },
            })}
          />
        )}

        {modal.type === 'create' && (result?.user?.isAdmin ?? false) && (
          <Controller
            name={'isAdmin'}
            control={control}
            render={({ field }) => (
              <Toggle
                label={'От имени платформы'}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}

        {modal.type === 'create' && (
          <FileUploader
            maxFiles={10}
            maxSize={423423}
            formats={['jpeg', 'jpg', 'png']}
            collection={FileCollectionType.NEWS}
            onChange={attachments => setValue('attachments', attachments)}
          />
        )}

        {poll && <NewsAdminCreatePoll setValue={setValue} />}

        <Button type={'submit'} color={'green'}>
          {modal.type === 'create' ? 'Опубликовать' : 'Обновить'}
        </Button>
      </form>
    </Modal>
  );
};
