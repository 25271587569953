import { useNews } from '../../news.context';
import { NewsEntity } from '../../news.entity';

export type NewsReactionsProps = {
  item: NewsEntity;
};

export const NewsReactions = ({ item }: NewsReactionsProps) => {
  const { reactions, react } = useNews();

  return (
    <div className={'flex flex-row gap-2 items-center'}>
      {[...reactions]
        .sort(
          (first, second) =>
            Number(
              item.reactions.some(
                item => item.reacted && item.type === second.type,
              ),
            ) -
              Number(
                item.reactions.some(
                  item => item.reacted && item.type === first.type,
                ),
              ) ||
            (item.reactions.find(reaction => reaction.type === first.type)
              ?.count ?? 0) -
              (item.reactions.find(reaction => reaction.type === second.type)
                ?.count ?? 0),
        )
        .map(reaction => {
          const userReacted = item.reactions.some(
            item => item.type === reaction.type && item.reacted,
          );

          const count =
            item.reactions.find(found => found.type === reaction.type)?.count ??
            0;

          return (
            <button
              key={reaction.type}
              className={`hover:cursor-pointer text-sm h-11 p-2 rounded-2xl ${
                userReacted
                  ? 'bg-gray-300/70 dark:bg-black/40'
                  : 'bg-gray-100 dark:bg-black/20'
              } ${count === 0 && 'aspect-square'}`}
              onClick={() => react(item.id, reaction.type)}
            >
              <div
                className={
                  'flex flex-row gap-2 items-center justify-center place-items-center'
                }
              >
                <div className={'text-lg'}>{reaction.reaction}</div>
                {count > 0 && (
                  <div className={'font-bold text-gray-600 dark:text-gray-300'}>
                    {count}
                  </div>
                )}
              </div>
            </button>
          );
        })}
    </div>
  );
};
