import { UserEntity } from '../../../user/user.entity';
import { useJournalItems } from '../../journal-items.context';
import { JournalTableMark } from './journal-table-mark.component';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  dropMark,
  fetchRow,
  setMark,
  setTotal,
} from '../../journal-items.service';
import toast from 'react-hot-toast';

export type JournalTableStudentRowProps = {
  student: UserEntity;
  lastItems: boolean;
};

export type SetMarkValues = {
  scheduleId: number;
  value: number;
};

export type DropMarkValues = {
  scheduleId: number;
};

export type SetTotalValues = {
  semesterId?: number;
  month?: number;
  value?: number;
  fixed?: number;
};

export const JournalTableStudentRow = ({
  student,
  lastItems,
}: JournalTableStudentRowProps) => {
  const { groupId, subjectId, meta, totals } = useJournalItems();

  const row = useQuery({
    queryKey: ['row', groupId, subjectId, student],
    queryFn: () => fetchRow(Number(groupId), Number(subjectId), student.id),
    refetchOnWindowFocus: false,
  });

  const mutateMark = useMutation({
    mutationKey: ['mark', groupId, subjectId, student.id],
    mutationFn: (values: SetMarkValues) =>
      toast.promise(
        setMark(
          Number(groupId),
          Number(subjectId),
          values.scheduleId,
          student.id,
          values.value,
        ),
        {
          loading: 'Ставим оценку',
          success: 'Оценка успешно установлена',
          error: 'Ошибка установки оценки',
        },
      ),
    // todo: bad
    onSuccess: () => row.refetch(),
  });

  const mutateTotal = useMutation({
    mutationKey: ['total', groupId, subjectId, student.id],
    mutationFn: (values: SetTotalValues) =>
      toast.promise(
        setTotal(
          Number(groupId),
          Number(subjectId),
          student.id,
          values.semesterId,
          values.month,
          values.value,
          values.fixed,
        ),
        {
          loading: 'Ставим оценку',
          success: 'Оценка успешно установлена',
          error: 'Ошибка установки оценки',
        },
      ),
    onSuccess: () => row.refetch(),
  });

  const mutateDrop = useMutation({
    mutationKey: ['mark', student.id, groupId, subjectId],
    mutationFn: (values: DropMarkValues) =>
      toast.promise(
        dropMark(
          Number(groupId),
          Number(subjectId),
          values.scheduleId,
          student.id,
        ),
        {
          loading: 'Удаляем оценку',
          success: 'Оценка успешно удалена',
          error: 'Ошибка удаления оценки',
        },
      ),
    onSuccess: () => row.refetch(),
  });

  return (
    <div className={'flex flex-row'}>
      {meta?.semesters
        ?.sort((first, second) => first.start - second.start)
        ?.map(semester => {
          const semesterRow = row?.data?.semesters?.find(
            item => item.id === semester.id,
          );

          return (
            <div key={semester.id} className={'flex flex-row'}>
              {semester.months
                .sort((first, second) => first.month - second.month)
                .map(month => {
                  const monthRow = semesterRow?.months?.find(
                    item => item.month === month.month,
                  );

                  return (
                    <div key={month.month} className={'flex flex-row'}>
                      {!totals &&
                        month.schedule.map(schedule => (
                          <JournalTableMark
                            key={schedule.id}
                            mark={
                              row.data?.marks?.find(
                                item => item.scheduleId === schedule.id,
                              )?.value ?? -2
                            }
                            lastItems={lastItems}
                            type={'ITEM'}
                            isLesson={true}
                            onSelected={value =>
                              value !== undefined
                                ? mutateMark.mutate({
                                    value,
                                    scheduleId: schedule.id,
                                  })
                                : mutateDrop.mutate({
                                    scheduleId: schedule.id,
                                  })
                            }
                          />
                        ))}

                      <JournalTableMark
                        mark={monthRow?.value ?? monthRow?.calculated ?? -1}
                        lastItems={lastItems}
                        type={'TOTAL'}
                        onSelected={value =>
                          mutateTotal.mutate({
                            semesterId: semester.id,
                            month: month.month,
                            value,
                          })
                        }
                      />

                      <JournalTableMark
                        mark={monthRow?.fixed ?? -2}
                        lastItems={lastItems}
                        type={'FIXED'}
                        onSelected={fixed =>
                          mutateTotal.mutate({
                            semesterId: semester.id,
                            month: month.month,
                            fixed,
                          })
                        }
                      />
                    </div>
                  );
                })}

              <JournalTableMark
                mark={semesterRow?.value ?? semesterRow?.calculated ?? -1}
                lastItems={lastItems}
                type={'TOTAL'}
                onSelected={value =>
                  mutateTotal.mutate({
                    semesterId: semester.id,
                    value,
                  })
                }
              />

              <JournalTableMark
                mark={semesterRow?.fixed ?? -2}
                lastItems={lastItems}
                type={'FIXED'}
                onSelected={fixed =>
                  mutateTotal.mutate({
                    semesterId: semester.id,
                    fixed,
                  })
                }
              />
            </div>
          );
        })}

      <JournalTableMark
        mark={row?.data?.value ?? row?.data?.calculated ?? -1}
        lastItems={lastItems}
        type={'TOTAL'}
        onSelected={value =>
          mutateTotal.mutate({
            value,
          })
        }
      />

      <JournalTableMark
        mark={row?.data?.fixed ?? -2}
        lastItems={lastItems}
        type={'FIXED'}
        onSelected={fixed =>
          mutateTotal.mutate({
            fixed,
          })
        }
      />
    </div>
  );
};
