import { useAuth } from '../../../app/auth/auth.context';
import { ProfileUserStudent } from './profile-user-student.component';
import { ProfileUserTeacher } from './profile-user-teacher.component';
import React from 'react';
import { ProfileUserDeveloper } from './profile-user-developer.component';

export const ProfileUser = () => {
  const { result } = useAuth();

  if (result?.user?.isAdmin) {
    return <ProfileUserDeveloper />;
  }

  if (result?.user?.type === 'STUDENT') {
    return <ProfileUserStudent />;
  }

  if (result?.user.type === 'TEACHER') {
    return <ProfileUserTeacher />;
  }

  return <div />;
};
