import { AvailabilitiesBoss } from './availabilities-boss.component';
import { AvailabilitiesSubject } from './availabilities-subject.component';

export const Availabilities = () => {
  return (
    <div className={'flex flex-col gap-4'}>
      <AvailabilitiesBoss />
      <AvailabilitiesSubject />
    </div>
  );
};
