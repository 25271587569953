import {
  EntityActionType,
  EntityColumnType,
  EntityDefaultActions,
  EntityLinkType,
  EntityModuleGroupByProps,
} from '../../entity.type';
import { Card } from '../../../common/block/card.component';
import { EntityTableHeader } from './entity-table-header.component';
import { EntityTableContent } from './entity-table-content.component';
import { EntityTableGroupedContent } from './entity-table-grouped-content.component';

export type EntityTableProps<ItemType> = {
  columns: EntityColumnType<ItemType>[];
  groupBy?: EntityModuleGroupByProps<ItemType>;
  actions?: EntityActionType<ItemType>[];
  links?: EntityLinkType<ItemType>[];
  disabledActions?: EntityDefaultActions[];
};

export const EntityTable = <ItemType,>(props: EntityTableProps<ItemType>) => (
  <Card className={'flex flex-row justify-between place-items-center py-2'}>
    <div className={'flex flex-col gap-4 w-full'}>
      <div>
        <table className="w-full divide-y divide-[#F0F2F5] dark:divide-[#333333]">
          <EntityTableHeader groupBy={props.groupBy} columns={props.columns} />

          {props.groupBy ? (
            <EntityTableGroupedContent groupBy={props.groupBy} {...props} />
          ) : (
            <EntityTableContent {...props} />
          )}
        </table>
      </div>
    </div>
  </Card>
);
