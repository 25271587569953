import { Button } from '../../../common/component/button/button.component';
import { InputSelect } from '../../../common/component/input-select/input-select';
import { Input } from '../../../common/component/input/input.component';
import { Card } from '../../../common/block/card.component';
import { ScheduleContext } from '../../schedule.context';
import { DateTime } from 'luxon';
import { Badge } from '../../../common/component/badge/badge';
import { Toggle } from '../../../common/component/toggle/toggle.component';
import { useContextSelector } from 'use-context-selector';

export const ScheduleBottomComponent = () => {
  const setModal = useContextSelector(
    ScheduleContext,
    context => context.setModal,
  );

  const setSearch = useContextSelector(
    ScheduleContext,
    context => context.setSearch,
  );

  const isSemester = useContextSelector(
    ScheduleContext,
    context => context.isSemester,
  );

  const setSemester = useContextSelector(
    ScheduleContext,
    context => context.setSemester,
  );

  const weeks = useContextSelector(ScheduleContext, context => context.weeks);

  const week = useContextSelector(ScheduleContext, context => context.week);

  const setWeek = useContextSelector(
    ScheduleContext,
    context => context.setWeek,
  );

  const isPublished = useContextSelector(
    ScheduleContext,
    context => context.isPublished,
  );

  const setPublished = useContextSelector(
    ScheduleContext,
    context => context.setPublished,
  );

  const publish = useContextSelector(
    ScheduleContext,
    context => context.publish,
  );

  const clone = useContextSelector(ScheduleContext, context => context.clone);

  const isGroupsView = useContextSelector(
    ScheduleContext,
    context => context.isGroupsView,
  );

  const setGroupsView = useContextSelector(
    ScheduleContext,
    context => context.setGroupsView,
  );

  return (
    <Card className={'p-2'}>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-row gap-4'}>
          <Button color={'blue'} onClick={() => setModal({ type: 'settings' })}>
            Настройки
          </Button>
          {!isSemester && (
            <Toggle
              label={'Режим черновика'}
              checked={!isPublished}
              onChange={value => setPublished(!value)}
            />
          )}
          {!isSemester && !isPublished && (
            <Button color={'yellow'} onClick={clone}>
              Клонировать
            </Button>
          )}
          {!isPublished && (
            <Button color={'green'} onClick={publish}>
              Опубликовать
            </Button>
          )}
          <Toggle
            label={'Режим преподавателей'}
            checked={!isGroupsView}
            onChange={value => setGroupsView(!value)}
          />
        </div>

        <div className={'flex flex-row gap-2 w-1/2'}>
          <div className={'w-1/5'}>
            <InputSelect
              title={''}
              selected={isSemester ? 'semester' : 'current'}
              items={[
                {
                  value: 'semester',
                  title: 'Семестровое',
                },
                {
                  value: 'current',
                  title: 'Текущее',
                },
              ]}
              onChange={value => setSemester(value === 'semester')}
              above={true}
            />
          </div>

          <div className={'w-3/5'}>
            <InputSelect
              title={'Учебная неделя'}
              selected={week.toUnixInteger()}
              items={weeks.map((item, index) => ({
                value: item.toUnixInteger(),
                title: isSemester
                  ? `Неделя #${index + 1}`
                  : `${item.toFormat('DD')} - ${item
                      .plus({ day: 6 })
                      .toFormat('DD')}`,
                view: (
                  <div className={'flex flex-row justify-between'}>
                    {isSemester ? (
                      <span>Неделя #{index + 1}</span>
                    ) : (
                      <div className={'flex flex-row gap-1'}>
                        <span>{item.toFormat('DD')}</span>
                        <span>-</span>
                        <span>{item.plus({ day: 6 }).toFormat('DD')}</span>
                      </div>
                    )}

                    <div className={'flex flex-row gap-2'}>
                      <Badge color={index % 2 !== 0 ? 'green' : 'red'}>
                        {index % 2 !== 0 ? 'Четная' : 'Нечетная'}
                      </Badge>
                      {!isSemester && (
                        <Badge color={'gray'}>Неделя #{index + 1}</Badge>
                      )}
                    </div>
                  </div>
                ),
              }))}
              onChange={value => setWeek(DateTime.fromSeconds(Number(value)))}
              above={true}
            />
          </div>

          <div className={'w-1/5'}>
            <Input
              onChange={event => setSearch(event.target.value)}
              placeholder={'Поиск'}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
