import { api } from '../app/networking/axios';
import {
  JournalAvailabilitiesEntity,
  JournalMarkCalculatedDTO,
  JournalMarkRowEntity,
  JournalMetaEntity,
  SetJournalMarkResultEntity,
} from './journal-items.entity';

export const fetchAvailabilities = () =>
  api
    .get<JournalAvailabilitiesEntity>('/journal/availabilities/')
    .then(response => response.data);

export const fetchMeta = (groupId: number, subjectId: number) =>
  api
    .get<JournalMetaEntity>(`/journal/${groupId}/${subjectId}/meta`)
    .then(response => response.data);

export const fetchRow = (
  groupId: number,
  subjectId: number,
  studentId: number,
) =>
  api
    .get<JournalMarkRowEntity>(
      `/journal/${groupId}/${subjectId}/${studentId}/row`,
    )
    .then(response => response.data);

export const setMark = (
  groupId: number,
  subjectId: number,
  scheduleId: number,
  studentId: number,
  value: number,
) =>
  api
    .post<SetJournalMarkResultEntity>(`/journal/${groupId}/${subjectId}/mark`, {
      scheduleId,
      studentId,
      value,
    })
    .then(response => response.data);

export const setTotal = (
  groupId: number,
  subjectId: number,
  studentId: number,
  semesterId?: number,
  month?: number,
  value?: number,
  fixed?: number,
) =>
  api
    .post<JournalMarkCalculatedDTO>(`/journal/${groupId}/${subjectId}/total`, {
      ...(studentId !== undefined && { studentId }),
      ...(semesterId !== undefined && { semesterId }),
      ...(month !== undefined && { month }),
      ...(value !== undefined && { value }),
      ...(fixed !== undefined && { fixed }),
    })
    .then(response => response.data);

export const dropMark = (
  groupId: number,
  subjectId: number,
  scheduleId: number,
  studentId: number,
) =>
  api
    .delete<JournalMarkCalculatedDTO>(`/journal/${groupId}/${subjectId}/mark`, {
      data: {
        scheduleId,
        studentId,
      },
    })
    .then(response => response.data);
