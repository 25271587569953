import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAppStoreIos,
  faGooglePlay,
  faVk,
} from '@fortawesome/free-brands-svg-icons';

import phone from '../assets/phone.png';

export const MobileLanding = () => {
  return (
    <div className={'flex-col text-center gap-4'}>
      <div className={'flex flex-col justify-center gap-8'}>
        <div>
          <h1 className={'font-semibold text-xl'}>
            Мобильные приложения платформы
          </h1>
          <span className={'dark:text-[#939393]'}>
            Установите официальное мобильное приложение Платформы
          </span>
        </div>

        <div className={'flex flex-row h-[500px] justify-center'}>
          <img src={phone} className={'max-h-[500px] object-contain'} />
        </div>

        <div className={'flex gap-4 text-[#939393] justify-center'}>
          <a
            href={'https://apps.apple.com/us/app/платформа-нкэивт/id1668578184'}
          >
            <div className={'flex gap-2'}>
              <FontAwesomeIcon icon={faAppStoreIos} size={'xl'} />
              <span>AppStore</span>
            </div>
          </a>
          <a
            href={'https://apps.rustore.ru/app/ru.nke.collegeschedule.schedule'}
          >
            <div className={'flex gap-2'}>
              <FontAwesomeIcon icon={faVk} size={'xl'} />
              <span>RuStore</span>
            </div>
          </a>
          <a
            href={
              'https://play.google.com/store/apps/datasafety?id=ru.nke.collegeschedule.schedule'
            }
          >
            <div className={'flex gap-2'}>
              <FontAwesomeIcon icon={faGooglePlay} size={'xl'} />
              <span>Google Play</span>
            </div>
          </a>
        </div>

        <div
          className={'flex flex-wrap justify-center items-center gap-2'}
        ></div>
      </div>
    </div>
  );
};
