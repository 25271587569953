import {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createFiles, uploadFile } from './file-service';
import { FileCollectionType } from './file-collection.type';

export type FileUploaderContextProps = {
  maxFiles: number;
  maxSize: number;
  formats: string[];
  collection?: FileCollectionType;
  isSystem?: boolean;
  upload?: boolean;
  showSelected?: boolean;

  onChange?: (attachments: number[]) => void;
  onFiles?: (files: File[]) => void;
};

export type FileUploaderContextType = {
  allowMultiple: boolean;

  files: File[];

  button: RefObject<HTMLButtonElement>;
  input: RefObject<HTMLInputElement>;

  isLoading: boolean;
  isSuccess: boolean;
  isError: string | undefined;
};

export const FileUploaderContext = createContext<FileUploaderContextType>(
  {} as FileUploaderContextType,
);

export const FileUploaderProvider = ({
  maxFiles,
  maxSize,
  formats,
  collection,
  isSystem,
  upload,
  onChange,
  onFiles,
  children,
}: FileUploaderContextProps & PropsWithChildren) => {
  const button = useRef<HTMLButtonElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<string | undefined>(undefined);

  const handleFiles = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        return;
      }

      if (files.length > maxFiles) {
        setLoading(false);
        setSuccess(false);
        setError(`Максимальное количество файлов: ${maxSize}`);

        return;
      }

      if (
        formats &&
        files.some(
          file =>
            !formats.some(format =>
              file.name.toLowerCase().endsWith(format.toLowerCase()),
            ),
        )
      ) {
        setLoading(false);
        setSuccess(false);
        setError(
          `Разрешенные форматы файлов: ${formats
            .map(item => item.toUpperCase())
            .join(', ')}`,
        );

        return;
      }

      setError(undefined);
      setSuccess(true);
      setFiles(files);

      onFiles?.(files);

      if (!(upload ?? true) || !collection) {
        return;
      }

      createFiles(collection, isSystem ?? false, files.length)
        .then(async response => {
          await Promise.all(
            response.data
              .map((item, index) => {
                const file = files[index];

                if (!file) {
                  return undefined;
                }

                return uploadFile(item.url, file);
              })
              .filter(Boolean),
          );

          onChange?.(response.data.map(item => item.id));
        })
        .catch(console.log);
    },
    [setLoading, setSuccess, setError],
  );

  const handleClick = useCallback(() => {
    input.current?.click();
  }, [input]);

  const handleChange = useCallback(
    (event: Event) => {
      handleFiles([
        ...((event as unknown as ChangeEvent<HTMLInputElement>).target.files ??
          []),
      ]);
    },
    [handleFiles],
  );

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (!event.dataTransfer || event.dataTransfer.files.length === 0) {
        return;
      }

      handleFiles([...(event.dataTransfer.files ?? [])]);
    },
    [handleFiles],
  );

  useEffect(() => {
    button.current?.addEventListener('click', handleClick);
    input.current?.addEventListener('change', handleChange);

    button.current?.addEventListener('dragover', handleDragOver);
    button.current?.addEventListener('drop', handleDrop);

    return () => {
      button.current?.removeEventListener('click', handleClick);
      input.current?.removeEventListener('change', handleChange);

      button.current?.removeEventListener('dragover', handleDragOver);
      button.current?.removeEventListener('drop', handleDrop);
    };
  }, [handleClick, handleFiles, handleChange]);

  const value = useMemo(
    () => ({
      allowMultiple: maxSize > 1,

      button,
      input,

      files,

      isLoading,
      isSuccess,
      isError,
    }),
    [maxSize, button, input, files, isLoading, isSuccess, isError],
  );

  return (
    <FileUploaderContext.Provider value={value}>
      {children}
    </FileUploaderContext.Provider>
  );
};

export const useFileUploader = () =>
  useContext<FileUploaderContextType>(FileUploaderContext);
