import { PlanTableHeaderTitle } from './plan-table-header-title.component';
import { PlanTableHeaderDistribution } from './plan-table-header-distribution.component';

export const PlanTableHeader = () => {
  return (
    <div
      className={
        'flex flex-row border-b-2 border-[#F0F2F5] dark:border-[#333333]'
      }
    >
      <PlanTableHeaderTitle />
      <PlanTableHeaderDistribution />
    </div>
  );
};
