import { MobileLanding } from './components/mobile-landing.component';
import { LoginForm } from './components/login/login-block.component';
import { Terms } from './components/terms/terms.component';

export type AuthModuleProps = {
  isAllowedLoginBlock: boolean;
};

export const AuthModule = ({ isAllowedLoginBlock }: AuthModuleProps) => {
  return (
    <div className={'grid h-screen place-items-center'}>
      <div className={'flex flex-row gap-[200px] items-center'}>
        <div
          className={`flex flex-col gap-4 ${
            !isAllowedLoginBlock && 'hidden lg:block'
          }`}
        >
          <LoginForm />
        </div>

        <div className={`${isAllowedLoginBlock && 'hidden'}`}>
          <MobileLanding />
        </div>
      </div>

      <Terms />
    </div>
  );
};
