import { Switch } from '@headlessui/react';

export type ToggleProps = {
  label: string;
  checked: boolean | undefined;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export const Toggle = ({ label, checked, onChange, disabled }: ToggleProps) => {
  return (
    <Switch.Group>
      <div className={'flex flex-row justify-between items-center gap-2'}>
        <Switch.Label className={'whitespace-nowrap'}>{label}</Switch.Label>
        <Switch
          disabled={disabled ?? false}
          checked={checked}
          onChange={onChange}
        >
          {({ checked }) => (
            <span
              className={`${
                checked ? 'bg-[#68B158]' : 'bg-gray-200 dark:bg-[#333333]'
              } relative inline-flex h-6 w-11 items-center rounded-full mt-0.5`}
            >
              <span
                className={`${
                  checked ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </span>
          )}
        </Switch>
      </div>
    </Switch.Group>
  );
};
