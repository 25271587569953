import { UserEntity } from '../user.entity';
import { DateTime } from 'luxon';
import { FileCollectionType } from '../../common/component/file/file-collection.type';
import { EntityModule } from '../../entity';
import { useAuth } from '../../app/auth/auth.context';
import { useUsers } from '../user.context';
import { Profile } from '../../common/component/profile/profile.component';
import { Verification } from '../../common/component/verification/verification.component';

export const UserTable = () => {
  const { result } = useAuth();
  const { setUser } = useUsers();

  return (
    <EntityModule<UserEntity>
      title={'Пользователи'}
      route={'users'}
      columns={[
        {
          key: 'cover',
          title: '',
          small: true,
          render: item => <Profile user={item} title={false} size={10} />,
        },
        {
          key: 'title',
          title: 'ФИО',
          render: item => (
            <div className={'flex flex-row gap-2 items-center'}>
              {item.full}
              {item.isAdmin && <Verification />}
            </div>
          ),
        },
        {
          key: 'secondName',
          title: 'Фамилия',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
              minLength: {
                value: 3,
                message: 'Минимальная длина 3 символа',
              },
            },
          },
        },
        {
          key: 'firstName',
          title: 'Имя',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
              minLength: {
                value: 3,
                message: 'Минимальная длина 3 символа',
              },
            },
          },
        },
        {
          key: 'thirdName',
          title: 'Отчество',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
              minLength: {
                value: 3,
                message: 'Минимальная длина 3 символа',
              },
            },
          },
        },
        {
          key: 'mail',
          title: 'Почта',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
            },
          },
        },
        {
          key: 'sex',
          title: 'Пол',
          input: {
            type: 'select',
            items: [
              {
                value: '0',
                title: 'Мужской',
              },
              {
                value: '1',
                title: 'Женский',
              },
            ],
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item => (item.sex === 0 ? 'Мужской' : 'Женский'),
        },
        {
          key: 'birthDate',
          title: 'Дата рождения',
          input: {
            type: 'calendar',
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item =>
            DateTime.fromSeconds(item.birthDate).toLocaleString(
              DateTime.DATE_MED,
            ),
        },
        {
          key: 'isSpecial',
          title: 'Спец. Пользователь',
          input: {
            type: 'toggle',
            title: 'Специальный пользователь',
            disabled: !(result?.user?.isAdmin ?? false),
          },
        },
        {
          key: 'avatarId',
          title: 'Аватар',
          input: {
            type: 'attachment',
            collection: FileCollectionType.AVATAR,
            formats: ['.png', '.jpeg', '.jpg'],
            count: 1,
            isSystem: false,
          },
        },
      ]}
      links={
        result?.user.isAdmin
          ? [
              {
                title: 'Полномочия',
                color: 'green',
                onClick: user => setUser(user),
              },
            ]
          : []
      }
    />
  );
};
