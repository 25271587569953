import { Menu } from '@headlessui/react';

export type MenuColorVariant = 'default' | 'blue' | 'red' | 'yellow' | 'green';

const colorValues = {
  default: 'bg-[#222222] text-white dark:bg-white dark:text-[#222222]',
  blue: 'bg-[#3375f6] text-white',
  red: 'bg-[#FD3246] text-white',
  yellow: 'bg-[#E9A440] text-white',
  green: 'bg-[#68B158] text-white',
};

export type DropdownItemProps = {
  title: string;
  onClick?: () => void;
  color?: MenuColorVariant;
};

export const DropdownItem = ({ title, onClick, color }: DropdownItemProps) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type={'button'}
          className={`${
            active
              ? colorValues[color ?? 'default']
              : 'text-black dark:text-white'
          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
          onClick={onClick}
        >
          {title}
        </button>
      )}
    </Menu.Item>
  );
};
