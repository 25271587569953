import { useProfile } from '../../profile.context';
import { ScheduleSection } from './schedule-section.component';
import { Fragment } from 'react';

export const Schedule = () => {
  const { nearby } = useProfile();

  if (
    [nearby.tomorrow, nearby.today, nearby.playing, nearby.soon]
      .map(item => item.length)
      .filter(item => item > 0).length === 0
  ) {
    return <Fragment />;
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <ScheduleSection title={'Сейчас'} journal={true} items={nearby.playing} />

      <ScheduleSection
        title={'Сегодня'}
        journal={false}
        items={[...nearby.soon, ...nearby.today]}
      />

      <ScheduleSection
        title={'Завтра'}
        journal={false}
        items={nearby.tomorrow}
      />
    </div>
  );
};
