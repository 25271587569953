import { useNotifier } from '../app/notification/notification-context';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SpecialtyEntity } from '../specialty/specialty.entity';
import { findOne } from '../entity/entity.service';
import { SpinningLoader } from '../common/component/loading/spinning-loader.component';
import { PlanProvider } from './plan.context';
import { PlanTabs } from './components/plan-tabs.component';

export type SpecialtyGroupParams = {
  specialtyId: string;
};

export const SpecialtyPlanModule = () => {
  const { notify } = useNotifier();
  const { specialtyId } = useParams<SpecialtyGroupParams>();

  const [specialty, setSpecialty] = useState<SpecialtyEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    findOne<SpecialtyEntity>('specialty', Number(specialtyId))
      .then(response => setSpecialty(response.data))
      .catch(() => notify('error', 'Ошибка загрузки данных'));
  }, [specialtyId]);

  if (!specialty) {
    return <SpinningLoader center />;
  }

  return (
    <PlanProvider specialty={specialty}>
      <PlanTabs />
    </PlanProvider>
  );
};
