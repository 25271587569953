import { PlanHeader } from './plan-header.component';
import { PlanContent } from './plan-content.component';

export const Plan = () => (
  <div className={'flex flex-col gap-4'}>
    <PlanHeader />

    <PlanContent />
  </div>
);
