import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export type SetSearchState = (value: string) => void;
export type SetSearchNumericState = (value: number) => void;

export const useSearchState = (
  key: string,
  defaultValue?: string,
): [string | undefined, SetSearchState] => {
  const [params, setParams] = useSearchParams();

  const set = useCallback(
    (value: string) =>
      setParams(previous => ({
        ...Object.fromEntries(Array.from(previous.entries())),

        [key]: value,
      })),
    [setParams],
  );

  return [params.get(key) ?? defaultValue, set];
};

export const useSearchNumericState = (
  key: string,
  defaultValue?: number,
): [number | undefined, SetSearchNumericState] => {
  const [params, setParams] = useSearchParams();

  const set = useCallback(
    (value: number) =>
      setParams(previous => ({
        ...Object.fromEntries(Array.from(previous.entries())),

        [key]: value.toString(),
      })),
    [setParams],
  );

  return [Number(params.get(key)) || defaultValue, set];
};
