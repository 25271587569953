import { usePlan } from '../../plan.context';
import { EntityModule } from '../../../entity';
import { SemesterEntity } from './semester.entity';
import { DateTime } from 'luxon';

export const PlanSemester = () => {
  const { specialty, base, revision, revisions } = usePlan();

  return (
    <EntityModule<SemesterEntity>
      title={'Семестры'}
      header={false}
      footer={false}
      groupBy={{
        key: 'year',
        sorted: (first, second) => first.year - second.year,
        predefined: Array.from(Array(revisions.length).keys()),
        headerRender: value =>
          `Учебный год #${Number(value) + 1} (${revision + Number(value)})`,
      }}
      route={`/specialties/${specialty.id}/academic/${revision}/${base}/semesters`}
      columns={[
        {
          key: 'id',
          title: 'Семестр',
          renderCountThrough: {
            enabled: true,
            roman: true,
          },
        },
        {
          key: 'range',
          title: 'Дата начала и окончания семестра',
          input: (key: string | number) => {
            const start = DateTime.now()
              .set({ year: revision + Number(key) })
              .set({
                month: 9,
                day: 0,
              })
              .toJSDate();

            const end = DateTime.now()
              .set({
                year: revision + 1 + Number(key),
              })
              .set({
                month: 7,
                day: 15,
              })
              .toJSDate();

            return {
              type: 'calendar',
              range: true,
              startProperty: 'start',
              endProperty: 'end',
              props: {
                startFrom: start,
                minDate: start,
                maxDate: end,
              },
            };
          },
          render: value => `${DateTime.fromSeconds(value.start).toFormat(
            'DD',
          )} - 
              ${DateTime.fromSeconds(value.end).toFormat('DD')}`,
        },
      ]}
    />
  );
};
