import { useNews } from '../news.context';
import { NewsCollectionItem } from './item/news-collection-item.component';

export const NewsCollection = () => {
  const { items } = useNews();

  return (
    <div className={'flex flex-col gap-4'}>
      {items.map(item => (
        <NewsCollectionItem key={item.id} item={item} />
      ))}
    </div>
  );
};
