import { Card } from '../../common/block/card.component';
import { useEntity } from '../entity.context';
import { Button } from '../../common/component/button/button.component';

export const EntityFooter = <ItemType,>() => {
  const { items, page, setPage } = useEntity<ItemType>();

  return (
    <Card className={'flex flex-row justify-between place-items-center p-4'}>
      <div className={'flex flex-row gap-2'}>
        <div>Количество элементов на странице: {items.length}</div>
        <div>Количество элементов на странице: {page}</div>
      </div>

      <div className={'flex flex-row gap-4'}>
        <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
          Назад
        </Button>
        <Button
          onClick={() => setPage(page + 1)}
          disabled={items.length !== 10}
        >
          Вперед
        </Button>
      </div>
    </Card>
  );
};
