import { Card } from '../../../common/block/card.component';

import { NewsEntity } from '../../news.entity';
import { NewsImages } from './news-images.component';
import { NewsPoll } from './news-poll.component';
import { NewsReactions } from './news-reactions.component';
import { NewsHeader } from './news-header.component';

export type NewsCollectionItemProps = {
  item: NewsEntity;
};

export const NewsCollectionItem = ({ item }: NewsCollectionItemProps) => {
  return (
    <Card className={'p-4 flex flex-col gap-4'}>
      <NewsHeader item={item} />

      <div
        className={
          'mt-2 min-w-0 break-words [overflow-wrap:anywhere] whitespace-pre-wrap'
        }
      >
        {item.content}
      </div>

      {item.files && item.files.length > 0 && <NewsImages files={item.files} />}
      {item.poll && <NewsPoll poll={item.poll} />}

      <NewsReactions item={item} />
    </Card>
  );
};
