import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { noop } from '../../utils/common.utils';

export type ModalContentProps = {
  showed: boolean;
  onClose: () => void;
  children?: ReactNode;
  title?: string;
  right?: ReactNode;
  fitWidth?: boolean;
};

export const ModalContent = ({
  showed,
  onClose,
  children,
  title,
  right,
  fitWidth,
}: ModalContentProps) => {
  return (
    <Transition appear show={showed} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100] dark:bg-[#222222]"
        onClose={noop}
        onKeyDown={e => {
          if (e.key === 'Escape') onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-25"
            onClick={() => onClose()}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto pointer-events-none">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${
                  fitWidth ? 'w-fit' : 'w-full'
                } max-w-4xl transform rounded-2xl bg-white dark:bg-[#222222] p-6 text-left align-middle shadow-xl transition-all pointer-events-auto`}
              >
                {title && (
                  <Dialog.Title>
                    <div
                      className={`flex flex-row justify-between place-items-center ${
                        right && 'mb-4'
                      }`}
                    >
                      <div className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        {title}
                      </div>

                      {right}
                    </div>
                  </Dialog.Title>
                )}

                <div className={'mt-2 dark:text-white'}>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
