import { EntityModule } from '../entity';
import { SpecialtyEntity } from './specialty.entity';
import { UserEntity } from '../user/user.entity';
import { Profile } from '../common/component/profile/profile.component';

export const SpecialtyModule = () => (
  <EntityModule<SpecialtyEntity>
    title={'Специальности'}
    route={'specialty'}
    columns={[
      {
        key: 'title',
        title: 'Название',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 10,
              message: 'Минимальная длина 10 символа',
            },
          },
        },
        render: item => item.title,
      },
      {
        key: 'bossId',
        title: 'Заведующий отделением',
        short: 'Заведующий',
        input: {
          type: 'service',
          route: 'users',
          options: {
            required: 'Данное поле обязательно',
          },
          titleFor: item => (item as UserEntity).pretty,
          viewFor: item => <Profile user={item as UserEntity} size={6} />,
        },
        render: item => <Profile user={item.boss} size={10} />,
      },
      {
        key: 'prefix',
        title: 'Префикс',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 2,
              message: 'Минимальная длина 2 символа',
            },
            maxLength: {
              value: 2,
              message: 'Максимальная длина 2 символа',
            },
          },
        },
        render: item => item.prefix,
      },
      {
        key: 'code',
        title: 'Код',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 8,
              message: 'Минимальная длина 8 символов',
            },
            maxLength: {
              value: 8,
              message: 'Максимальная длина 8 символов',
            },
          },
        },
        render: item => item.code,
      },
      {
        key: 'fullYears',
        title: 'Количество обучаемых лет (База 11 классов)',
        short: 'База 11 классов',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => `${item.fullYears} г.`,
      },
      {
        key: 'notFullYears',
        title: 'Количество обучаемых лет (База 9 классов)',
        short: 'База 9 классов',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => `${item.notFullYears} г.`,
      },
      {
        key: 'description',
        title: 'Описание',
        input: {
          type: 'textarea',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 10,
              message: 'Минимальная длина 10 символа',
            },
          },
        },
      },
    ]}
    links={[
      {
        title: 'Учебный план',
        color: 'red',
        link: item => `${item.id}/plans`,
      },
      {
        title: 'Обучаемые группы',
        color: 'blue',
        link: item => `${item.id}/groups`,
      },
    ]}
  />
);
