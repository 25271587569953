import Lottie from 'lottie-react';

import { useFileUploader } from '../file-uploader.context';
import duckError from '../assets/error.json';

export const FileContainerStatusError = () => {
  const { isError } = useFileUploader();

  return (
    <div className={'flex flex-col gap-2 text-center'}>
      <Lottie animationData={duckError} className={'h-40'} />

      <div className={'text-[#FD3246] font-medium'}>{isError}</div>
    </div>
  );
};
