import { SpinningLoader } from '../../../../common/component/loading/spinning-loader.component';
import { Button } from '../../../../common/component/button/button.component';
import { ScheduleContext } from '../../../schedule.context';
import { Modal } from '../../../../common/component/modal/modal';
import { ScheduleItemModalRightTab } from './schedule-item-modal-right-tab.component';
import { ScheduleItemClassroom } from './schedule-item-modal-classroom.component';
import { ScheduleItemSubject } from './schedule-item-modal-subject.component';
import { ScheduleItemTeacher } from './schedule-item-modal-teacher.component';
import { ScheduleItemModalToggle } from './schedule-item-modal-toggle.component';
import { useMemo } from 'react';
import { firstLetterUppercase } from '../../../../common/utils/string.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useContextSelector } from 'use-context-selector';

export const ScheduleItemModal = () => {
  const modal = useContextSelector(ScheduleContext, context => context.modal);

  const metaPositions = useContextSelector(
    ScheduleContext,
    context => context.metaPositions,
  );

  const setModal = useContextSelector(
    ScheduleContext,
    context => context.setModal,
  );

  const isMetaLoading = useContextSelector(
    ScheduleContext,
    context => context.isMetaLoading,
  );

  const save = useContextSelector(ScheduleContext, context => context.save);

  const plansFor = useContextSelector(
    ScheduleContext,
    context => context.plansFor,
  );

  const week = useContextSelector(ScheduleContext, context => context.week);

  const isSemester = useContextSelector(
    ScheduleContext,
    context => context.isSemester,
  );

  const headerFor = useContextSelector(
    ScheduleContext,
    context => context.headerFor,
  );

  const clear = useContextSelector(ScheduleContext, context => context.clear);

  const metaFor = useContextSelector(
    ScheduleContext,
    context => context.metaFor,
  );

  const size = metaPositions();
  const isEmpty = useMemo(() => plansFor().length === 0, [plansFor]);
  const title = useMemo(() => {
    if (modal.type !== 'lesson') {
      return '';
    }

    const date = firstLetterUppercase(
      week
        .startOf('week')
        .plus({ day: modal.day })
        .toFormat(isSemester ? 'EEE' : 'DDDD'),
    );

    return `${date}, ${headerFor(modal.groupId)?.title}, ${
      modal.sort + 1
    }-ое занятие`;
  }, [week, isSemester, modal, headerFor]);

  return (
    <Modal
      showed={modal.type === 'lesson'}
      title={isMetaLoading || isEmpty ? undefined : title}
      right={isEmpty || isEmpty ? undefined : <ScheduleItemModalRightTab />}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      {isMetaLoading ? (
        <div className={'flex justify-center my-20'}>
          <SpinningLoader />
        </div>
      ) : isEmpty ? (
        <div className={'text-center py-8'}>
          Для данной группы нет учебных планов
        </div>
      ) : (
        <div className={'flex flex-col gap-2'}>
          <div className={'w-full flex flex-row gap-4'}>
            {Array.from(Array(size).keys()).map(position => (
              <div
                key={position}
                className={`flex flex-col gap-2 ${
                  size === 1 ? 'w-full' : `w-1/${size}`
                }`}
              >
                <div
                  className={`flex flex-row ${
                    size > 1 ? 'justify-between' : 'justify-end'
                  } items-center`}
                >
                  {size > 1 && (
                    <div className={'font-semibold'}>
                      Подгруппа #{position + 1}
                    </div>
                  )}
                  <Button
                    color={'red'}
                    small={true}
                    square={true}
                    onClick={() => clear(position)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>

                <ScheduleItemSubject position={position} />

                {!metaFor(position).isOnline && (
                  <ScheduleItemClassroom position={position} />
                )}

                <ScheduleItemTeacher position={position} />
                <ScheduleItemModalToggle position={position} />
              </div>
            ))}
          </div>

          <Button color={'green'} onClick={save}>
            Сохранить
          </Button>
        </div>
      )}
    </Modal>
  );
};
