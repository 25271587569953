import { Control, Controller } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';

import { DateTime } from 'luxon';
import { ErrorCard } from '../../../../common/block/error-card.component';
import {
  EntityCalendarColumnType,
  EntityColumnType,
} from '../../../entity.type';

export type EntityCalendarFieldProps<ItemType> = {
  control: Control;
  column: EntityColumnType<ItemType>;
  input: EntityCalendarColumnType;
  error?: string;
};

export const EntityCalendarField = <ItemType,>({
  control,
  column,
  input,
  error,
}: EntityCalendarFieldProps<ItemType>) => {
  const isRange = input.range ?? false;

  return (
    <Controller
      name={column.key}
      control={control}
      rules={input?.options}
      render={field => {
        const value =
          field.field.value === undefined
            ? null
            : isRange
            ? (() => {
                const [first, second] = field.field.value as [number, number];

                const startDate = DateTime.fromSeconds(first).toJSDate();
                const endDate = DateTime.fromSeconds(second).toJSDate();

                return {
                  startDate,
                  endDate,
                };
              })()
            : (() => {
                const date = DateTime.fromSeconds(field.field.value).toJSDate();

                return {
                  startDate: date,
                  endDate: date,
                };
              })();

        return (
          <div className={'flex flex-col gap-2'}>
            <Datepicker
              placeholder={column.title}
              value={value}
              onChange={value =>
                field.field.onChange(
                  value === undefined || value === null
                    ? undefined
                    : isRange
                    ? [
                        DateTime.fromSQL(
                          value.startDate as string,
                        ).toUnixInteger(),
                        DateTime.fromSQL(
                          value.endDate as string,
                        ).toUnixInteger(),
                      ]
                    : DateTime.fromSQL(
                        value.startDate as string,
                      ).toUnixInteger(),
                )
              }
              inputClassName={
                'px-4 py-2 w-full rounded-lg text-md bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6] dark:placeholder-[#e1e3e6]'
              }
              asSingle={!isRange}
              useRange={isRange}
              i18n={'ru'}
              startWeekOn={'mon'}
              {...input.props}
            />
            {error && <ErrorCard error={error} />}
          </div>
        );
      }}
    />
  );
};
