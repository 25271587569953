import { useParams } from 'react-router-dom';
import { SpecialtyGroupProvider } from '../specialty-group/specialty-group.context';
import { SpecialtyGroupParams } from '../specialty-group';
import { SpecialtyGroupRelationProvider } from './specialty-group-relation.context';
import { HeaderComponent } from './components/header.component';
import { RelationTable } from './components/relation-table.component';
import { GroupRelationType } from './specialty-group-relation.entity';

export type SpecialtyGroupRelationParams = {
  groupId: string;
};

export const SpecialtyGroupRelationModule = () => {
  const { specialtyId, groupId } = useParams<
    SpecialtyGroupParams & SpecialtyGroupRelationParams
  >();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <SpecialtyGroupRelationProvider groupId={groupId}>
        <div
          className={
            'container mx-auto flex flex-col gap-4 flex flex-col gap-4'
          }
        >
          <HeaderComponent />
          <RelationTable title={'Студенты'} type={GroupRelationType.STUDENT} />
          <RelationTable title={'Старосты'} type={GroupRelationType.CAPTAIN} />
          <RelationTable title={'Кураторы'} type={GroupRelationType.CURATOR} />
        </div>
      </SpecialtyGroupRelationProvider>
    </SpecialtyGroupProvider>
  );
};
