import { Modal } from '../../../../../common/component/modal/modal';
import { Button } from '../../../../../common/component/button/button.component';
import { PlanModalDropCategoryType, usePlan } from '../../../../plan.context';

export const PlanTableContentCategoryDropModal = () => {
  const { modal, setModal, dropCategory } = usePlan();

  return (
    <Modal
      showed={modal.type === 'dropCategory'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-4'}>
        <h1>
          Вы действительно хотите удалить категорию:{' '}
          {(modal as PlanModalDropCategoryType).title}?
        </h1>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={dropCategory}>
            Да, удалить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
