import { useCallback, useState } from 'react';

import { NewsFileEntity } from '../../news.entity';
import { NewsImagesPreview } from './news-images-preview.component';

export type NewsCollectionItemImagesProps = {
  files: NewsFileEntity[];
};

export const NewsImages = ({ files }: NewsCollectionItemImagesProps) => {
  const [preview, setPreview] = useState<number | undefined>(undefined);

  const iterateImage = useCallback(() => {
    setPreview(prevState => {
      if (prevState === undefined) {
        return prevState;
      }

      return files.length - 1 === prevState ? 0 : prevState + 1;
    });
  }, [files, setPreview]);

  const size =
    files.length === 1
      ? 'w-full'
      : files.length <= 5
      ? `w-1/${files.length}`
      : files.length === 6
      ? 'w-1/3'
      : 'w-1/5';

  return (
    <div className="-m-1 flex flex-wrap md:-m-2 m-2">
      {files.map((file, index) => (
        <div key={file.id} className={`flex flex-wrap ${size}`}>
          <button
            className="w-full p-1 md:p-2"
            onClick={() => setPreview(index)}
          >
            <img
              className="block h-full w-full rounded-lg object-cover object-center hover:cursor-pointer"
              src={file.url}
            />
          </button>
        </div>
      ))}

      {files.length > 0 && (
        <NewsImagesPreview
          files={files}
          preview={preview}
          iterateImage={iterateImage}
          onClose={() => setPreview(undefined)}
        />
      )}
    </div>
  );
};
