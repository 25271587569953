import { Fragment } from 'react';
import { ScheduleContext } from '../../schedule.context';
import { useContextSelector } from 'use-context-selector';

export const SidebarSort = () => {
  const lessonsCount = useContextSelector(
    ScheduleContext,
    context => context.lessonsCount,
  );

  const lessonSize = useContextSelector(
    ScheduleContext,
    context => context.lessonSize,
  );

  return (
    <div
      className={
        'flex flex-col z-30 sticky left-20 dark:bg-[#333333] bg-[#E5E6E7]'
      }
    >
      <div
        className={
          'px-1 py-3.5 text-center border-r border-b border-[#F0F2F5] dark:border-[#333333] sticky top-0 w-8 z-10 dark:bg-[#333333] bg-[#E5E6E7]'
        }
      >
        №
      </div>

      {Array.from(Array(6).keys()).map(day => (
        <Fragment key={day}>
          {Array.from(Array(lessonsCount).keys()).map(sort => (
            <div
              key={`${day}-${sort}`}
              className={`p-1 text-center border-r border-b border-[#F0F2F5] dark:border-[#333333] font-bold justify-center place-items-center`}
              style={{
                height: `${lessonSize}rem`,
              }}
            >
              {sort + 1}
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
};
