import { Fragment } from 'react';
import { SidebarDay } from './sidebar-day.component';
import { SidebarSort } from './sidebar-sort.component';

export const Sidebar = () => {
  return (
    <Fragment>
      <SidebarDay />
      <SidebarSort />
    </Fragment>
  );
};
