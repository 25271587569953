import { Input } from '../../../../../../common/component/input/input.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { PlanItemEntity } from '../../../plan-item.entity';
import { usePlan } from '../../../../../plan.context';

export type PlanTableContentCategoryHeaderInputProps = {
  plan?: PlanItemEntity;
};

export const PlanTableContentCategoryHeaderInput = ({
  plan,
}: PlanTableContentCategoryHeaderInputProps) => {
  const { setModal, hoursCountByPlan } = usePlan();

  return (
    <div
      className={`flex flex-row items-center justify-center ${
        plan
          ? 'w-2/4 border-r-2 border-[#F0F2F5] dark:border-[#333333]'
          : 'w-full'
      }`}
    >
      <div className={`${plan ? 'w-11/12' : 'w-full'} flex flex-row`}>
        <div className={'w-full h-full'}>
          <Input
            placeholder={'Название категории'}
            background={false}
            defaultValue={plan?.rootTitle}
            className={'w-full h-full text-bold'}
            onKeyDown={event => {
              if (event.key !== 'Enter') {
                return;
              }

              const value = event.currentTarget.value;

              if (value.length === 0) {
                event.preventDefault();
                event.stopPropagation();

                return;
              }

              if (plan) {
                setModal({
                  type: 'updateCategory',
                  id: plan.id,
                  title: value,
                });
              } else {
                setModal({
                  type: 'createCategory',
                  title: value,
                });

                event.currentTarget.value = '';
              }
            }}
          />
        </div>

        {plan && (
          <button
            onClick={() =>
              setModal({
                type: 'dropCategory',
                id: plan.id,
                title: plan.rootTitle,
              })
            }
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              className={'mr-2 text-gray-600 dark:text-white/70'}
            />
          </button>
        )}
      </div>

      {plan && (
        <div
          className={
            'flex items-center justify-center p-2 border-l-2 border-[#F0F2F5] dark:border-[#333333] w-1/12'
          }
        >
          {hoursCountByPlan(plan.id)}
        </div>
      )}
    </div>
  );
};
