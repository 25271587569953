import { Card } from '../../../common/block/card.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';

export const StatementBodyEmpty = () => {
  return (
    <Card className={'py-20 px-4 '}>
      <div className={'flex flex-col gap-4 text-center'}>
        <FontAwesomeIcon
          icon={faArrowPointer}
          className={'text-gray-400 text-5xl'}
        />

        <div className={'flex flex-col font-semibold text-gray-500'}>
          <div>Чтобы продолжить работу с журналом</div>
          <div>выберите интересующий вас семестр</div>
        </div>
      </div>
    </Card>
  );
};
