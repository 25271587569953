import { EntityContextBodyType, EntityProvider } from './entity.context';
import {
  EntityActionType,
  EntityColumnType,
  EntityDefaultActions,
  EntityLinkType,
  EntityModuleGroupByProps,
} from './entity.type';
import { EntityHeader } from './components/entity-header.component';
import { EntityFooter } from './components/entity-footer.component';
import { EntityModifyModal } from './components/modify/entity-modify-modal.component';
import { EntityDropModal } from './components/modify/entity-drop-modal.component';
import { EntityTable } from './components/table/entity-table.component';
import { PropsWithChildren } from 'react';

export type EntityModuleProps<ItemType> = {
  title: string;
  subtitle?: string;
  route: string;

  header?: boolean;
  footer?: boolean;
  groupBy?: EntityModuleGroupByProps<ItemType>;
  filter?: Parameters<Array<ItemType>['filter']>[0];

  columns: EntityColumnType<ItemType>[];
  actions?: EntityActionType<ItemType>[];
  links?: EntityLinkType<ItemType>[];
  request?: EntityContextBodyType;
  disabledActions?: EntityDefaultActions[];
};

export const EntityModule = <ItemType,>({
  children,

  title,
  subtitle,
  route,

  header,
  footer,

  columns,
  groupBy,
  filter,
  actions,
  links,
  request,
  disabledActions,
}: PropsWithChildren<EntityModuleProps<ItemType>>) => {
  return (
    <div>
      <EntityProvider<ItemType>
        route={route}
        request={request}
        groupBy={groupBy}
        columns={columns}
        actions={actions}
        disabledActions={disabledActions}
        filter={filter}
      >
        <div className={'container mx-auto flex flex-col gap-4'}>
          {(header ?? true) && (
            <EntityHeader<ItemType>
              title={title}
              subtitle={subtitle}
              isCreateDisabled={disabledActions?.includes('create')}
              isSearchDisabled={disabledActions?.includes('search')}
            />
          )}
          <EntityTable<ItemType>
            columns={columns}
            groupBy={groupBy}
            actions={actions}
            links={links}
            disabledActions={disabledActions}
          />
          {(footer ?? true) && <EntityFooter<ItemType> />}
        </div>

        <EntityModifyModal<ItemType> columns={columns} />
        <EntityDropModal />

        {children && children}
      </EntityProvider>
    </div>
  );
};
