import { NewsProvider } from './news.context';
import { NewsCollection } from './components/news-collection.component';
import { NewsAdmin } from './components/news-admin.component';
import { PermissionType } from '../app/auth/auth-role-permission.type';
import { useAuth } from '../app/auth/auth.context';

export const NewsModule = () => {
  const { result } = useAuth();

  return (
    <NewsProvider>
      <div className={'flex flex-col gap-4'}>
        {result?.permissions?.includes(PermissionType.NEWS_CREATE) && (
          <NewsAdmin />
        )}

        <NewsCollection />
      </div>
    </NewsProvider>
  );
};
