import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createOne, dropOne, findAll, updateOne } from './entity.service';
import { useNotifier } from '../app/notification/notification-context';
import {
  EntityActionType,
  EntityColumnType,
  EntityDefaultActions,
  EntityModuleGroupByProps,
  EntityType,
} from './entity.type';
import { FieldValues } from 'react-hook-form';
import { retrieveValue } from './entity.utils';
import { useQuery } from '@tanstack/react-query';

export type EntityContextBodyType = {
  post?: FieldValues;
  put?: FieldValues;
  get?: FieldValues;
};

export type EntityContextProps<ItemType> = {
  route: string;
  request?: EntityContextBodyType;
  groupBy?: EntityModuleGroupByProps<ItemType>;
  columns: EntityColumnType<ItemType>[];
  filter?: Parameters<Array<ItemType>['filter']>[0];

  disabledActions?: EntityDefaultActions[];
  actions?: EntityActionType<ItemType>[];
};

export type EntityContextModalCreateType = {
  type: 'create';
  groupedValue?: number | string;
};

export type EntityContextModalUpdateType = {
  type: 'update';
  id: number;
  groupedValue?: number | string;
};

export type EntityContextModalDropType = {
  type: 'drop';
  id: number;
};

export type EntityContextModalNoneType = {
  type: 'none';
};

export type EntityContextModalType =
  | EntityContextModalCreateType
  | EntityContextModalUpdateType
  | EntityContextModalDropType
  | EntityContextModalNoneType;

export type EntityContextType<ItemType> = {
  items: EntityType<ItemType>[];
  refetch: () => void;

  modal: EntityContextModalType;
  setModal: (type: EntityContextModalType) => void;
  setSearch: (value: string) => void;
  setPage: (value: number) => void;
  page: number;
  maxPage: number;

  isActionsDisabled: boolean;

  itemWithId: (id: number) => EntityType<ItemType> | undefined;
  modify: (values: FieldValues) => void;
  drop: () => void;
};

export const EntityContext = createContext<EntityContextType<unknown>>(
  {} as EntityContextType<unknown>,
);

export const EntityProvider = <ItemType,>({
  children,
  route,
  request,
  groupBy,
  filter,
  columns,
  disabledActions,
  actions,
}: PropsWithChildren<EntityContextProps<ItemType>>) => {
  const { notify } = useNotifier();

  const [modal, setModal] = useState<EntityContextModalType>({
    type: 'none',
  });

  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const items = useQuery({
    queryKey: ['items', route, page, search, request],
    queryFn: () =>
      findAll<EntityType<ItemType>>(
        route,
        page * 10,
        10,
        search,
        request?.get,
      ).then(results => results.data),

    refetchInterval: 5000,
  });

  const itemsData = useMemo(
    () => (filter ? items.data?.filter(filter) : items.data) ?? [],
    [items, items.data, filter],
  );

  const itemWithId = useCallback(
    (id: number) => itemsData.find(item => item.id === id),
    [itemsData],
  );

  const modify = useCallback(
    (values: FieldValues) => {
      if (modal.type !== 'create' && modal.type !== 'update') {
        return;
      }

      const filtered = Object.keys(values)
        .filter(key => values[key] !== undefined)
        .reduce((previousValue: Record<string, unknown>, currentValue) => {
          const column = columns.find(column => column.key === currentValue);
          const input = column?.input ? retrieveValue(column.input) : undefined;

          if (
            input?.type === 'calendar' &&
            input.range &&
            input.startProperty &&
            input.endProperty
          ) {
            previousValue[input.startProperty] = (
              values[currentValue] as number[]
            )[0];
            previousValue[input.endProperty] = (
              values[currentValue] as number[]
            )[1];
          } else {
            previousValue[currentValue] = values[currentValue];
          }

          return previousValue;
        }, {});

      (modal.type === 'create'
        ? createOne<EntityType<ItemType>>(route, {
            ...filtered,
            ...(groupBy !== undefined && modal.groupedValue !== undefined
              ? {
                  [groupBy.key]: modal.groupedValue,
                }
              : {}),
            ...(request && request.post ? request.post : {}),
          })
        : updateOne<EntityType<ItemType>>(route, modal.id, {
            ...filtered,
            ...(request && request.post ? request.put : {}),
          })
      )
        .then(async () => {
          await items.refetch();

          notify(
            'success',
            `Сущность успешно ${
              modal.type === 'create' ? 'создана' : 'обновлена'
            }`,
          );

          setModal({ type: 'none' });
        })
        .catch(() =>
          notify(
            'error',
            `Ошибка при ${
              modal.type === 'create' ? 'создании' : 'обновлении'
            } сущности!`,
          ),
        );
    },
    [modal, items, itemWithId, columns],
  );

  const drop = useCallback(() => {
    if (modal.type !== 'drop') {
      return;
    }

    dropOne<EntityType<ItemType>>(route, modal.id)
      .then(async () => {
        await items.refetch();

        notify('success', 'Сущность успешно удалена!');
      })
      .catch(() => notify('error', 'Ошибка при удалении сущности!'))
      .finally(() => setModal({ type: 'none' }));
  }, [items, modal]);

  const isActionsDisabled = useMemo(
    () =>
      disabledActions === undefined || (actions?.length ?? 0) > 0
        ? false
        : disabledActions.includes('update') &&
          disabledActions.includes('delete'),
    [disabledActions, actions],
  );

  const value = useMemo(
    () => ({
      items: itemsData,
      refetch: items.refetch,

      setModal,
      modal,
      setSearch,
      setPage,
      page,
      maxPage: 10,

      isActionsDisabled,

      itemWithId,
      modify,
      drop,
    }),
    [
      items,
      setModal,
      modal,
      setSearch,
      setPage,
      page,
      itemWithId,
      modify,
      drop,
      isActionsDisabled,
    ],
  );

  return (
    <EntityContext.Provider value={value}>{children}</EntityContext.Provider>
  );
};

export const useEntity = <ItemType,>() =>
  useContext<EntityContextType<unknown>>(
    EntityContext,
  ) as unknown as EntityContextType<ItemType>;
