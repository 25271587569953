import { ScheduleContext } from '../../../schedule.context';
import { Toggle } from '../../../../common/component/toggle/toggle.component';
import { useContextSelector } from 'use-context-selector';

export type ScheduleItemModalToggleProps = {
  position: number;
};

export const ScheduleItemModalToggle = ({
  position,
}: ScheduleItemModalToggleProps) => {
  const metaFor = useContextSelector(
    ScheduleContext,
    context => context.metaFor,
  );

  const modify = useContextSelector(ScheduleContext, context => context.modify);

  return (
    <div className={'flex flex-col gap-2 my-3'}>
      <Toggle
        label={'Консультационное занятие'}
        checked={metaFor(position).isConsultation}
        onChange={value =>
          modify(position, {
            property: 'isConsultation',
            value,
          })
        }
        disabled={metaFor(position).isExam}
      />

      <Toggle
        label={'Аттестационное занятие'}
        checked={metaFor(position).isExam}
        onChange={value =>
          modify(position, {
            property: 'isExam',
            value,
          })
        }
        disabled={metaFor(position).isConsultation}
      />

      <Toggle
        label={'Дистанционное занятие'}
        checked={metaFor(position).isOnline}
        onChange={value =>
          modify(position, {
            property: 'isOnline',
            value,
          })
        }
        disabled={metaFor(position).isStreet}
      />

      <Toggle
        label={'На улице'}
        checked={metaFor(position).isStreet}
        onChange={value =>
          modify(position, {
            property: 'isStreet',
            value,
          })
        }
        disabled={metaFor(position).isOnline}
      />
    </div>
  );
};
