import { useRoles } from '../role.context';
import { Modal } from '../../common/component/modal/modal';
import { Toggle } from '../../common/component/toggle/toggle.component';
import React from 'react';
import { DropdownContents } from '../../common/component/dropdown-contents/dropdown-contents.component';

export const RolePermissionsModal = () => {
  const { roleId, setRoleId, categories, isToggled, togglePermissions } =
    useRoles();

  return (
    <Modal
      title={'Редактирование роли'}
      showed={roleId !== undefined}
      onClose={() => setRoleId(undefined)}
    >
      <div className={'flex flex-col gap-4'}>
        {categories.map((category, index) => (
          <DropdownContents key={index} title={category.title}>
            <div className={'flex flex-col gap-2'}>
              {category.permissions.map(permission => (
                <div key={permission.value}>
                  <Toggle
                    label={permission.title}
                    checked={isToggled(permission.value)}
                    onChange={value =>
                      togglePermissions(permission.value, value)
                    }
                  />
                </div>
              ))}
            </div>
          </DropdownContents>
        ))}
      </div>
    </Modal>
  );
};
