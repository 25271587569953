import { DocumentEntityType, TaskStatus } from '../document.type';
import { useTask } from '../task.context';
import { EntityModule } from '../../entity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { EntityTableTaskStatus } from './entity-table-task-status.component';
import { TaskDocumentGenerateModal } from './modal/task-document-generate-modal.component';
import { TaskDocumentConfigurationModal } from './modal/task-document-configuration-modal.component';

export const EntityTable = () => {
  const { setModal } = useTask();

  return (
    <EntityModule<DocumentEntityType>
      title={'Документы'}
      route={'task'}
      columns={[
        {
          key: 'type',
          title: 'Тип документа',
          render: value => value.title,
        },
        {
          key: 'status',
          title: 'Статус',
          render: value => <EntityTableTaskStatus entity={value} />,
        },
        {
          key: 'files',
          title: 'Вложения',
          render: value =>
            value.lastJob?.url && value.lastJob?.status === TaskStatus.DONE ? (
              <a
                href={value.lastJob?.url}
                className={'flex flex-row gap-2 items-center font-bold'}
              >
                <FontAwesomeIcon icon={faDownload} />

                <span>Скачать</span>
              </a>
            ) : (
              'Отсутствуют'
            ),
        },
      ]}
      links={[
        {
          title: 'Генерировать',
          onClick: entity =>
            setModal({
              type: 'generate',
              entity,
            }),
          color: 'green',
        },
        {
          title: 'Конфигурация',
          color: 'yellow',
          onClick: entity =>
            setModal({
              type: 'configuration',
              entity,
            }),
        },
      ]}
      disabledActions={['search', 'create', 'update', 'delete']}
      footer={false}
    >
      <TaskDocumentGenerateModal />
      <TaskDocumentConfigurationModal />
    </EntityModule>
  );
};
