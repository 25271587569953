import { Card } from '../../common/block/card.component';
import { Input } from '../../common/component/input/input.component';
import { Button } from '../../common/component/button/button.component';
import { useEntity } from '../entity.context';

export type EntityHeaderProps = {
  title: string;
  subtitle?: string;
  isCreateDisabled?: boolean;
  isSearchDisabled?: boolean;
};

export const EntityHeader = <ItemType,>({
  title,
  subtitle,
  isCreateDisabled,
  isSearchDisabled,
}: EntityHeaderProps) => {
  const { setSearch, setModal } = useEntity<ItemType>();

  return (
    <Card className={'flex flex-row justify-between place-items-center p-4'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'text-lg font-bold'}>{title}</div>
        {subtitle && <div className={'text-md font-medium'}>{subtitle}</div>}
      </div>

      <div className={'flex flex-row gap-4'}>
        {!isSearchDisabled && (
          <Input
            placeholder={'Поиск'}
            className={'w-64'}
            onChange={event => setSearch(event.currentTarget.value)}
          />
        )}

        {!isCreateDisabled && (
          <Button
            onClick={() =>
              setModal({
                type: 'create',
              })
            }
          >
            Создать
          </Button>
        )}
      </div>
    </Card>
  );
};
