import { EntityModuleGroupByProps, EntityType } from '../../entity.type';
import { useEntity } from '../../entity.context';
import { EntityTableActions } from './entity-table-actions.component';
import { EntityTableContentProps } from './entity-table-content.component';
import { Fragment, useMemo } from 'react';
import { Button } from '../../../common/component/button/button.component';
import { retrieveValue } from '../../entity.utils';
import { convertToRoman } from '../../../common/utils/number.utils';

export type EntityTableGroupedContentProps<ItemType> = {
  groupBy: EntityModuleGroupByProps<ItemType>;
};

export const EntityTableGroupedContent = <ItemType,>({
  columns,
  groupBy,
  actions,
  links,
  disabledActions,
}: EntityTableContentProps<ItemType> &
  EntityTableGroupedContentProps<ItemType>) => {
  const { items, setModal } = useEntity<ItemType>();

  const grouped = useMemo(() => {
    const value = items
      .sort(groupBy.sorted)
      .reduce(
        (
          previousValue: Record<string, EntityType<ItemType>[]>,
          currentValue,
          currentIndex,
        ) => {
          const key = currentValue[groupBy.key];

          (previousValue[key] = previousValue[key] || []).push({
            ...currentValue,

            countThrough: currentIndex,
          });

          return previousValue;
        },
        {},
      );

    groupBy.predefined?.forEach(predefinedKey => {
      if (!value[predefinedKey]) {
        value[predefinedKey] = [];
      }
    });

    return value;
  }, [items]);

  return (
    <tbody className="bg-white divide-y divide-[#F0F2F5] dark:divide-[#333333] dark:bg-[#222222]">
      {Object.keys(grouped).map(key => (
        <Fragment key={key}>
          <tr
            className={
              'bg-gray-100/70 py-3.5 pl-4 pr-12 text-left rtl:text-right text-black dark:text-white dark:bg-neutral-700/40'
            }
          >
            <td
              className={
                'py-3.5 pl-4 pr-12 w-44 text-left font-bold rtl:text-right text-black dark:text-white'
              }
              colSpan={columns.length + 1}
            >
              {groupBy.headerRender(key)}
            </td>

            <td className={'flex flex-row py-2 justify-end'}>
              <Button
                color={'green'}
                small
                className={'mr-4'}
                onClick={() =>
                  setModal({
                    type: 'create',
                    groupedValue: key,
                  })
                }
              >
                Создать
              </Button>
            </td>
          </tr>

          {grouped[key].map(item => (
            <tr key={item.id}>
              <td className="w-44 text-left rtl:text-right text-black dark:text-white" />

              {columns
                .filter(column => {
                  const input = column.input
                    ? retrieveValue(column.input, key)
                    : undefined;

                  return (
                    column.render !== undefined ||
                    column.renderCountThrough !== undefined ||
                    (input?.type === 'select' && input?.render)
                  );
                })
                .map(column => {
                  const input = column.input
                    ? retrieveValue(column.input, key)
                    : undefined;

                  return (
                    <td
                      key={column.key}
                      className="py-3.5 pl-4 pr-12 text-left rtl:text-right text-black dark:text-white"
                    >
                      {column.render ? (
                        column.render(item)
                      ) : column.renderCountThrough !== undefined ? (
                        column.renderCountThrough.roman ? (
                          convertToRoman(Number(item['countThrough']) + 1)
                        ) : (
                          Number(item['countThrough']) + 1
                        )
                      ) : input?.type === 'select' && input.render ? (
                        input.items.find(
                          selectItem => selectItem.value === item[column.key],
                        )?.title ?? 'Не установлено'
                      ) : (
                        <div />
                      )}
                    </td>
                  );
                })}
              <EntityTableActions
                actions={actions}
                predefined={key}
                links={links}
                item={item}
                disabledActions={disabledActions}
              />
            </tr>
          ))}
        </Fragment>
      ))}
    </tbody>
  );
};
