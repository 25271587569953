import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';

import { SpecialityGroupEntity } from '../specialty-group/speciality-group.entity';
import { useNotifier } from '../app/notification/notification-context';
import { DateTime } from 'luxon';
import {
  ScheduleEditPropertyType,
  ScheduleEntity,
  SchedulePlanEntity,
} from './schedule.entity';
import { ClassroomEntity } from '../building-classroom/classroom.entity';
import {
  CurrentLessonMeta,
  CurrentLessonMetaMap,
  ScheduleLessonModalType,
  ScheduleModalType,
} from './schedule-modal-type.context';
import { UserEntity } from '../user/user.entity';
import { useQueries } from '@tanstack/react-query';
import {
  cloneSchedule,
  fetchBuildings,
  fetchClassrooms,
  fetchGroups,
  fetchPlans,
  fetchSchedule,
  fetchTeachers,
  publishSchedule,
  saveSchedule,
} from './schedule.service';
import {
  currentScheduleWeek,
  endOfEducationYear,
  startOfEducationYear,
} from '../common/utils/plan.utils';
import { useMap } from 'react-use';

export type ScheduleContextType = {
  /**
   * Режим отображения семестрового расписания. true - отображается семестровое
   * расписание, в противном случае - текущее.
   */
  isSemester: boolean;

  /**
   * Режим отображения опубликованного расписания. true - отображается
   * опубликованное расписание, в противном случае - черновик, который позже
   * можно опубликовать. В режим семестрового расписания данный параметр не
   * используется.
   */
  isPublished: boolean;

  /**
   * Режим отображения заголовков расписания. true - расписание формируется на
   * основе академических группы, в противном случае - на основе преподавателей
   * платформы.
   */
  isGroupsView: boolean;

  /**
   * Выбранный корпус для фильтрации академических групп.
   */
  buildingId: number | undefined;

  /**
   * Текущая отображаемая неделя.
   */
  week: DateTime;

  /**
   * Поиск запрос для выборки определенный преподавателей или академических
   * групп.
   */
  search: string;

  /**
   * Текущее отображаемое модальное окно, если type у данного объекта является
   * none, то никакое модальное окно не отображается.
   */
  modal: ScheduleModalType;

  /**
   * Текущий размер ячеек для отображения занятий.
   */
  lessonSize: number;

  /**
   * Состояние загрузки базовых данных с сервера, если true, то в данный момент
   * происходит загрузка данных.
   */
  isLoading: boolean;

  /**
   * Состояние загрузки мета-данных с сервера, если true, то в данный момент
   * происходит загрузка данных.
   */
  isMetaLoading: boolean;

  /**
   * Количество занятий в день.
   */
  lessonsCount: number;

  /**
   * Список учебных недель для данной секции.
   */
  weeks: DateTime[];

  weekTitle: string;

  /**
   * Множество заголовков для отображения в таблице.
   */
  headers: UserEntity[] | SpecialityGroupEntity[];

  /**
   * Возвращает академическую группу по идентификатору.
   *
   * @param {number} id идентификатор академической группы.
   */
  headerFor: (id: number) => SpecialityGroupEntity | undefined;

  /**
   * Возвращает количество подгрупп для редактирования.
   */
  metaPositions: () => number;

  /**
   * Возвращает текущие мета-данные для подгруппы.
   *
   * @param {number} position порядковый номер подгруппы
   */
  metaFor: (position: number) => CurrentLessonMeta;

  /**
   * Множество учебных планов для определенной подгруппы.
   */
  plansFor: () => SchedulePlanEntity[];

  /**
   * Множество преподавателей, подходящих под данную группу.
   */
  teacherFor: (position: number) => UserEntity[];

  /**
   * Множество аудиторий, подходящих под данную группу.
   */
  classroomFor: (position: number) => ClassroomEntity[];

  /**
   * Список всех занятий на определенный день, с учетом номера занятия и
   * идентификатора сущности, в зависимости от текущего выбранного визуального
   * режима просмотра.
   *
   * Если визуальный режим просмотра указан как преподаватели, то выборка будет
   * происходить с учетом параметра UserEntity#id, в противном случае, выборка
   * будет происходить с учетом параметры SpecialityGroupEntity#id.
   *
   * @param {number} day  порядковый номер дня, отсчет начинается с 0.
   * @param {number} sort порядковый номер занятия, отсчет начинается с 0.
   * @param {number} id   идентификатор сущности, по которой нужно выполнить
   *                      выборку.
   */
  itemsFor: (day: number, sort: number, id: number) => ScheduleEntity[];

  /**
   * Количество занятий по идентификатору сущности, в зависимости от текущего
   * выбранного визуального режима просмотра.
   *
   * @param {number} id   идентификатор сущности, по которой нужно выполнить
   *                      выборку.
   */
  itemsCountFor: (id: number) => number;

  /**
   * Возвращает список совпадений на определенную академическую группу или
   * преподавателя. При выборке используется указанная дата и номер занятия,
   * а также параметр по которому нужно найти совпадения.
   *
   * @param {number} headerId идентификатор академической группы или
   *                          преподаватели, работает в зависимости от
   *                          выбранного в данный момент визуального вида
   *                          отображения расписания.
   * @param {number} day      порядковый номер дня.
   * @param {number} sort     порядковый номер занятия.
   * @param {number} property название параметры по которому нужно произвести
   *                          выборку, преподаватель и аудитория.
   * @param {number} targetId идентификатор преподавателя и аудитории, с которым
   *                          нужно сверить
   */
  isDuplicated: (
    day: number,
    sort: number,
    property: 'teacher' | 'classroom',
    targetId: number,
    count?: number,
  ) => boolean;

  /**
   * Возвращает список совпадений на текущие мета-данные согласно модальному
   * окну. При выборке используется указанная дата и номер занятия,
   * а также параметр по которому нужно найти совпадения.
   *
   * @param {number} headerId идентификатор академической группы или
   *                          преподаватели, работает в зависимости от
   *                          выбранного в данный момент визуального вида
   *                          отображения расписания.
   * @param {number} day      порядковый номер дня.
   * @param {number} sort     порядковый номер занятия.
   * @param {number} property название параметры по которому нужно произвести
   *                          выборку, преподаватель и аудитория.
   * @param {number} targetId идентификатор преподавателя и аудитории, с которым
   *                          нужно сверить
   */
  isDuplicatedByModal: (
    property: 'teacher' | 'classroom',
    targetId: number,
  ) => boolean;

  /**
   * Возвращает подмножество кабинетов, с учетом выбранных настроек отображения.
   */
  classroomsFor: () => ClassroomEntity[];

  /**
   * Настраивает мета данные для выбранного занятия.
   *
   * @param {number} headerId идентификатор академической группы или
   *                          преподавателя, по которому нужно произвести
   *                          выборки с учетом даты и порядкового номера занятия
   * @param {number} day      день недели
   * @param {number} sort     порядковый номер занятия
   */
  edit: (headerId: number, day: number, sort: number) => void;

  /**
   * Меняет местами подгруппы.
   */
  swap: () => void;

  /**
   * Изменяет количество подгрупп в расписании.
   *
   * @param {number} subgroup количество подгрупп, которое нужно установить
   */
  changeSubgroupSize: (subgroup: number) => void;

  /**
   * Удаляет данные о расписание в определенной подгруппе.
   *
   * @param {number} subgroup порядковый номер подгруппы.
   */
  clear: (subgroup: number) => void;

  /**
   * Изменяет сущность дисциплины, преподавателя или аудитории в текущем окне редактирования по определенному индексу
   * подгруппы.
   *
   * @param {number} position    индекс подгруппы
   * @param {number} property данные сущности для изменения
   */
  modify: (position: number, property: ScheduleEditPropertyType) => void;

  /**
   * Отправляет изменения на сервер.
   */
  save: () => void;

  /**
   * Публикует черновик.
   */
  publish: () => void;

  /**
   * Копирует семестровое расписание.
   */
  clone: () => void;

  /**
   * Подтверждает выполнение запрашиваемого действия.
   */
  confirm: () => void;

  /**
   * Изменяет значение поиска для выборки академических групп или преподавателей.
   *
   * @param {string} value значение, по которому необходимо произвести поиск.
   */
  setSearch: (value: string) => void;

  /**
   * Изменяет неделю для выборки расписания.
   *
   * @param {DateTime} value изменяет текущую неделю
   */
  setWeek: (value: DateTime) => void;

  /**
   * Изменяет область редактирования, черновик или опубликованное.
   *
   * @param {number} value true - работа с опубликованным расписание,
   *                       в противном случае с черновиком.
   */
  setPublished: (value: boolean) => void;

  /**
   * Изменяет область редактирования, семестровое или текущее.
   *
   * @param {number} value true - работа с семестровым расписание, в противном
   *                       случае с текущим.
   */
  setSemester: (value: boolean) => void;

  /**
   * Изменяет область редактирования, академические группы или преподаватели.
   *
   * @param {number} value true - работа с академическими группами, в противном
   *                       случае с преподавателями.
   */
  setGroupsView: (value: boolean) => void;

  /**
   * Изменяет текущее отображаемое окно
   */
  setModal: (value: ScheduleModalType) => void;

  /**
   * Изменяет текущий размер ячеек для отображения занятий/
   */
  setLessonSize: (value: number) => void;

  /**
   * Изменяет выбранный корпус
   * @param {number | undefined} value идентификатор выбранного корпуса
   */
  setBuildingId: (value: number | undefined) => void;
};

export const ScheduleContext = createContext<ScheduleContextType>(
  {} as ScheduleContextType,
);

export const ScheduleProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();

  const [week, setWeek] = useState<DateTime>(currentScheduleWeek);
  const [isSemester, setSemester] = useState<boolean>(false);
  const [isPublished, setPublished] = useState<boolean>(true);
  const [isGroupsView, setGroupsView] = useState<boolean>(true);
  const [buildingId, setBuildingId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [modal, setModal] = useState<ScheduleModalType>({ type: 'none' });
  const [meta, { set, remove, reset }] = useMap<CurrentLessonMetaMap>({});
  const [lessonSize, setLessonSize] = useState<number>(8);

  useEffect(() => {
    setWeek(
      isSemester
        ? startOfEducationYear().startOf('week').startOf('day')
        : currentScheduleWeek(),
    );

    if (isSemester) {
      setPublished(true);
    }
  }, [isSemester]);

  const [groups, teachers, buildings, classrooms, schedule, plans] = useQueries(
    {
      queries: [
        {
          queryKey: ['groups'],
          queryFn: fetchGroups,
        },
        {
          queryKey: ['teachers'],
          queryFn: fetchTeachers,
        },
        {
          queryKey: ['buildings'],
          queryFn: fetchBuildings,
        },
        {
          queryKey: ['classrooms'],
          queryFn: fetchClassrooms,
        },
        {
          queryKey: ['schedule', week, isSemester, isPublished],
          queryFn: () =>
            fetchSchedule(
              week.startOf('week').startOf('day').toUnixInteger(),
              week.plus({ day: 6 }).startOf('day').toUnixInteger(),
              isSemester,
              isPublished,
            ),
        },
        {
          queryKey: ['plans', modal, week, isSemester],
          queryFn: () =>
            fetchPlans(
              (modal as ScheduleLessonModalType).groupId,
              isSemester
                ? DateTime.now()
                    .set({
                      year: 2024,
                      month: 9,
                      day: 2,
                    })
                    .startOf('day')
                    .toUnixInteger()
                : week
                    .startOf('week')
                    .plus({ day: (modal as ScheduleLessonModalType).day })
                    .toUnixInteger(),
            ),
          enabled: modal.type === 'lesson',
        },
      ],
    },
  );

  const isLoading = useMemo(
    () =>
      [groups, teachers, buildings, classrooms, schedule].some(
        item => item.isLoading,
      ),
    [
      groups.isLoading,
      teachers.isLoading,
      buildings.isLoading,
      classrooms.isLoading,
      schedule.isLoading,
    ],
  );

  const isMetaLoading = useMemo(() => plans.isLoading, [plans.isLoading]);

  const headers = useMemo(
    () =>
      isGroupsView
        ? (groups.data ?? []).filter(
            item =>
              item.title.includes(search) &&
              (!buildingId || item.buildingId === buildingId),
          )
        : (teachers.data ?? [])
            .filter(item => item.full.includes(search))
            .sort((a, b) => a.full.localeCompare(b.full)),
    [buildingId, isGroupsView, groups.data, teachers.data, search],
  );

  const headerFor = useCallback(
    (id: number) => (groups.data ?? []).find(item => item.id === id),
    [groups.data],
  );

  const metaPositions = useCallback(() => Object.keys(meta).length, [meta]);

  const metaFor = useCallback((position: number) => meta[position], [meta]);

  const duplicated = useMemo(() => {
    return (schedule.data ?? []).reduce(
      (previous: Record<string, number>, item) => {
        const day = DateTime.fromSeconds(item.date).weekday - 1;
        const key = `teacher-${day}-${item.sort}-${item.teacher.id}`;

        previous[key] = (previous[key] || 0) + 1;

        if (item.classroom) {
          const key = `classroom-${day}-${item.sort}-${item.classroom.id}`;

          previous[key] = (previous[key] || 0) + 1;
        }

        return previous;
      },
      {},
    );
  }, [schedule.data]);

  const isDuplicated = useCallback(
    (
      day: number,
      sort: number,
      property: 'teacher' | 'classroom',
      targetId: number,
      count?: number,
    ) => {
      return (
        (duplicated[`${property}-${day}-${sort}-${targetId}`] || 0) >=
        (count ?? 2)
      );
    },
    [duplicated],
  );

  const plansFor = useCallback(() => plans.data ?? [], [plans.data]);

  const teacherFor = useCallback(
    (position: number) => {
      if (modal.type !== 'lesson') {
        return [];
      }

      const plans = plansFor();
      const meta = metaFor(position);
      const planTeachers = meta.planId
        ? plans
            .find(item => item.id === meta.planId)
            ?.teachers?.map(item => item.teacher.id) ?? []
        : [];

      return (teachers.data ?? []).sort(
        (a, b) =>
          Number(planTeachers.includes(b.id)) -
            Number(planTeachers.includes(a.id)) ||
          Number(isDuplicated(modal.day, modal.sort, 'teacher', a.id, 1)) -
            Number(isDuplicated(modal.day, modal.sort, 'teacher', b.id, 1)) ||
          a.full.localeCompare(b.full),
      );
    },
    [modal, isDuplicated, teachers.data, plansFor, metaFor],
  );

  const classroomFor = useCallback(
    (position: number) => {
      if (modal.type !== 'lesson') {
        return [];
      }

      const plans = plansFor();
      const meta = metaFor(position);
      const planTeachers = meta.planId
        ? plans
            .find(item => item.id === meta.planId)
            ?.teachers?.filter(item => item.classroom !== undefined)
            ?.map(item => item.classroom.id) ?? []
        : [];

      return (classrooms.data ?? []).sort(
        (a, b) =>
          Number(planTeachers.includes(b.id)) -
            Number(planTeachers.includes(a.id)) ||
          Number(isDuplicated(modal.day, modal.sort, 'classroom', a.id, 1)) -
            Number(isDuplicated(modal.day, modal.sort, 'classroom', b.id, 1)) ||
          a.title.localeCompare(b.title),
      );
    },
    [modal, isDuplicated, classrooms.data, plansFor, metaFor],
  );

  const itemsFor = useCallback(
    (day: number, sort: number, id: number) => {
      const date = week
        .startOf('week')
        .plus({ day })
        .startOf('day')
        .toUnixInteger();

      return (schedule.data ?? []).filter(
        item =>
          item.date === date &&
          item.sort === sort &&
          (isGroupsView ? item.groupId === id : item.teacher.id === id),
      );
    },
    [week, schedule.data, isGroupsView],
  );

  const itemsCountFor = useCallback(
    (id: number) => {
      return (schedule.data ?? []).filter(item =>
        isGroupsView ? item.groupId === id : item.teacher.id === id,
      ).length;
    },
    [week, schedule.data, isGroupsView],
  );

  const isDuplicatedByModal = useCallback(
    (property: 'teacher' | 'classroom', targetId: number) => {
      if (modal.type !== 'lesson') {
        return false;
      }

      return isDuplicated(modal.day, modal.sort, property, targetId, 1);
    },
    [modal, isDuplicated],
  );

  const classroomsFor = useCallback(
    () => classrooms.data ?? [],
    [classrooms.data],
  );

  const edit = useCallback(
    (groupId: number, day: number, sort: number) => {
      if (!isGroupsView) {
        notify(
          'error',
          'Редактирования расписания в режиме просмотра преподавателей недоступно!',
        );

        return;
      }

      setModal({
        type: 'lesson',
        groupId,
        day,
        sort,
      });

      const items = itemsFor(day, sort, groupId);

      reset();

      if (items.length > 0) {
        if (items.some(item => item.subgroup != undefined)) {
          [0, 1].map(index => {
            const item = items.find(item => item.subgroup === index);

            if (!item) {
              set(index, {});
              return;
            }

            set(index, {
              planId: item.plan.id,
              teacherId: item.teacher.id,
              classroomId: item.classroom?.id,
              isExam: item.isExam,
              isOnline: item.isOnline,
              isStreet: item.isStreet,
              isConsultation: item.isConsultation,
            });
          });
        } else {
          items.forEach((item, index) =>
            set(index, {
              planId: item.plan.id,
              teacherId: item.teacher.id,
              classroomId: item.classroom?.id,
              isExam: item.isExam,
              isOnline: item.isOnline,
              isStreet: item.isStreet,
              isConsultation: item.isConsultation,
            }),
          );
        }
      } else {
        set(0, {});
      }
    },
    [meta, set, isGroupsView, itemsFor],
  );

  const swap = useCallback(() => {
    if (modal.type !== 'lesson' || metaPositions() !== 2) {
      return;
    }

    set(0, meta[1]);
    set(1, meta[0]);
  }, [meta, metaPositions, set]);

  const changeSubgroupSize = useCallback(
    (subgroup: number) => {
      const currentSize = metaPositions();

      if (currentSize === subgroup) {
        return;
      }

      if (subgroup === 1) {
        remove(1);
      } else if (subgroup === 2) {
        set(1, {});
      }
    },
    [metaPositions, meta, remove],
  );

  const clear = useCallback((subgroup: number) => set(subgroup, {}), [set]);

  const modify = useCallback(
    (position: number, property: ScheduleEditPropertyType) => {
      const plan =
        property.property === 'planId'
          ? plansFor().find(item => item.id === property.value)
          : undefined;

      const teacher = plan?.teachers[position] ?? undefined;

      set(position, {
        ...meta[position],

        [property.property]: property.value,

        ...(property.property === 'isOnline' &&
          property.value && {
            classroomId: undefined,
          }),

        ...(teacher && {
          teacherId: teacher.teacher.id,

          ...(teacher.classroom && {
            classroomId: teacher.classroom.id,
          }),
        }),
      });
    },
    [meta, set, plansFor],
  );

  const save = useCallback(() => {
    if (modal.type !== 'lesson') {
      return;
    }

    const date = week
      .startOf('week')
      .plus({ day: modal.day })
      .startOf('day')
      .toUnixInteger();

    const items = Object.keys(meta)
      .map(Number)
      .map(key => meta[key])
      .map((item, index, array) => ({
        ...item,
        ...(array.length > 1 && { subgroup: index }),
      }))
      .filter(
        item =>
          item.teacherId &&
          (item.isOnline || item.isStreet || item.classroomId) &&
          (item.subjectTitle || item.planId),
      );

    saveSchedule(
      modal.groupId,
      date,
      modal.sort,
      isPublished,
      isSemester,
      items,
    )
      .then(async () => {
        await schedule.refetch();

        notify('success', 'Расписание успешно сохранено!');
        setModal({ type: 'none' });
      })
      .catch(() => notify('error', 'Ошибка сохранения расписания!'));
  }, [modal, meta, isPublished, isSemester, schedule.data]);

  const weeks = useMemo(() => {
    const start = startOfEducationYear();
    const end = isSemester ? start.plus({ week: 1 }) : endOfEducationYear();
    const diff = Math.round(end.diff(start, 'week').weeks) + 1;

    return Array.from(Array(diff).keys()).map(index =>
      start.plus({ week: index }).startOf('week').startOf('day'),
    );
  }, [isSemester]);

  const publish = useCallback(
    () =>
      setModal({
        type: 'confirm',
        title: 'Публикация занятий',
        description: 'Вы действительно хотите опубликовать черновик?',
        action: () => {
          const start = week.startOf('week').startOf('day');
          const end = start.plus({ day: 6 }).startOf('day');

          publishSchedule(
            start.toUnixInteger(),
            end.toUnixInteger(),
            buildingId,
          )
            .then(async () => {
              await schedule.refetch();

              setPublished(true);
              notify('success', 'Черновик успешно опубликован!');
            })
            .catch(() => notify('error', 'Ошибка публикации черновика!'));
        },
      }),
    [week, buildingId],
  );

  const clone = useCallback(
    () =>
      setModal({
        type: 'confirm',
        title: 'Клонирование семестровых занятий',
        description: 'Вы действительно хотите клонировать семестровые занятия?',
        action: () =>
          cloneSchedule(
            Math.round(week.diff(weeks[0], 'week').weeks),
            buildingId,
          )
            .then(async () => {
              await schedule.refetch();

              notify('success', 'Клонирование успешно завершено!');
            })
            .catch(() =>
              notify('error', 'Ошибка клонирования семестрового расписания!'),
            ),
      }),
    [weeks, week, buildingId],
  );

  const confirm = useCallback(() => {
    if (modal.type !== 'confirm') {
      return;
    }

    modal.action();

    setModal({
      type: 'none',
    });
  }, [modal]);

  const lessonsCount = useMemo(() => 6, []);

  const weekTitle = useMemo(() => {
    const start = startOfEducationYear();
    const diff = Math.round(week.diff(start, 'week').weeks);

    return isSemester
      ? diff % 2 === 0
        ? 'Четная'
        : 'Нечетная'
      : `${diff + 2} нед.`;
  }, [week, isSemester]);

  const value = useMemo(
    () => ({
      isSemester,
      isPublished,
      isGroupsView,
      buildingId,

      week,
      search,
      modal,
      lessonSize,

      isLoading,
      isMetaLoading,

      lessonsCount,
      weeks,
      weekTitle,

      headers,
      headerFor,
      metaPositions,
      metaFor,
      plansFor,
      teacherFor,
      classroomFor,
      itemsFor,
      itemsCountFor,
      isDuplicated,
      isDuplicatedByModal,
      classrooms,

      classroomsFor,

      edit,
      swap,
      changeSubgroupSize,
      clear,
      modify,
      save,

      publish,
      clone,
      confirm,

      setSearch,
      setWeek,
      setPublished,
      setSemester,
      setGroupsView,
      setModal,
      setLessonSize,
      setBuildingId,
    }),
    [
      isSemester,
      isPublished,
      isGroupsView,
      buildingId,

      week,
      search,
      modal,
      lessonSize,

      isLoading,
      isMetaLoading,

      lessonsCount,
      weeks,
      weekTitle,

      headers,
      headerFor,
      metaPositions,
      metaFor,
      plansFor,
      teacherFor,
      classroomFor,
      itemsFor,
      itemsCountFor,
      isDuplicated,
      isDuplicatedByModal,
      classrooms,

      classroomsFor,

      edit,
      swap,
      changeSubgroupSize,
      clear,
      modify,
      save,

      publish,
      clone,
      confirm,

      setSearch,
      setWeek,
      setPublished,
      setSemester,
      setGroupsView,
      setModal,
      setLessonSize,
      setBuildingId,
    ],
  );

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  );
};
