import { UseFormRegister, FieldValues, Control } from 'react-hook-form';
import {
  EntityAttachmentColumnType,
  EntityCalendarColumnType,
  EntityColumnType,
  EntitySelectColumnType,
  EntityServiceColumnType,
  EntityToggleColumnType,
  EntityType,
} from '../../../entity.type';
import { Input } from '../../../../common/component/input/input.component';

import { EntitySelectField } from './entity-select-field.component';
import { EntityCalendarField } from './entity-calendar-field.component';
import { TextArea } from '../../../../common/component/textarea/textarea.component';
import { EntityServiceField } from './entity-service-field.component';
import { retrieveValue } from '../../../entity.utils';
import { EntityAttachmentField } from './entity-attachment-field.component';
import { EntityToggleField } from './entity-toggle-field.component';

export type EntityFieldProps<ItemType> = {
  column: EntityColumnType<EntityType<ItemType>>;
  predefined: number | string | undefined;
  register: UseFormRegister<FieldValues>;
  control: Control;
  error?: string;
};

export const EntityField = <ItemType,>({
  column,
  predefined,
  register,
  error,
  control,
}: EntityFieldProps<ItemType>) => {
  const input = column.input
    ? retrieveValue(column.input, predefined)
    : undefined;

  const type = input?.type ?? 'text';

  if (type === 'toggle') {
    return (
      <EntityToggleField
        column={column}
        control={control}
        input={input as EntityToggleColumnType}
      />
    );
  }

  if (type === 'attachment') {
    return (
      <EntityAttachmentField
        column={column}
        control={control}
        input={input as EntityAttachmentColumnType}
        error={error}
      />
    );
  }

  if (type === 'service') {
    return (
      <EntityServiceField
        column={column}
        control={control}
        input={input as EntityServiceColumnType}
        error={error}
      />
    );
  }

  if (type === 'select') {
    return (
      <EntitySelectField
        column={column}
        control={control}
        input={input as EntitySelectColumnType}
        error={error}
      />
    );
  }

  if (type === 'calendar') {
    return (
      <EntityCalendarField
        column={column}
        control={control}
        input={input as EntityCalendarColumnType}
        error={error}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <TextArea
        {...register(column.key, input?.options)}
        placeholder={column.title}
        error={error}
      />
    );
  }

  return (
    <Input
      {...register(column.key, input?.options)}
      placeholder={column.title}
      type={type}
      error={error}
    />
  );
};
