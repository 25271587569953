import { FC, useCallback } from 'react';
import { useAuth } from '../../app/auth/auth.context';
import { Modal } from '../../common/component/modal/modal';
import { EntityModule } from '../../entity';
import { UserEntityWithInstitution } from '../../user/user.entity';
import { Profile } from '../../common/component/profile/profile.component';
import { Verification } from '../../common/component/verification/verification.component';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../common/component/button/button.component';

export const SimulationModal: FC = () => {
  const { result, modal, setModal, simulateUser } = useAuth();
  const navigate = useNavigate();

  const usersFilter = useCallback(
    (user: UserEntityWithInstitution) => user.id !== result?.user.id,
    [result?.user.id],
  );

  return (
    <Modal
      title="Симуляция"
      showed={modal.type === 'simulation'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-2'}>
        {result?.original?.user ? (
          <div className="flex flex-row items-center gap-6">
            <span className="text-lg">Оригинальный пользователь:</span>
            <div>
              <Profile user={result.original.user} title size={10} titled />
            </div>
            <div className="ml-auto">
              <Button color="red" onClick={() => simulateUser('none')}>
                Остановить
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-center">
            <span
              className={'text-[#8E8E92] dark:text-[#AAAAAA] font-semibold'}
            >
              Симуляция выключена
            </span>
          </div>
        )}
        {result && !result.original && (
          <EntityModule<UserEntityWithInstitution>
            title={'Пользователи'}
            route={'users'}
            request={{ get: { ignoreInstitution: true } }}
            disabledActions={['create', 'delete', 'update']}
            filter={usersFilter}
            links={[
              {
                title: 'Активировать',
                color: 'default',
                onClick: item =>
                  simulateUser(item.id).then(() => navigate('/home')),
              },
            ]}
            columns={[
              {
                key: 'id',
                title: 'ID',
                render: item => (
                  <div className={'flex flex-row gap-2 items-center'}>
                    {item.id}
                  </div>
                ),
              },
              {
                key: 'cover',
                title: '',
                small: true,
                render: item => <Profile user={item} title={false} size={10} />,
              },
              {
                key: 'title',
                title: 'ФИО',
                render: item => (
                  <div className={'flex flex-row gap-2 items-center'}>
                    {item.full}
                    {item.isAdmin && <Verification />}
                  </div>
                ),
              },
              {
                key: 'mail',
                title: 'Почта',
                render: item => (
                  <div className={'flex flex-row gap-2 items-center'}>
                    {item.mail}
                  </div>
                ),
              },
              {
                key: 'institution',
                title: 'Образовательное учреждение',
                render: item => (
                  <div className={'flex flex-row gap-2 items-center'}>
                    {item.institution.short}
                  </div>
                ),
              },
            ]}
          />
        )}
      </div>
    </Modal>
  );
};
